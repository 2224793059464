import { take, put } from "redux-saga/effects";

// services
import { getGroups } from "src/services/http/group";

// types
import { GetGroupsRequestTypes, GetGroupsResponseTypes } from "src/redux/actions/group/types";

// actions
import { GET_GROUPS, getGroupsActions } from "src/redux/actions/group";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetGroupsRequest() {
  while (true) {
    const { payload }: IActionType<GetGroupsRequestTypes> = yield take(GET_GROUPS.REQUEST);

    try {
      const result: GetGroupsResponseTypes = yield call(getGroups, payload);

      yield put(getGroupsActions.success(result));
    } catch (e) {
      yield put(getGroupsActions.error(e as any));
    }
  }
}

export default watchGetGroupsRequest;
