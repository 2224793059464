import { take, put } from "redux-saga/effects";
import { GET_STUDENT_PROGRESS, getStudentProgressActions } from "src/redux/actions/reports";

// services
import { getStudentProgress } from "src/services/http/reports";

// types
import { GetStudentProgressRequestTypes, GetStudentProgressResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetStudentProgressRequest() {
  while (true) {
    const { payload }: IActionType<GetStudentProgressRequestTypes> = yield take(GET_STUDENT_PROGRESS.REQUEST);

    try {
      const result: GetStudentProgressResponseTypes = yield call(getStudentProgress, payload);
      yield put(getStudentProgressActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getStudentProgressActions.error(e as any));
    }
  }
}

export default watchGetStudentProgressRequest;
