import { take, put } from "redux-saga/effects";
import { GET_SCHOOLS_LIST, getSchoolsListActions } from "src/redux/actions/schools";

// services
import { getSchoolsList } from "../../../services/http/schools";

// types
import { GetSchoolsListResponseTypes } from "src/redux/actions/schools/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetSchoolsListRequest() {
  while (true) {
    const { payload }: IActionType<{ iso: string | undefined }> = yield take(GET_SCHOOLS_LIST.REQUEST);

    try {
      const result: GetSchoolsListResponseTypes = yield call(getSchoolsList, payload);
      const resultSorted = result?.length
        ? result.sort(function (a, b) {
            return b.schoolId - a.schoolId;
          })
        : [];

      yield put(getSchoolsListActions.success(resultSorted));
    } catch (e) {
      yield put(getSchoolsListActions.error(e as any));
    }
  }
}

export default watchGetSchoolsListRequest;
