const useShortenString = () => {
  const s = (inputString: string, maxLength = 25) => {
    if (inputString.length > maxLength) {
      return inputString.substring(0, maxLength) + "...";
    } else {
      return inputString;
    }
  };
  return [s];
};

export default useShortenString;
