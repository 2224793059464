import styled from "styled-components";

export const StyledAddChildren = styled.div`
  padding-top: 36px;

  @keyframes bounce {
    0% {
      transform: translateX(0px);
      timing-function: ease-in;
    }
    37% {
      transform: translateX(5px);
      timing-function: ease-out;
    }
    55% {
      transform: translateX(-5px);
      timing-function: ease-in;
    }
    73% {
      transform: translateX(4px);
      timing-function: ease-out;
    }
    82% {
      transform: translateX(-4px);
      timing-function: ease-in;
    }
    91% {
      transform: translateX(2px);
      timing-function: ease-out;
    }
    96% {
      transform: translateX(-2px);
      timing-function: ease-in;
    }
    100% {
      transform: translateX(0px);
      timing-function: ease-in;
    }
  }

  .fileWrapper {
    margin-bottom: 35px;

    .fiter_text {
      margin-bottom: 10px;
    }

    .formSubmitError {
      margin-top: 10px;
    }
  }

  .dropzone {
    width: 100%;
    height: 230px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px dashed #aed5f1;
    border-radius: 16px;
    transition: opacity 0.3s;
    margin-bottom: 15px;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #aed5f1;
      opacity: 0;
      z-index: 2;
      transition: opacity 0.3s;
    }

    .fileName,
    .error {
      font-size: 18px;
      font-weight: 600;
      color: #165b95;
    }

    .error {
      color: #a52834;
    }
  }

  .downloadDescription {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #01324b;

    a {
      color: #01324b;
      text-decoration: underline;
      transition: color 0.3s;

      &:hover {
        color: #165b95;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 500px) {
    .dropzone {
      height: 120px;

      .fileName {
        font-size: 16px;
      }
    }
  }
`;
