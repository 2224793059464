import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

// components
import { Loader, BookCard } from "src/components";

// types
import { BookListPropsType } from "./BookListTypes";
import { BookTypes } from "src/redux/actions/book/types";

const BookList = ({
  bookList,
  offset,
  loadBooks,
  isLoading,
  scrollableList,
  totalRows,
  bookButtonTitle,
  hideEditButton,
  onBookClick,
  selectedBookId,
  setSelectedBookId,
  useWindow = false
}: BookListPropsType) => {
  const [t] = useTranslation();
  return (
    <>
      {scrollableList ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadBooks && loadBooks()}
          initialLoad={!bookList || bookList?.length == 0}
          hasMore={!isLoading && (!bookList || bookList?.length < (totalRows || 0))}
          useWindow={useWindow}
          threshold={100}
        >
          <div className="row">
            {offset !== undefined && bookList && bookList.length > 0 ? (
              bookList.map((book: BookTypes) => {
                return (
                  <div className="col-xxl-4  col-lg-6" key={book.id + book.name}>
                    <BookCard
                      isSelected={
                        Array.isArray(selectedBookId) ? selectedBookId.includes(book.id) : selectedBookId === book.id
                      }
                      selectedBookId={selectedBookId}
                      setSelectedBookId={setSelectedBookId}
                      buttonTitle={bookButtonTitle}
                      hideEditButton={hideEditButton}
                      blankDetailsButton={true}
                      title={book.name}
                      id={book.id}
                      series={book.seriesData}
                      author={book.authorsData}
                      imgUrl={book.thumbnailSmallUrl || book.thumbnailLargeUrl}
                      readingLevel={book.readingLevel}
                      onBookClick={onBookClick}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}

            {!isLoading && !bookList?.length && (
              <h4 className="mt-5 mb-5 text-center">{t("library_books_in_category_title_no_results")}</h4>
            )}

            {isLoading && (
              <div className="loader-wrapper static-loader autoHeight">
                <Loader />
              </div>
            )}
          </div>
        </InfiniteScroll>
      ) : (
        <>
          <div className="row books-by-categories">
            {bookList &&
              bookList.length > 0 &&
              bookList.map((book: BookTypes) => {
                return (
                  <div className="col-xxl-4 col-lg-6" key={book.id + book.name}>
                    <BookCard
                      title={book.name}
                      id={book.id}
                      series={book.seriesData}
                      author={book.authorsData}
                      imgUrl={book.thumbnailSmallUrl || book.thumbnailLargeUrl}
                      readingLevel={book.readingLevel}
                    />
                  </div>
                );
              })}
          </div>
          {!bookList ||
            (bookList.length === 0 && <h4 className="mt-5 mb-5 text-center">{t("library_category_empty_title")}</h4>)}
        </>
      )}
    </>
  );
};

export default BookList;
