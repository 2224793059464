import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// assets
import logoImage from "src/assets/images/logo.svg";
import bgImage from "src/assets/images/bg-2.png";
import backArrowIcon from "src/assets/images/back-arrow.svg";

// types
import { AuthLayoutPropsType } from "./AuthLayoutTypes";

const AuthLayout = ({ children, showBackLink = false }: AuthLayoutPropsType) => {
  const [t] = useTranslation();

  return (
    <div className="register-section" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="row justify-content-end">
        <div className="register">
          {showBackLink && (
            <div className="back-btn">
              <Link to="/login">
                <img src={backArrowIcon} alt="icon" />
                {t("auth_link_back_to_login")}
              </Link>
            </div>
          )}
          <div className="register-content">
            <div className="logo text-center">
              <img src={logoImage} alt="Logo" className="img-fluid" />
            </div>
            {children}
            <div className="register-footer">
              <p>©{t("footer_copyright")}</p>
              <ul>
                <li>
                  <a href="#">{t("footer_link_terms_conditions")}</a>
                </li>
                <li>
                  <a href="#">{t("footer_link_privacy_policy")}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
