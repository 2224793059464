import React, { useEffect, useState } from "react";
import { StyledActiveSchool } from "./AcctiveSchoolSelect.styles";
import { Autocomplete, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import searchIcon from "src/assets/fonts/5.svg";
import UndoIcon from "@mui/icons-material/Undo";
import { RootReducer } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import useSchools from "../../hooks/useSchools";
import { SchoolSearchOptionTypes } from "../../redux/actions/schools/types";
import { setActiveSchool } from "../../redux/actions/schools";
import LoaderLocal from "../LoaderLocal";
import useShortenString from "../../hooks/useShortenString";
import { getLocalStorageItem, setLocalStorageItem } from "../../services/localStorage";

const ActiveSchoolSelect = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [s] = useShortenString();

  const [isEditMode, setIsEditMode] = useState(false);
  const [schoolsOptions, setSchoolsOptions] = useState<SchoolSearchOptionTypes[]>([]);
  const {
    schools: { isLoading, activeSchool, schoolsList }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  //load schools
  useSchools();

  const handleToggleEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  const handleSelectActiveSchool = (newSchool: SchoolSearchOptionTypes) => {
    dispatch(setActiveSchool(newSchool));
    setLocalStorageItem("activeSchool", newSchool);
    handleToggleEditMode();
  };

  const restoreActiveSchool = () => {
    const lastActiveSchool = getLocalStorageItem<SchoolSearchOptionTypes>("activeSchool");

    if (!activeSchool?.id && lastActiveSchool) {
      handleSelectActiveSchool(lastActiveSchool);
    } else {
      schoolsOptions?.length && handleSelectActiveSchool(schoolsOptions[0]);
    }
  };

  useEffect(() => {
    if (!schoolsOptions?.length && schoolsList?.length) {
      const options = schoolsList?.map(({ schoolId, name }) => ({
        id: schoolId,
        label: name
      }));
      setSchoolsOptions(options);
    }
  }, [schoolsList]);

  useEffect(() => {
    !activeSchool?.id && setIsEditMode(true);
  }, [activeSchool]);

  useEffect(() => {
    !activeSchool?.id && restoreActiveSchool();
  }, [schoolsOptions]);

  if (isLoading) {
    return <LoaderLocal />;
  }

  return (
    <StyledActiveSchool>
      <h5>{t("active_school_title")}:</h5>
      {isEditMode ? (
        <Autocomplete
          id="search-school-input"
          options={schoolsOptions}
          onChange={(e, val) => val?.id && handleSelectActiveSchool(val)}
          renderInput={(params) => (
            <div className="search-school-form" ref={params.InputProps.ref}>
              <form action="#">
                <img src={searchIcon} alt="Icon" className="searchIcon" />
                <input
                  type="text"
                  autoFocus
                  placeholder={`${t("active_school_input_placeholder")}...`}
                  {...params.inputProps}
                />
                {activeSchool?.id ? (
                  <IconButton size="small" className="closeIcon" onClick={handleToggleEditMode}>
                    <UndoIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </form>
            </div>
          )}
        />
      ) : (
        <a className="editSchoolName" onClick={handleToggleEditMode}>
          <h5>{s(`${activeSchool?.label}`)}</h5>
          <EditIcon />
        </a>
      )}
    </StyledActiveSchool>
  );
};

export default ActiveSchoolSelect;
