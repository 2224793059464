import { GET_USER_INFO } from "src/redux/actions/user";
import { GetUserInfoResponseTypes } from "src/redux/actions/user/types";
import { RESET_STATE } from "../actions/auth";

export const initialState: UserState = {
  isLoading: false,
  userData: undefined,
  error: undefined,
  lastUpdated: null
};

export interface UserState {
  isLoading: boolean;
  userData: GetUserInfoResponseTypes | undefined;
  error: any;
  lastUpdated: number | null;
}

const user = (state = initialState, action: IActionType<GetUserInfoResponseTypes>): UserState => {
  switch (action.type) {
    case GET_USER_INFO.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case GET_USER_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: action.payload as GetUserInfoResponseTypes,
        error: undefined,
        lastUpdated: Date.now()
      };
    }

    case GET_USER_INFO.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default user;
