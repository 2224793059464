import React from "react";
import { StyledNoResultsSection } from "./NoResultsSection.styles";

interface IProps {
  title: string;
  image: string;
  largeDesc?: boolean;
  children?: React.ReactNode;
}

const NoResultsSection: React.FC<IProps> = ({ title, image, largeDesc = false, children }) => {
  return (
    <StyledNoResultsSection>
      <img src={image} className="noResImg" alt="No Results" />
      <div className={`noResultsDescription ${largeDesc && "lg"}`}>
        <h2>{title}</h2>
      </div>
      <div className="noResultsActions">{children}</div>
    </StyledNoResultsSection>
  );
};

export default NoResultsSection;
