import { requestTypeCreator } from "src/helpers/index";
import {
  GetSchoolsListResponseTypes,
  GetSchoolsItemResponseTypes,
  GetCampusUsersResponseTypes,
  SchoolSearchOptionTypes
} from "./types";

export const GET_SCHOOLS_LIST = requestTypeCreator("GET_SCHOOLS_LIST");
export const GET_SCHOOLS_ITEM = requestTypeCreator("GET_SCHOOLS_ITEM");
export const GET_CAMPUS_ITEM = requestTypeCreator("GET_CAMPUS_ITEM");
export const GET_SCHOOL_TEACHERS = requestTypeCreator("GET_SCHOOL_TEACHERS");
export const GET_SCHOOL_STUDENTS = requestTypeCreator("GET_SCHOOL_STUDENTS");
export const GET_CAMPUS_TEACHERS = requestTypeCreator("GET_CAMPUS_TEACHERS");
export const GET_CAMPUS_STUDENTS = requestTypeCreator("GET_CAMPUS_STUDENTS");
export const SET_ACTIVE_SCHOOL = "SET_ACTIVE_SCHOOL";

export const getSchoolsListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SCHOOLS_LIST.ERROR
  }),
  request: (payload: { iso: string | undefined }) => ({
    payload,
    type: GET_SCHOOLS_LIST.REQUEST
  }),
  success: (payload: GetSchoolsListResponseTypes): IActionType<GetSchoolsListResponseTypes> => ({
    payload,
    type: GET_SCHOOLS_LIST.SUCCESS
  })
};

export const getSchoolsItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SCHOOLS_ITEM.ERROR
  }),
  request: (payload: { id: number }) => ({
    payload,
    type: GET_SCHOOLS_ITEM.REQUEST
  }),
  success: (payload: GetSchoolsItemResponseTypes): IActionType<GetSchoolsItemResponseTypes> => ({
    payload,
    type: GET_SCHOOLS_ITEM.SUCCESS
  })
};

export const getCampusItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CAMPUS_ITEM.ERROR
  }),
  request: (payload: { id: number }) => ({
    payload,
    type: GET_CAMPUS_ITEM.REQUEST
  }),
  success: (payload: GetSchoolsItemResponseTypes): IActionType<GetSchoolsItemResponseTypes> => ({
    payload,
    type: GET_CAMPUS_ITEM.SUCCESS
  })
};

export const getCampusTeachersActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CAMPUS_TEACHERS.ERROR
  }),
  request: (payload: { campusId: number }) => ({
    payload,
    type: GET_CAMPUS_TEACHERS.REQUEST
  }),
  success: (payload: GetCampusUsersResponseTypes): IActionType<GetCampusUsersResponseTypes> => ({
    payload,
    type: GET_CAMPUS_TEACHERS.SUCCESS
  })
};

export const getCampusStudentsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CAMPUS_STUDENTS.ERROR
  }),
  request: (payload: { campusId: number }) => ({
    payload,
    type: GET_CAMPUS_STUDENTS.REQUEST
  }),
  success: (payload: GetCampusUsersResponseTypes): IActionType<GetCampusUsersResponseTypes> => ({
    payload,
    type: GET_CAMPUS_STUDENTS.SUCCESS
  })
};

export const getSchoolTeachersActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SCHOOL_TEACHERS.ERROR
  }),
  request: (payload: { campusId: number }) => ({
    payload,
    type: GET_SCHOOL_TEACHERS.REQUEST
  }),
  success: (payload: GetCampusUsersResponseTypes): IActionType<GetCampusUsersResponseTypes> => ({
    payload,
    type: GET_SCHOOL_TEACHERS.SUCCESS
  })
};

export const getSchoolStudentsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SCHOOL_STUDENTS.ERROR
  }),
  request: (payload: { campusId: number }) => ({
    payload,
    type: GET_SCHOOL_STUDENTS.REQUEST
  }),
  success: (payload: GetCampusUsersResponseTypes): IActionType<GetCampusUsersResponseTypes> => ({
    payload,
    type: GET_SCHOOL_STUDENTS.SUCCESS
  })
};

export const setActiveSchool = (payload: SchoolSearchOptionTypes) => ({
  payload,
  type: SET_ACTIVE_SCHOOL
});
