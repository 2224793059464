import { take, put } from "redux-saga/effects";
import { GET_SCHOOLS_ITEM, getSchoolsItemActions } from "src/redux/actions/schools";

// services
import { getSchool } from "../../../services/http/schools";

// types
import { GetSchoolsItemResponseTypes } from "src/redux/actions/schools/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetSchoolsItemRequest() {
  while (true) {
    const { payload }: IActionType<{ iso: string | undefined }> = yield take(GET_SCHOOLS_ITEM.REQUEST);

    try {
      const result: GetSchoolsItemResponseTypes = yield call(getSchool, payload);
      yield put(getSchoolsItemActions.success(result));
    } catch (e) {
      yield put(getSchoolsItemActions.error(e as any));
    }
  }
}

export default watchGetSchoolsItemRequest;
