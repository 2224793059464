import { take, put } from "redux-saga/effects";
import {
  getStudentAssignmentsActions,
  MARK_COMPLETE_ASSIGNMENT,
  markCompleteAssignmentActions
} from "src/redux/actions/assignments";

// services
import { getStudentAssignments, markCompleteAssignment } from "src/services/http/assignments";

// types
import { GetStudentAssignmentResponseTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";
import { GetBookListResponseTypes } from "../../actions/book/types";
import { getBookListExtended } from "../../../services/http/book";
const call: any = Effects.call;

function* watchMarkCompleteAssignmentRequest() {
  const sortByPublishDateDescending = (data: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.sort((a, b) => {
      const dateA = new Date(a.publishDate);
      const dateB = new Date(b.publishDate);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  };

  function getBookIds(challenges: any) {
    if (challenges?.length) {
      const challengeBookIds = challenges.map((challenge: any) => {
        if (challenge.bookId) {
          return challenge.bookId;
        }
      });
      return challengeBookIds.filter((id: number) => !!id);
    }
  }

  function* processItems(challenges: any[], assignmentsList: any[], subscriptionId?: number) {
    if (challenges.length === 0 && assignmentsList.length === 0) return;
    const challengeBookIds: number[] = yield getBookIds(challenges);
    const bookData: GetBookListResponseTypes = yield getBookListExtended({
      ids: challengeBookIds,
      limit: challengeBookIds.length,
      subscriptionIds: subscriptionId ? [subscriptionId] : undefined
    });
    for (const challenge of challenges) {
      const book = bookData.books.find((item) => item.id === challenge.bookId);
      assignmentsList.push({ ...challenge, bookData: book });
    }
  }

  while (true) {
    const { payload } = yield take(MARK_COMPLETE_ASSIGNMENT.REQUEST);

    try {
      yield call(markCompleteAssignment, { assignmentId: payload.assignmentId, userId: payload.studentId });

      //get assignments
      const assignmentsList: any = [];
      const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments, {
        userId: payload.studentId
      });

      sortByPublishDateDescending(result);

      yield processItems(result, assignmentsList, payload.subscriptionId);

      yield put(getStudentAssignmentsActions.success(assignmentsList));

      yield put(markCompleteAssignmentActions.success());
    } catch (e) {
      console.log(e, "error");
      yield put(markCompleteAssignmentActions.error(e as any));
    }
  }
}

export default watchMarkCompleteAssignmentRequest;
