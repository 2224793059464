import { take, put } from "redux-saga/effects";
import {
  createAssignmentActions,
  getStudentAssignmentsActions,
  CREATE_FIRST_STEP_ASSIGNMENT
} from "src/redux/actions/assignments";

// services
import { createFirstStepAssignment } from "src/services/http/assignments";

// types
import { CreateFirstSectionAssignmentRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchCreateFirstStepAssignmentRequest() {
  const assignmentsList: any = [];

  while (true) {
    const { payload }: IActionType<CreateFirstSectionAssignmentRequestTypes> = yield take(
      CREATE_FIRST_STEP_ASSIGNMENT.REQUEST
    );

    try {
      const id: number = yield call(createFirstStepAssignment, payload);

      yield put(createAssignmentActions.success());

      if (payload.onSuccess) {
        payload.onSuccess(id);
      }
      yield put(getStudentAssignmentsActions.success(assignmentsList));
    } catch (e) {
      console.log(e, "error");
      yield put(createAssignmentActions.error(e as any));
    }
  }
}

export default watchCreateFirstStepAssignmentRequest;
