import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import i18n from "./services/translations/i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { Loader } from "src/components";
import { store } from "src/redux/store";
import AppRouter from "src/router";
import { ThemeProvider } from "@mui/system";
import { muiTheme } from "src/theme/muiTheme";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

function App() {
  return (
    <Provider store={store}>
      <FlagsmithProvider
        options={{
          environmentID: "TzELU4nRo7y8GejRjoiqzz"
        }}
        flagsmith={flagsmith}
      >
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={muiTheme}>
              <Suspense fallback={<Loader />}>
                <AppRouter />
              </Suspense>
            </ThemeProvider>
          </I18nextProvider>
        </BrowserRouter>
      </FlagsmithProvider>
    </Provider>
  );
}

export default App;
