import { Link, useNavigate, useParams } from "react-router-dom";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { Layout, Loader } from "src/components";
import { FirstStepSection } from "./partials/FirstStepSection";
import { SecondStepSection } from "./partials/SecondStepSection";
import { ThirdStepSection } from "./partials/ThirdStepSection/ThirdStepSection";

// utils
import { getAssignmentsByIdActions, publishAssignmentActions } from "src/redux/actions/assignments";
import { RootReducer } from "src/redux/reducers";
import { getUserInfoActions } from "src/redux/actions/user";
import { useTranslation } from "react-i18next";
import useSchools from "../../hooks/useSchools";
import { EDIT_ASSIGNMENT_QUICK_MODE } from "../../constants";

interface IProps {
  isEditing?: boolean;
}

const CreateAssignment: FC<IProps> = ({ isEditing }) => {
  const { id, editMode } = useParams();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    assignments: { assignments, isLoading: isAssignmentsLoading },
    group: { isLoading: isGroupLoading, groups },
    auth: {
      authData: { userId }
    },
    user: { userData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const isLoading = useMemo(() => isAssignmentsLoading || isGroupLoading, [isAssignmentsLoading, isGroupLoading]);

  const { activeSchoolId, getGroups } = useSchools();

  const getUserInfo = useCallback(() => dispatch(getUserInfoActions.request({ userId: userId || "" })), [dispatch]);

  const getAssignmentById = useCallback(() => {
    if (id) {
      return dispatch(
        getAssignmentsByIdActions.request({
          id: Number(id),
          subscriptionId: userData?.subscriptionId
        })
      );
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (isEditing) {
      getAssignmentById();
    }
    getUserInfo();
    activeSchoolId && getGroups();
  }, [isEditing, activeSchoolId]);

  const assignment = useMemo(() => assignments?.find((item) => item.assignmentId === Number(id)), [id, assignments]);

  const secondStepRef = useRef<HTMLDivElement | null>(null);

  const scrollToSecondStep = () => {
    if (secondStepRef.current) {
      secondStepRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (id && assignment?.assignmentId && assignment.content?.content?.length === 0) {
      scrollToSecondStep();
    }
  }, [id, assignment]);

  const handlePublish = useCallback(() => {
    if (assignment?.assignees.length && assignment?.content.content.length) {
      dispatch(
        publishAssignmentActions.request({
          id: Number(id)
        })
      );
      navigate("/assignments");
    }
  }, [dispatch, id, assignment]);

  return (
    <Layout>
      {isLoading && <Loader />}
      <div className="create-assignment-page">
        <div className="main_breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/assignments">{t("menu_item_assignments")}</Link>
              </li>
              <li className="breadcrumb-item">{t("assignment_create_btn")}</li>
            </ol>
          </nav>
        </div>
        <FirstStepSection editMode={editMode} assignment={assignment} />
        {id && editMode !== EDIT_ASSIGNMENT_QUICK_MODE && (
          <div ref={secondStepRef}>
            <SecondStepSection assignment={assignment} />
            <ThirdStepSection assignment={assignment} groups={groups?.rows} handlePublish={handlePublish} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreateAssignment;
