import React, { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoaderLocal from "../../../../LoaderLocal";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { ClassItemStatsTypes } from "../../../../../redux/actions/reports/types";
import { assignClassesToUser, getAssignedUserClasses } from "../../../../../services/http/users";
import { getSchoolClasses } from "../../../../../services/http/schools";

interface Props {
  isShow: boolean;
  userId: number;
  schoolId: number;
  isNameEdit: boolean;
  handleToggle: () => void;
  handleSuccess: () => void;
}

const TeacherEditForm: React.FC<Props> = ({ isShow, userId, schoolId, isNameEdit, handleToggle, handleSuccess }) => {
  const [t] = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");

  const [isFetchingInitData, setIsFetchingInitData] = useState(false);
  const [schoolClasses, setSchoolClasses] = useState<ClassItemStatsTypes[]>([]);
  const [initUserClassIds, setInitUserClassIds] = useState<number[]>([]);

  const getInitialData = async () => {
    setIsFetchingInitData(true);
    try {
      const assignedClasses = (await getAssignedUserClasses({ schoolId, userId })) as ClassItemStatsTypes[];
      const schoolClassesList = (await getSchoolClasses({ schoolId })) as ClassItemStatsTypes[];

      schoolClassesList?.length && setSchoolClasses(schoolClassesList);

      if (assignedClasses?.length) {
        const initClassIds = assignedClasses.map((teacherClass) => +teacherClass.groupId);
        setInitUserClassIds(initClassIds);
      }
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetchingInitData(false);
    }
  };

  const handleSubmitTeacherForm = async ({ teacherClasses }: { teacherClasses: number[] }) => {
    setErrorSubmit("");
    setIsFetching(true);

    const assignClassesPayload = {
      userId,
      memberships: schoolClasses.map((schoolClass) => ({
        userId,
        role: "teacher",
        groupId: schoolClass.groupId,
        isMember: teacherClasses.includes(schoolClass.groupId)
      }))
    };

    try {
      const assignClassesRes = await assignClassesToUser(assignClassesPayload);

      if (assignClassesRes) {
        handleSuccess();
      } else {
        setErrorSubmit(`${t("Error")}`);
      }
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsResetForm(true);
      setError(error);
    };
  }, []);

  useEffect(() => {
    isShow && getInitialData();
  }, [isShow]);

  if (isFetchingInitData) {
    return <LoaderLocal />;
  }

  const teacherInitialValues = {
    teacherClasses: initUserClassIds || []
  };

  const teacherValidationSchema = Yup.object().shape({
    teacherClasses: Yup.array()
      .of(Yup.number())
      .min(1, `${t("edit_user_select_classes_validation")}`)
  });

  return (
    <Formik
      validationSchema={teacherValidationSchema}
      initialValues={teacherInitialValues}
      onSubmit={handleSubmitTeacherForm}
      enableReinitialize={isResetForm}
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <div className="form-group">
            <label className="label-text pt-2">{t("edit_user_select_classes")}</label>
            <FormControl fullWidth={true}>
              <Select
                labelId="teacher-multiple-classes"
                id="teacher-multiple-classes"
                multiple
                name="teacherClasses"
                value={values.teacherClasses}
                onChange={(e) => setFieldValue("teacherClasses", e.target.value as number[])}
              >
                {schoolClasses.map((schoolClass) => (
                  <MenuItem key={schoolClass.groupId + schoolClass.groupName} value={+schoolClass.groupId}>
                    {schoolClass.groupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {errors.teacherClasses && (
              <ErrorMessage
                name="teacherClasses"
                render={(children) => (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {children}
                  </div>
                )}
              />
            )}
          </div>
          {errorSubmit && <div className="formSubmitError">{errorSubmit}</div>}
          {!isNameEdit ? (
            <div className="submitRow mt-36">
              <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleToggle}>
                {t("Cancel")}
              </button>
              <button type="submit" className="btn-submit" disabled={isFetching} data-bs-dismiss="modal">
                {isFetching ? <LoaderLocal forBtn={true} /> : <>{t("Submit")}</>}
              </button>
            </div>
          ) : (
            <></>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default TeacherEditForm;
