const CheckboxCorrectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        stroke="#4DB984"
        strokeWidth="2"
        d="M3.04 5.695c1.622-1.974 14.295-1.137 16.188 0 1.894 1.137 1.786 14.614-.513 15.841-2.299 1.227-11.97 3.12-14.933 1.05C.453 20.258 1.416 7.67 3.04 5.696z"
      ></path>
      <path
        fill="#00B14B"
        d="M21.357 1.593c.109-.038.235-.066.38-.085h.406c.325 0 .631.038.92.114.29.076.542.2.759.37.216.17.388.39.514.655.145.285.217.636.217 1.054 0 .398-.082.788-.244 1.167-.163.4-.397.883-.704 1.453l-9.381 13.088c-.614.494-1.593 1.099-2.604 1.099-.758 0-1.345-.114-1.76-.342a2.407 2.407 0 01-.975-.997l-4.332-7.832c.234-.93.55-1.566.948-1.908.415-.323.857-.484 1.326-.484.632 0 1.12.161 1.463.484.36.342.695.836 1.002 1.481l2.41 5.013 9.655-14.33z"
      ></path>
    </svg>
  );
};

export default CheckboxCorrectIcon;
