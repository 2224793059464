import { take, put } from "redux-saga/effects";
import { GET_BOOK_ITEM, getBookItemActions, getTeacherGuidanceUrlActions } from "src/redux/actions/book";

// services
import { getBookListExtended, getBookURL } from "src/services/http/book";

// types
import { GetBookListResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookItemRequest() {
  while (true) {
    const { payload }: IActionType<GetBookListResponseTypes> = yield take(GET_BOOK_ITEM.REQUEST);

    try {
      const result: GetBookListResponseTypes = yield call(getBookListExtended, payload);
      if (result?.books && result.books.length > 0) {
        const book = result.books[0];

        yield put(getBookItemActions.success(book));

        const getTeacherGuidanceUrlPayload = {
          bookId: book.id,
          type: 21
        };
        const bookUrlRes: string = yield call(getBookURL, getTeacherGuidanceUrlPayload);
        yield put(getTeacherGuidanceUrlActions.success(bookUrlRes));
      } else {
        yield put(getBookItemActions.success(undefined));
      }
    } catch (e) {
      console.log(e, "error");
      yield put(getBookItemActions.error(e as any));
    }
  }
}

export default watchGetBookItemRequest;
