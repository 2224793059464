import { take, put } from "redux-saga/effects";
import { GET_ASSIGNMENT_STATUS, getAssignmentStatusActions } from "src/redux/actions/assignments";

// services
import { getGetAssignmentStatus } from "src/services/http/assignments";

// types
import { GetAssignmentStatusResponseTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchAssignmentStatusRequest() {
  while (true) {
    const { payload } = yield take(GET_ASSIGNMENT_STATUS.REQUEST);

    try {
      const result: GetAssignmentStatusResponseTypes = yield call(getGetAssignmentStatus, { assignmentId: payload.id });
      yield put(getAssignmentStatusActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getAssignmentStatusActions.error(e as any));
    }
  }
}

export default watchAssignmentStatusRequest;
