import { all, fork } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import watchLoginRequest from "./auth/login";
import watchGetBookListRequest from "./book/getBookList";
import watchGetAuthorListRequest from "./book/getAuthorList";
import watchGetBookItemRequest from "./book/getBookItem";
import watchGetBookURLRequest from "./book/getBookURL";
import watchGetCategoryListRequest from "./book/getCategoryList";
import watchGetLengthListRequest from "./book/getLengthList";
import watchGetSeriesListRequest from "./book/getSeriesList";
import watchGetUserInfoRequest from "./user/getUserInfo";
import watchChildrenStatsRequest from "./reports/getChildrenStats";
import watchGetBookGamesRequest from "./assignments/getBookGames";
import watchCreateAssignmentRequest from "./assignments/createAssignment";
import watchCreateFirstStepAssignmentRequest from "./assignments/createFirstStepAssignment";
import watchAssignmentsRequest from "./assignments/getAssignments";
import watchStudentAssignmentsRequest from "./assignments/getStudentAssignments";
import watchQuizDetailsRequest from "./assignments/getQuizDetails";
import watchAssignmentDetailsRequest from "./assignments/getAssignmentDetails";
import watchMarkCompleteAssignmentRequest from "./assignments/markCompleteAssignment";
import watchAssignmentByIdRequest from "./assignments/getAssignmentById";
import watchAddContentToAssignmentRequest from "./assignments/addContentToAssignment";
import watchAddAudienceToAssignmentRequest from "./assignments/addAudienceToAssignment";
import watchGetCEFRListRequest from "./book/getCEFRList";
import watchGetTopicListRequest from "./book/getTopicList";
import watchUpdateFirstStepAssignmentRequest from "./assignments/updateFirstStepAssignment";
import watchGetBookTypesListRequest from "./book/getCEFRList copy";
import watchCreateNewGroupRequest from "./group/createNewGroup";
import watchUnassignContentFromAssignmentRequest from "./assignments/unassignContentFromAssignment";
import watchGetGroupsRequest from "./group/getGroups";
import watchAssignStudentToGroupRequest from "./group/assignStudentToGroup";
import watchUnassignAudienceFromAssignmentRequest from "./assignments/unassignAudienceFromAssignment";
import watchPublishAssignmentRequest from "./assignments/publishAssignment";
import watchSSORequest from "./auth/sso";
import watchGetDashboardInfoRequest from "./dashboard/getDashboardInfo";
import watchGetClassListStatsRequest from "./reports/getClassListStats";
import watchGetClassItemStatsRequest from "./reports/getClassItemStats";
import watchAssignmentStatusRequest from "./assignments/getAssignmentsStatus";
import watchAssignmentUserActivityRequest from "./assignments/getAssignmentsUserActivity";
import watchGetSchoolsListRequest from "./schools/getSchoolsList";
import watchGetStudentStatsRequest from "./reports/getStudentStats";
import watchGetSchoolsItemRequest from "./schools/getSchoolsItem";
import watchGetCampusItemRequest from "./schools/getCampusItem";
import watchGetCampusStudentsRequest from "./schools/getCampusStudents";
import watchGetCampusTeachersRequest from "./schools/getCampusTeachers";
import watchGetStudentProgressRequest from "./reports/getStudentProgress";
import watchGetSchoolTeachersRequest from "./schools/getSchoolTeachers";
import watchGetSchoolStudentsRequest from "./schools/getSchoolStudents";
import watchGetClassListAdminRequest from "./reports/getClassListAdmin";
import watchGetPowerBiConfigRequest from "./reports/getPowerBiConfig";

export default function* rootSaga(): SagaIterator {
  yield all([
    fork(watchLoginRequest),
    fork(watchGetBookListRequest),
    fork(watchGetAuthorListRequest),
    fork(watchGetBookItemRequest),
    fork(watchGetBookURLRequest),
    fork(watchGetCategoryListRequest),
    fork(watchGetCEFRListRequest),
    fork(watchGetBookTypesListRequest),
    fork(watchGetTopicListRequest),
    fork(watchGetLengthListRequest),
    fork(watchGetSeriesListRequest),
    fork(watchGetUserInfoRequest),
    fork(watchChildrenStatsRequest),
    fork(watchGetBookGamesRequest),
    fork(watchCreateAssignmentRequest),
    fork(watchCreateFirstStepAssignmentRequest),
    fork(watchUpdateFirstStepAssignmentRequest),
    fork(watchAssignmentsRequest),
    fork(watchAssignmentStatusRequest),
    fork(watchAssignmentUserActivityRequest),
    fork(watchAssignmentByIdRequest),
    fork(watchAddContentToAssignmentRequest),
    fork(watchUnassignContentFromAssignmentRequest),
    fork(watchAddAudienceToAssignmentRequest),
    fork(watchStudentAssignmentsRequest),
    fork(watchQuizDetailsRequest),
    fork(watchAssignmentDetailsRequest),
    fork(watchMarkCompleteAssignmentRequest),
    fork(watchCreateNewGroupRequest),
    fork(watchGetGroupsRequest),
    fork(watchAssignStudentToGroupRequest),
    fork(watchUnassignAudienceFromAssignmentRequest),
    fork(watchPublishAssignmentRequest),
    fork(watchSSORequest),
    fork(watchGetDashboardInfoRequest),
    fork(watchGetClassListStatsRequest),
    fork(watchGetClassItemStatsRequest),
    fork(watchGetSchoolsListRequest),
    fork(watchGetStudentStatsRequest),
    fork(watchGetSchoolsItemRequest),
    fork(watchGetCampusItemRequest),
    fork(watchGetCampusTeachersRequest),
    fork(watchGetCampusStudentsRequest),
    fork(watchGetStudentProgressRequest),
    fork(watchGetSchoolTeachersRequest),
    fork(watchGetSchoolStudentsRequest),
    fork(watchGetCampusStudentsRequest),
    fork(watchGetClassListAdminRequest),
    fork(watchGetPowerBiConfigRequest)
  ]);
}
