export const BOOK_ITEMS_LIMIT = 18;

export const TAG_TYPE_CATEGORY = 1;
export const TAG_TYPE_CEFR = 28;
export const TAG_TYPE_TOPIC = 27;
export const TAG_TYPE_BOOK_TYPES = 29;
export const TAG_TYPE_LENGTH = 4;
export const TAG_TYPE_SERIES = 7;
export const TAG_TYPE_AUTHOR = 17;

export const AGE_FILTER_OPTIONS = [
  {
    ageFrom: 2,
    ageTo: 4
  },
  {
    ageFrom: 5,
    ageTo: 7
  },
  {
    ageFrom: 8,
    ageTo: 10
  },
  {
    ageFrom: 11,
    ageTo: 13
  }
];

export const BOOK_READING_MAX_LEVEL = 35;
export const BOOK_LEXILE_MAX_LEVEL = 1400;

export const USER_TYPES = {
  CHILD: 1,
  PARENT: 3,
  TEACHER: 4,
  ADMINISTRATOR: 5,
  UNLOGIN_USER: 6,
  NOTA_MEDLEM: 7,
  UNLOGIN_SCHOOL_USER: 8,
  NLB_USER: 9,
  SCHOOL_STUDENT: 10,
  SCHOOL_TEACHER: 11,
  SCHOOL_ADMIN: 12,
  DISTRIBUTOR_ADMIN: 13
};

export const IMPORT_STUDENTS_CSV_EXAMPLE_LINK = "https://maneno.blob.core.windows.net/maneno-common/assets/student.csv";
export const IMPORT_TEACHERS_CSV_EXAMPLE_LINK = "https://maneno.blob.core.windows.net/maneno-common/assets/teacher.csv";

export const EDIT_ASSIGNMENT_QUICK_MODE = "quick-edit";
