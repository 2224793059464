import { take, put } from "redux-saga/effects";
import {
  createAssignmentActions,
  CREATE_ASSIGNMENT,
  getStudentAssignmentsActions
} from "src/redux/actions/assignments";

// services
import {
  addAudienceToAssignment,
  addContentToAssignment,
  createFirstStepAssignment,
  getStudentAssignments,
  publishAssignment
} from "src/services/http/assignments";

// types
import { CreateAssignmentRequestTypes, GetStudentAssignmentResponseTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";
import { GetBookListResponseTypes } from "../../actions/book/types";
import { getBookListExtended } from "../../../services/http/book";

const call: any = Effects.call;

function* watchCreateAssignmentRequest() {
  const sortByPublishDateDescending = (data: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.sort((a, b) => {
      const dateA = new Date(a.publishDate);
      const dateB = new Date(b.publishDate);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  };

  function getBookIds(challenges: any) {
    if (challenges?.length) {
      const challengeBookIds = challenges.map((challenge: any) => {
        if (challenge.bookId) {
          return challenge.bookId;
        }
      });
      return challengeBookIds.filter((id: number) => !!id);
    }
  }

  function* processItems(challenges: any[], assignmentsList: any[]) {
    if (challenges.length === 0 && assignmentsList.length === 0) return;
    const challengeBookIds: number[] = yield getBookIds(challenges);
    const bookData: GetBookListResponseTypes = yield getBookListExtended({
      ids: challengeBookIds,
      limit: challengeBookIds.length
    });
    for (const challenge of challenges) {
      const book = bookData.books.find((item) => item.id === challenge.bookId);
      assignmentsList.push({ ...challenge, bookData: book });
    }
  }

  while (true) {
    const { payload }: IActionType<CreateAssignmentRequestTypes> = yield take(CREATE_ASSIGNMENT.REQUEST);

    try {
      const assignmentsList: any = [];

      //1
      const firstStepPayload = {
        assignmentName: payload.challengeName,
        startDate: payload.startDate,
        dueDate: payload.dueDate,
        description: "No description",
        reward: payload.reward
      };

      const id: number = yield call(createFirstStepAssignment, firstStepPayload);

      if (id) {
        //2
        const addContentPayload = {
          id,
          content: [
            {
              bookId: payload.bookId
            }
          ],
          contentTypes: [payload.gameId ? 3 : 1]
        };
        const isContentAdd: boolean = yield call(addContentToAssignment, addContentPayload);

        //3
        const addAudiencePayload = {
          assignmentId: id,
          assignees: [
            {
              assigneeId: +payload.assignToUserId,
              role: 1
            }
          ]
        };
        const isAudienceAdd: boolean = yield call(addAudienceToAssignment, addAudiencePayload);

        if (isContentAdd && isAudienceAdd) {
          const isPublished: boolean = yield call(publishAssignment, { id });

          if (isPublished) {
            const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments, {
              userId: payload.assignToUserId
            });

            sortByPublishDateDescending(result);

            yield processItems(result, assignmentsList);

            yield put(getStudentAssignmentsActions.success(assignmentsList));

            yield put(createAssignmentActions.success());
          } else {
            yield put(createAssignmentActions.error({ error: "Publish assignment error" }));
          }
        }
      } else {
        yield put(createAssignmentActions.error({ error: "Create assignment error" }));
      }

      // yield call(createAssignment, { ...payload, challengeTypeId: 9 });
      //
      // const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments, {
      //   userId: payload.assignToUserId
      // });
      //
      // sortByPublishDateDescending(result);
      //
      // yield processItems(result, assignmentsList);
      //
      // yield put(getStudentAssignmentsActions.success(assignmentsList));
      //
      // yield put(createAssignmentActions.success());
    } catch (e) {
      console.log(e, "error");
      yield put(createAssignmentActions.error(e as any));
    }
  }
}

export default watchCreateAssignmentRequest;
