import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootReducer } from "../../redux/reducers";
import { useSelector } from "react-redux";

// assets
import logoImage from "src/assets/images/logo-inverse.svg";
import dashboardIcon from "src/assets/fonts/1.svg";
import fileIcon from "src/assets/fonts/2.svg";
import classRoomIcon from "src/assets/fonts/11.svg";
import groupIcon from "src/assets/fonts/12.svg";
import bookIcon from "src/assets/fonts/3.svg";
import schoolIcon from "src/assets/fonts/13.svg";
import dragonImage from "src/assets/images/s1.png";
import resourcesImage from "src/assets/images/teach_resource.png";

//hooks
import useSchools from "../../hooks/useSchools";
import useUserType from "../../hooks/useUserType";

const Sidebar = ({ handleLogout }: { handleLogout: () => void }) => {
  const [t] = useTranslation();
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const {
    user: { userData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const dropdownToggle = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const { isAdmin } = useUserType();

  const { activeSchoolId } = useSchools();

  const isAdminUserNoSchools = userData && isAdmin && !activeSchoolId;

  return (
    <div className="ds-sidebar">
      <div className="logo">
        <img src={logoImage} alt="Logo" className="img-fluid logo-lg" />
        <img src={logoImage} alt="Logo" className="img-fluid logo-sm" />
      </div>
      <ul className="side-nav">
        {!isAdminUserNoSchools && (
          <li>
            <NavLink to="/dashboard">
              <span className="ds-icon">
                <img src={dashboardIcon} alt="Img" className="img-fluid" />
              </span>{" "}
              <span className="ds-text">{t("sidebar_link_dashboard")}</span>
            </NavLink>
          </li>
        )}
        {!isAdminUserNoSchools && (
          <li className="ds-dropdown">
            <a href="#" onClick={() => dropdownToggle()}>
              <span className="ds-icon">
                <img src={fileIcon} alt="Img" className="img-fluid" />
              </span>{" "}
              <span className="ds-text">{t("sidebar_link_progress_report")}</span>
            </a>
            <ul className={`ds-dropdown-menu slide-toggle-content ${dropdownIsOpen ? "open" : "closed"}`}>
              <li>
                <NavLink to="/progress-report-class">
                  <img src={classRoomIcon} alt="Img" className="img-fluid" />
                  {t("sidebar_link_progress_class")}
                </NavLink>
              </li>
              <li>
                <NavLink to="/progress-report-students">
                  <img src={groupIcon} alt="Img" className="img-fluid" />
                  {t("sidebar_link_progress_students")}
                </NavLink>
              </li>
            </ul>
            <span className="icon"></span>
          </li>
        )}
        <li>
          <NavLink to="/library">
            <span className="ds-icon">
              <img src={bookIcon} alt="Img" className="img-fluid" />
            </span>
            <span className="ds-text">{t("sidebar_link_book_library")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/teacher-resources">
            <span className="ds-icon">
              <img src={bookIcon} alt="Img" className="img-fluid" />
            </span>
            <span className="ds-text">{t("sidebar_link_teacher_resources")}</span>
          </NavLink>
        </li>
        {userData && isAdmin && (
          <li>
            <NavLink to="/reports">
              <span className="ds-icon">
                <img src={fileIcon} alt="Img" className="img-fluid" />
              </span>
              <span className="ds-text">Reports</span>
            </NavLink>
          </li>
        )}
        {!isAdminUserNoSchools && (
          <li>
            <NavLink to="/assignments">
              <span className="ds-icon">
                <img src={bookIcon} alt="Img" className="img-fluid" />
              </span>
              <span className="ds-text">Assignments</span>
            </NavLink>
          </li>
        )}
        {userData && isAdmin && (
          <li>
            <NavLink to="/schools">
              <span className="ds-icon">
                <img src={schoolIcon} alt="Img" className="img-fluid" />
              </span>
              <span className="ds-text">Schools</span>
            </NavLink>
          </li>
        )}
      </ul>
      <div className="sidebar-footer">
        <div className="log-out">
          <button type="button" onClick={handleLogout}>
            <span className="ds-icon">
              <img src={resourcesImage} alt="Img" className="img-fluid" />
            </span>{" "}
            <span className="ds-text">{t("sidebar_button_logout_label")}</span>
          </button>
        </div>
        <div className="thumb">
          <img src={dragonImage} alt="Img" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
