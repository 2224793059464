import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// types
import { AssignmentCardPropTypes } from "./AssignmentCardTypes";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

const MarkCompleteAssignmentButton = ({
  handleClick,
  isLoadingChanges = false
}: {
  handleClick: () => void;
  isLoadingChanges: boolean;
}) => {
  const [t] = useTranslation();
  const [isClicked, setIsClicked] = useState(false);

  const handleMark = () => {
    setIsClicked(true);
    handleClick();
  };

  return (
    <div className="d-flex justify-content-center">
      <button type="button" className={`btn-primary-light btn ${isClicked && "disabled"}`} onClick={handleMark}>
        {isClicked && isLoadingChanges
          ? `${t("assignments_item_button_label_mark_complete_loading")}...`
          : t("assignments_item_button_label_mark_complete")}
      </button>
    </div>
  );
};

const AssignmentCard = ({
  id,
  title,
  description,
  status,
  level,
  assignmentType,
  bookImageUrl,
  link,
  showCompleteButton,
  completeAssignment,
  studentId,
  completedDate,
  isLoadingChanges
}: AssignmentCardPropTypes) => {
  const [t] = useTranslation();

  return (
    <div className="card assign_card">
      <div className="card-body p-4">
        <div>
          <div>
            {status && (
              <span className={`mb-lg-4 ${status === "active" ? "assign_gren_btn" : "assign_btn"}`}>
                {status === "active" ? t("assignments_item_label_active") : t("assignments_item_label_completed")}
              </span>
            )}
            <div className="d-flex mb-lg-2">
              {completedDate && <span className="success-label">{t("assignments_item_label_completed")}</span>}
              {assignmentType && (
                <span className={`ms-auto ${assignmentType === "quiz" ? "assign_gren_btn" : "assign_btn"}`}>
                  {assignmentType === "quiz" ? t("assignments_item_label_quiz") : t("assignments_item_label_book")}
                </span>
              )}
            </div>
            {title && (
              <h5 className="assign_title">
                {t(title)} <br />
              </h5>
            )}
            {bookImageUrl && (
              <div className="image-wrapper">
                <img src={bookImageUrl} alt="book cover" />
              </div>
            )}
            {level && (
              <div>
                <b>
                  {t("assignments_item_label_level")} {level}
                </b>
              </div>
            )}
          </div>
          {description && (
            <div>
              <p className="assign_text">{description}</p>
            </div>
          )}
        </div>
        <div className="button text-center">
          <ButtonPrimary role="link" to={link} title={t("assignments_item_button_label_check_details")} />
          {showCompleteButton && (
            <MarkCompleteAssignmentButton
              isLoadingChanges={isLoadingChanges !== undefined && isLoadingChanges}
              handleClick={() => {
                completeAssignment({ studentId, assignmentId: id });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignmentCard;
