import { take, put } from "redux-saga/effects";
import { GET_BOOK_URL, getBookURLActions } from "src/redux/actions/book";

// services
import { getBookURL } from "src/services/http/book";

// types
import { GetBookURLRequestTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookURLRequest() {
  while (true) {
    const { payload }: IActionType<GetBookURLRequestTypes> = yield take(GET_BOOK_URL.REQUEST);

    try {
      const result: string = yield call(getBookURL, payload);
      yield put(getBookURLActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getBookURLActions.error(e as any));
    }
  }
}

export default watchGetBookURLRequest;
