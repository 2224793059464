import http from "./index";

export const updateUserPassword = async (payload: { password: string; userId: number }) => {
  return await http("api/v2/account/resetpasswordforuser", {
    method: "POST",
    body: JSON.stringify(payload)
  });
};

export const setUserReadingLevel = async ({ userId, readingLevel }: { userId: number; readingLevel: number }) => {
  return await http(`api/users/${userId}/SetReadingLevel`, {
    method: "POST",
    params: { readingLevel }
  });
};

export const getAssignedUserClasses = async ({ schoolId, userId }: { schoolId: number; userId: number }) => {
  return await http(`api/schools/${schoolId}/GetClassesForUser`, {
    method: "GET",
    params: { userId }
  });
};

export type AssignClassesToUserRequestTypes = {
  userId: number;
  memberships: {
    userId: number;
    role: string;
    groupId: number;
    isMember: boolean;
  }[];
};

export const assignClassesToUser = async (payload: AssignClassesToUserRequestTypes) => {
  return await http("api/users/assigntogroups", {
    method: "POST",
    body: JSON.stringify(payload)
  });
};

export const deleteUserAccount = async ({ userId }: { userId: number }) => {
  return await http(`api/users/${userId}`, {
    method: "DELETE"
  });
};

export const updateUserName = async ({
  userId,
  firstName,
  lastName
}: {
  userId: number;
  firstName: string;
  lastName: string;
}) => {
  return await http(`api/users/${userId}/update`, {
    method: "POST",
    body: JSON.stringify({ firstName, lastName })
  });
};
