import { combineReducers } from "redux";

import auth, { AuthState } from "./auth";
import book, { BookState } from "./book";
import user, { UserState } from "./user";
import schools, { SchoolsState } from "./schools";
import dashboard, { DashboardState } from "./dashboard";
import reports, { ReportsState } from "./reports";
import assignments, { AssignmentsState } from "./assignments";
import group, { GroupState } from "./group";

export interface RootReducer {
  auth: AuthState;
  book: BookState;
  user: UserState;
  schools: SchoolsState;
  dashboard: DashboardState;
  reports: ReportsState;
  assignments: AssignmentsState;
  group: GroupState;
}

const reducers = {
  auth,
  book,
  user,
  schools,
  dashboard,
  reports,
  assignments,
  group
};

export default combineReducers<RootReducer>(reducers);
