import styled from "styled-components";

export const StyledModal = styled.div`
  display: none;

  &.show {
    display: block;
  }

  .modal {
    display: block;
    background: rgba(11, 37, 69, 0.7);
    z-index: 10000;
    transition: opacity 0.3s;
    opacity: 0;

    &.fade {
      opacity: 1;
    }
  }

  .modal-dialog {
    max-width: 80%;
    width: 100%;

    &.md {
      max-width: 780px;
    }
  }

  .show {
    .modal-dialog {
      transition: opacity, transform 0.5s;
      transform: translateY(-50px);
      opacity: 0;
    }
  }

  .fade {
    .modal-dialog {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .modal-header {
    padding: 25px 20px 40px;
  }

  .modal-body {
    padding: 20px 25px;
    padding-bottom: 36px;
  }

  @media screen and (max-width: 992px) {
    .modal-dialog {
      &.md {
        max-width: 80%;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .modal-dialog,
    .modal-dialog.md {
      max-width: 95%;
      margin: 16px auto;
    }
  }
`;
