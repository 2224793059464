import React from "react";

// types
import { ReportTabButtonPropTypes } from "./CampusTabsTypes";
import ReportTabButton from "../../../../../components/ReportTabButton";

const CampusTabs = ({ action, tabs, activeTabIndex, btnClass = "nav-link" }: ReportTabButtonPropTypes) => {
  return (
    <div className="campusTabs">
      <ul className="fancy_tabs-report nav nav-tabs" id="myTab" role="tablist">
        {tabs.map(({ title, id }, index) => {
          return (
            <li className="nav-item me-1" role="presentation" key={title + id}>
              <ReportTabButton
                title={title}
                action={() => action(index)}
                isActive={activeTabIndex === index}
                id={id}
                btnClass={btnClass}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CampusTabs;
