import React from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

// types
import { AssignmentResultCardPropTypes } from "./AssignmentResultCardTypes";

const AssignmentResultCard = ({
  date,
  // totalPages,
  // currentPage,
  completionPercentage,
  readingMinutes
}: AssignmentResultCardPropTypes) => {
  const [t] = useTranslation();

  // Convert the given date string to Moment.js object
  const convertedDate = moment(date);
  // Convert the Moment.js object to the desired format
  const formattedDate = convertedDate.tz("America/New_York").format("MMM DD, YYYY - hh:mmA");

  return (
    <div className="assignment-result-card">
      <div className="date">{formattedDate}</div>
      <div className="mb-3">{t("assignment_details_progress_label_reading_minutes", { minutes: readingMinutes })}</div>
      <div className="pages">
        {t("assignment_details_progress_label_completion_percentage")}
        {/*{currentPage}/{totalPages} Pages*/}
      </div>
      <div className="progress-wrapper">
        <div className="progress-bar">
          <div className="bar" style={{ width: `${completionPercentage}%` }}></div>
        </div>
        <span className="percent">{completionPercentage}%</span>
      </div>
    </div>
  );
};

export default AssignmentResultCard;
