import React, { useCallback, useState, useEffect } from "react";
import { StyledSchoolInfo } from "./SchoolInfo.styles";
import { Layout } from "../../../components";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//img
import bulletTeachers from "src/assets/images/schoolBullets/teachers.svg";
import bulletStudents from "src/assets/images/schoolBullets/students.svg";

{
  /* TODO: Display school books */
}
// import bulletBooks from "src/assets/images/schoolBullets/books.svg";
import Campus from "./partials/Campus/Campus";
import AddCampusPopup from "../partials/AddCampusPopup/AddCampusPopup";
import AddUsersPopup from "../partials/AddUsersPopup/AddUsersPopup";
import { RootReducer } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolsItemActions } from "../../../redux/actions/schools";
import CampusTabs from "./partials/CampusTabs";
import noPhoto from "../../../assets/images/noPhoto.svg";
import LoaderLocal from "../../../components/LoaderLocal";

const SchoolInfo = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [t] = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [tabList, setTabList] = useState<any>([]);
  const [isShowAddCampusPopup, setIsShowAddCampusPopup] = useState(false);
  const [isShowAddUsersPopup, setIsShowAddUsersPopup] = useState(false);
  const [isTeacherPopup, setIsTeacherPopup] = useState(false);

  const {
    schools: { school, isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getSchool = useCallback(() => dispatch(getSchoolsItemActions.request({ id: id ? +id : 0 })), [dispatch]);

  const getTabsList = () => {
    if (school && school.campuses?.length) {
      const result: { title: string; id: number }[] = [];

      school.campuses?.map((item) => {
        const obj: { title: string; id: number } = {
          title: item.name,
          id: item.id
        };

        result.push(obj);
      });
      setTabList(result ?? []);
    } else {
      setTabList([]);
    }
  };

  useEffect(() => {
    getSchool();
    getTabsList();
  }, []);

  useEffect(() => {
    getTabsList();
  }, [school]);

  const handleToggleAddUsersPopup = (isTeacher = false) => {
    setIsTeacherPopup(isTeacher);
    setIsShowAddUsersPopup((prev) => !prev);
  };

  const handleToggleAddCampusPopup = () => {
    setIsShowAddCampusPopup((prev) => !prev);
  };

  const handleGetSchool = () => getSchool();

  // useEffect(() => {
  //   if (tabList?.length > 0 && activeTabIndex !== null) {
  //     getCampus({ id: tabList[activeTabIndex].id });
  //   } else if (tabList?.length > 0) {
  //     getCampus({ id: tabList[0].id });
  //   }
  // }, [activeTabIndex, school]);

  if (!id) return <></>;

  return (
    <Layout>
      <AddUsersPopup
        isShow={isShowAddUsersPopup}
        handleToggle={handleToggleAddUsersPopup}
        handleUsersAdded={handleGetSchool}
        isTeachers={isTeacherPopup}
        campusId={tabList[activeTabIndex]?.id || id}
      />
      <StyledSchoolInfo>
        <div className="main_breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/schools">Schools</Link>
              </li>
              <li className="breadcrumb-item">{school?.name}</li>
            </ol>
          </nav>
        </div>
        <section className="schoolHead sec_body">
          <div className="schoolHead--main">
            <div className={`photoWrapper ${!school?.imageUrl && "noPhoto"}`}>
              {<img src={school?.imageUrl || noPhoto} alt="School" />}
            </div>
            <div className="descriptionWrapper">
              <div className="sectionHeader">
                <div className="title_current">
                  <h1>{school?.name}</h1>
                  {/* TODO: Display school location */}
                  {/*<span className="location">*/}
                  {/*  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*    <path*/}
                  {/*      fillRule="evenodd"*/}
                  {/*      clipRule="evenodd"*/}
                  {/*      d="M11.5098 8.71047C11.5098 7.3292 10.3905 6.20996 9.00926 6.20996C7.629 6.20996 6.50977 7.3292 6.50977 8.71047C6.50977 10.0907 7.629 11.21 9.00926 11.21C10.3905 11.21 11.5098 10.0907 11.5098 8.71047Z"*/}
                  {/*      stroke="url(#paint0_linear_2001_5355)"*/}
                  {/*      strokeWidth="1.5"*/}
                  {/*      strokeLinecap="round"*/}
                  {/*      strokeLinejoin="round"*/}
                  {/*    />*/}
                  {/*    <path*/}
                  {/*      fillRule="evenodd"*/}
                  {/*      clipRule="evenodd"*/}
                  {/*      d="M8.99951 19C6.10148 19 1.5 13.9587 1.5 8.59864C1.5 4.40246 4.8571 1 8.99951 1C13.1419 1 16.5 4.40246 16.5 8.59864C16.5 13.9587 11.8985 19 8.99951 19Z"*/}
                  {/*      stroke="url(#paint1_linear_2001_5355)"*/}
                  {/*      strokeWidth="1.5"*/}
                  {/*      strokeLinecap="round"*/}
                  {/*      strokeLinejoin="round"*/}
                  {/*    />*/}
                  {/*    <defs>*/}
                  {/*      <linearGradient*/}
                  {/*        id="paint0_linear_2001_5355"*/}
                  {/*        x1="8.89316"*/}
                  {/*        y1="10.4025"*/}
                  {/*        x2="8.78583"*/}
                  {/*        y2="6.28559"*/}
                  {/*        gradientUnits="userSpaceOnUse"*/}
                  {/*      >*/}
                  {/*        <stop stopColor="#32A3DD" />*/}
                  {/*        <stop offset="0.521397" stopColor="#32A3DD" />*/}
                  {/*        <stop offset="1" stopColor="#1180BF" />*/}
                  {/*      </linearGradient>*/}
                  {/*      <linearGradient*/}
                  {/*        id="paint1_linear_2001_5355"*/}
                  {/*        x1="8.65019"*/}
                  {/*        y1="16.093"*/}
                  {/*        x2="8.18666"*/}
                  {/*        y2="1.27669"*/}
                  {/*        gradientUnits="userSpaceOnUse"*/}
                  {/*      >*/}
                  {/*        <stop stopColor="#32A3DD" />*/}
                  {/*        <stop offset="0.521397" stopColor="#32A3DD" />*/}
                  {/*        <stop offset="1" stopColor="#1180BF" />*/}
                  {/*      </linearGradient>*/}
                  {/*    </defs>*/}
                  {/*  </svg>*/}
                  {/*  Sed in cursus aliquet sit ligula.*/}
                  {/*</span>*/}
                </div>
                {/* TODO: Display school edit btn */}
                {/*<a href="#" className="right_btn md btn-primary-light btn wt_icon">*/}
                {/*  {t("school_edit_btn")}*/}
                {/*  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*    <path*/}
                {/*      fillRule="evenodd"*/}
                {/*      clipRule="evenodd"*/}
                {/*      d="M15.947 2.0734C16.1931 1.83194 16.5269 1.69629 16.8749 1.69629C17.2229 1.69629 17.5567 1.83194 17.8028 2.0734L20.4278 4.64943C20.6739 4.89097 20.8121 5.21852 20.8121 5.56005C20.8121 5.90159 20.6739 6.22914 20.4278 6.47068L8.61534 18.0628C8.47165 18.2042 8.29641 18.3109 8.10347 18.3745L4.16597 19.6625C3.93464 19.7385 3.68629 19.7497 3.44882 19.6949C3.21134 19.6401 2.99413 19.5215 2.82156 19.3523C2.64899 19.183 2.52789 18.97 2.47186 18.737C2.41582 18.504 2.42707 18.2602 2.50434 18.0332L3.81684 14.1691C3.88142 13.9793 3.99017 13.8069 4.13447 13.6655L15.947 2.0734V2.0734ZM16.8749 2.98403L19.4999 5.56005L7.68741 17.1522L3.74991 18.4402L5.06241 14.5761L16.8749 2.98403Z"*/}
                {/*      fill="#165B95"*/}
                {/*    ></path>*/}
                {/*    <path*/}
                {/*      fillRule="evenodd"*/}
                {/*      clipRule="evenodd"*/}
                {/*      d="M17.0666 8.59191L13.7854 5.37187L14.7146 4.45996L17.9959 7.67999L17.0679 8.59191H17.0666ZM5.0625 13.288V13.932C5.0625 14.1028 5.13164 14.2666 5.25471 14.3874C5.37778 14.5082 5.5447 14.576 5.71875 14.576H6.375V15.22C6.375 15.3908 6.44414 15.5546 6.56721 15.6754C6.69028 15.7962 6.8572 15.864 7.03125 15.864H7.6875V16.508C7.6875 16.6788 7.75664 16.8426 7.87971 16.9634C8.00278 17.0842 8.1697 17.152 8.34375 17.152H9V15.22C9 15.0492 8.93086 14.8854 8.80779 14.7646C8.68472 14.6439 8.5178 14.576 8.34375 14.576H7.6875V13.932C7.6875 13.7612 7.61836 13.5974 7.49529 13.4766C7.37222 13.3559 7.2053 13.288 7.03125 13.288H5.0625Z"*/}
                {/*      fill="#165B95"*/}
                {/*    ></path>*/}
                {/*    <path*/}
                {/*      fillRule="evenodd"*/}
                {/*      clipRule="evenodd"*/}
                {/*      d="M17.0018 8.7504L13.5592 5.37203L14.7145 4.23828L18.2217 7.68007L17.1326 8.7504H17.0018ZM4.90405 13.1298H7.03114C7.24622 13.1298 7.45309 13.2136 7.60607 13.3638C7.75916 13.514 7.84572 13.7184 7.84572 13.9322V14.4178H8.34364C8.55873 14.4178 8.76559 14.5017 8.91857 14.6518C9.07166 14.802 9.15822 15.0064 9.15822 15.2202V17.3105H8.34364C8.12855 17.3105 7.92168 17.2267 7.7687 17.0766C7.61561 16.9264 7.52905 16.722 7.52905 16.5082V16.0225H7.03114C6.81605 16.0225 6.60918 15.9387 6.4562 15.7886C6.30311 15.6383 6.21655 15.4339 6.21655 15.2202V14.7345H5.71864C5.50355 14.7345 5.29668 14.6507 5.1437 14.5006C4.99061 14.3503 4.90405 14.1459 4.90405 13.9322V13.1298ZM5.06239 13.9322C5.06239 14.103 5.13153 14.2668 5.2546 14.3875C5.37767 14.5083 5.54459 14.5762 5.71864 14.5762H6.37489V15.2202C6.37489 15.391 6.44403 15.5548 6.5671 15.6756C6.69017 15.7963 6.85709 15.8642 7.03114 15.8642H7.68739V16.5082C7.68739 16.679 7.75653 16.8428 7.8796 16.9636C8.00267 17.0844 8.16959 17.1522 8.34364 17.1522H8.99989V15.2202C8.99989 15.0494 8.93075 14.8856 8.80768 14.7648C8.6846 14.644 8.51768 14.5762 8.34364 14.5762H7.68739V13.9322C7.68739 13.7614 7.61825 13.5976 7.49518 13.4768C7.3721 13.356 7.20518 13.2882 7.03114 13.2882H5.06239V13.9322ZM17.0678 8.59207H17.0665L13.7853 5.37203L14.7145 4.46013L17.9958 7.68015L17.0678 8.59207Z"*/}
                {/*      fill="#165B95"*/}
                {/*    ></path>*/}
                {/*    <path*/}
                {/*      d="M2 21.8083C2 21.371 2.35444 21.0166 2.79167 21.0166H20.4583C20.8956 21.0166 21.25 21.371 21.25 21.8083V21.9423C21.25 22.3795 20.8956 22.734 20.4583 22.734H2.79167C2.35444 22.734 2 22.3795 2 21.9423V21.8083Z"*/}
                {/*      fill="#165B95"*/}
                {/*    ></path>*/}
                {/*  </svg>*/}
                {/*</a>*/}
              </div>
              <p className="schoolDescription">{school?.description}</p>

              <div className="bullets">
                <div className="item">
                  <img src={bulletTeachers} alt="teachers" />
                  <div className="itemWrap">
                    <span className="itemWrap--number">{school?.numberOfTeachers}</span>
                    <span className="itemWrap--description">Number of Teacher</span>
                  </div>
                </div>
                <div className="item">
                  <img src={bulletStudents} alt="students" />
                  <div className="itemWrap">
                    <span className="itemWrap--number">{school?.numberOfStudents}</span>
                    <span className="itemWrap--description">Number of Students</span>
                  </div>
                </div>
                {/* TODO: Display school books */}
                {/*  <div className="item">*/}
                {/*    <img src={bulletBooks} alt="books" />*/}
                {/*    <div className="itemWrap">*/}
                {/*      <span className="itemWrap--number">56</span>*/}
                {/*      <span className="itemWrap--description">Number of Books</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
              </div>
            </div>
          </div>
          {!school?.teachers?.length && !school?.students?.length && (
            <div className="schoolHead--footer">
              <CampusTabs
                tabs={tabList}
                activeTabIndex={activeTabIndex}
                action={setActiveTabIndex}
                btnClass={"nav-link2"}
              />
              <div className="campusActions">
                <a href="#" className="right_btn md btn-primary-light btn wt_icon" onClick={handleToggleAddCampusPopup}>
                  {t("school_add_campus")}
                  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.84555 2.53702C6.86155 2.66502 6.88555 2.77702 6.91755 2.87302L10.402 4.62086C7.27326 4.15236 2.85834 3.62662 1.59784 4.16102C0.190208 4.75778 0.702027 7.66093 1.70938 7.66102C2.65334 7.6611 7.09185 7.46595 10.2903 7.31946L8.59755 8.08102C8.05355 8.35302 7.63755 8.64902 7.34955 8.96902C7.07755 9.27302 6.94155 9.70502 6.94155 10.265C6.94155 10.681 7.07755 11.073 7.34955 11.441C7.63755 11.793 8.17355 12.073 8.95755 12.281L15.5575 8.44102C15.9255 8.23302 16.2055 7.94502 16.3975 7.57702C16.5895 7.20902 16.6855 6.68902 16.6855 6.01702C16.6855 5.12102 16.4775 4.40102 16.0615 3.85702L10.9015 0.881016C10.4215 0.609016 10.0135 0.401016 9.67755 0.257016C9.35755 0.113016 9.02955 0.0410156 8.69355 0.0410156C8.34155 0.0410156 8.04555 0.105016 7.80555 0.233015C7.58155 0.345016 7.39755 0.497015 7.25355 0.689015C7.10955 0.881015 7.00555 1.10502 6.94155 1.36102C6.87755 1.61702 6.84555 1.88902 6.84555 2.17702V2.53702Z"
                    />
                  </svg>
                </a>
                {/*TODO: display import campuses btn*/}
                {/*<a href="#" className="right_btn md btn-primary-dark btn wt_icon">*/}
                {/*    {t("school_import_campuses")}*/}
                {/*    <svg width="17" height="13" viewBox="0 0 17 13" fill="none"*/}
                {/*         xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path*/}
                {/*            fillRule="evenodd"*/}
                {/*            clipRule="evenodd"*/}
                {/*            d="M6.84555 2.53702C6.86155 2.66502 6.88555 2.77702 6.91755 2.87302L10.402 4.62086C7.27326 4.15236 2.85834 3.62662 1.59784 4.16102C0.190208 4.75778 0.702027 7.66093 1.70938 7.66102C2.65334 7.6611 7.09185 7.46595 10.2903 7.31946L8.59755 8.08102C8.05355 8.35302 7.63755 8.64902 7.34955 8.96902C7.07755 9.27302 6.94155 9.70502 6.94155 10.265C6.94155 10.681 7.07755 11.073 7.34955 11.441C7.63755 11.793 8.17355 12.073 8.95755 12.281L15.5575 8.44102C15.9255 8.23302 16.2055 7.94502 16.3975 7.57702C16.5895 7.20902 16.6855 6.68902 16.6855 6.01702C16.6855 5.12102 16.4775 4.40102 16.0615 3.85702L10.9015 0.881016C10.4215 0.609016 10.0135 0.401016 9.67755 0.257016C9.35755 0.113016 9.02955 0.0410156 8.69355 0.0410156C8.34155 0.0410156 8.04555 0.105016 7.80555 0.233015C7.58155 0.345016 7.39755 0.497015 7.25355 0.689015C7.10955 0.881015 7.00555 1.10502 6.94155 1.36102C6.87755 1.61702 6.84555 1.88902 6.84555 2.17702V2.53702Z"*/}
                {/*        />*/}
                {/*    </svg>*/}
                {/*</a>*/}
              </div>
            </div>
          )}
        </section>
        {isLoading ? (
          <LoaderLocal />
        ) : (
          <>
            {!isLoading && !school?.campuses && (
              <section className="sec_body">
                <Campus handleToggleAddUsersPopup={handleToggleAddUsersPopup} campusId={+id} isSchool={true} />
              </section>
            )}
            {!isLoading && school?.campuses?.length && tabList && tabList.length > 0 && (
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="campus-tab-pane"
                  role="tabpanel"
                  aria-labelledby="campus-tab-pane"
                >
                  <section className="sec_body">
                    <Campus
                      handleToggleAddUsersPopup={handleToggleAddUsersPopup}
                      campusId={school?.campuses?.find((item) => item.id === tabList[activeTabIndex].id)?.id || 0}
                    />
                  </section>
                </div>
              </div>
            )}
          </>
        )}
      </StyledSchoolInfo>
      {/*TODO: Replace countryId with real data*/}
      {school && (
        <AddCampusPopup
          isShow={isShowAddCampusPopup}
          handleToggle={handleToggleAddCampusPopup}
          handleCampusCreated={handleGetSchool}
          countryId={1}
          id={school.id}
        />
      )}
    </Layout>
  );
};

export default SchoolInfo;
