import http from "../http";
import {
  GetClassListStatsRequestTypes,
  GetClassItemStatsRequestTypes,
  GetStudentStatsRequestTypes,
  GetStudentProgressRequestTypes
} from "src/redux/actions/reports/types";

export const getChildrenStats = ({ userId }: { userId: string }) => {
  return http(`api/users/${userId}/StudentStats`, {
    method: "GET"
  });
};

export const getSchoolClassList = ({ id, ...values }: GetClassListStatsRequestTypes) => {
  return http(`api/Schools/${id}/GetClassesForUser`, {
    params: values,
    method: "GET"
  });
};

export const getClassItemStats = ({ id, ...values }: GetClassItemStatsRequestTypes) => {
  return http(`api/Schools/${id}/ClassDashboard`, {
    params: values,
    method: "GET"
  });
};

export const getStudentStats = ({ id, ...values }: GetStudentStatsRequestTypes) => {
  return http(`api/Schools/${id}/Students`, {
    params: values,
    method: "GET"
  });
};

export const getStudentProgress = ({ userId, languageId }: GetStudentProgressRequestTypes) => {
  return http(`api/users/${userId}/assessment?languageId=${languageId}`, {
    method: "GET"
  });
};
