import http from "../http";
import { GetBookURLRequestTypes, GetTagListRequestTypes } from "src/redux/actions/book/types";

export const getBookListExtended = (values: any) => {
  return http("api/books/MetadataSearchExtended", {
    params: values,
    method: "GET"
  });
};

export const getTagList = ({ id, languageId = 2 }: GetTagListRequestTypes) => {
  return http(`api/tagtype/${id}/tags`, {
    params: languageId,
    method: "GET"
  });
};

export const getBookURL = (values: GetBookURLRequestTypes) => {
  return http("api/books/directZipped", {
    params: values,
    method: "GET"
  });
};
