import { requestTypeCreator } from "src/helpers/index";
import { LoginRequestTypes, LoginResponseTypes, SSORequestTypes } from "./types";

export const LOGIN = requestTypeCreator("LOGIN");
export const RESET_STATE = "RESET_STATE";
export const SSO = requestTypeCreator("SSO");

export const loginActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: LOGIN.ERROR
  }),
  request: (payload: LoginRequestTypes): IActionType<LoginRequestTypes> => ({
    payload,
    type: LOGIN.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: LOGIN.SUCCESS
  })
};

export const resetStateAction = {
  type: RESET_STATE
};

export const ssoActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: SSO.ERROR
  }),
  request: (payload: SSORequestTypes): IActionType<SSORequestTypes> => ({
    payload,
    type: SSO.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: SSO.SUCCESS
  })
};
