import styled from "styled-components";

export const StyledNoResultsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  .noResImg {
    max-height: 400px;
  }

  .noResultsDescription {
    margin-top: 25px;
    width: 100%;
    max-width: 480px;
    text-align: center;

    &.lg {
      max-width: 700px;
    }
  }

  .noResultsActions {
    margin-top: 15px;
    display: flex;
    align-items: center;

    a {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .noResultsActions {
      flex-direction: column;
    }

    .noResultsActions .btn {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
`;
