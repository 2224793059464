import { take, put } from "redux-saga/effects";

// actions
import {
  UNASSIGN_AUDIENCE_FROM_ASSIGNMENT,
  unassignAudienceFromAssignmentActions
} from "src/redux/actions/assignments";
import { assignStudentsToGroupActions } from "src/redux/actions/group";

// services
import { unassignAudienceFromAssignment } from "src/services/http/assignments";

// types
import { UnassignAudienceFromAssignmentRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchUnassignAudienceFromAssignmentRequest() {
  while (true) {
    const { payload }: IActionType<UnassignAudienceFromAssignmentRequestTypes> = yield take(
      UNASSIGN_AUDIENCE_FROM_ASSIGNMENT.REQUEST
    );

    try {
      yield call(unassignAudienceFromAssignment, payload);
      yield put(unassignAudienceFromAssignmentActions.success(payload));
    } catch (e) {
      yield put(assignStudentsToGroupActions.error(e as any));
    }
  }
}

export default watchUnassignAudienceFromAssignmentRequest;
