import React from "react";
import pencil from "../../assets/images/pencil.svg";

interface Props {
  handleEdit: () => void;
}

const CardEditButton: React.FC<Props> = ({ handleEdit }) => {
  return (
    <button className="btn-edit-round cardEdit" onClick={handleEdit}>
      <img src={pencil} alt="pencil" />
    </button>
  );
};

export default CardEditButton;
