import { take, put } from "redux-saga/effects";
import { GET_CAMPUS_TEACHERS, getCampusTeachersActions } from "../../actions/schools";

// services
import { getCampusTeachers } from "../../../services/http/schools";

// types
import { GetCampusUsersResponseTypes } from "../../actions/schools/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetCampusTeachersRequest() {
  while (true) {
    const { payload }: IActionType<{ campusId: number | undefined }> = yield take(GET_CAMPUS_TEACHERS.REQUEST);

    try {
      const result: GetCampusUsersResponseTypes = yield call(getCampusTeachers, payload);
      yield put(getCampusTeachersActions.success(result));
    } catch (e) {
      yield put(getCampusTeachersActions.error(e as any));
    }
  }
}

export default watchGetCampusTeachersRequest;
