import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootReducer } from "src/redux/reducers";

// components
import { RangeInput } from "src/components";

// constants
import { BOOK_LEXILE_MAX_LEVEL, BOOK_READING_MAX_LEVEL } from "src/constants";

// assets
import crossIcon from "src/assets/images/crose-icon.svg";

interface IProps {
  categoryId?: number[];
  cefrId?: number[];
  topicsId?: number[];
  readingLevelValue?: number[];
  lexileLevelValue?: number[];
  handleSubmit: (
    categories: number[],
    cefr: number[],
    topics: number[],
    readingLevel: number[],
    lexileLevel: number[]
  ) => void;
  useCategories?: boolean;
  modalIdToOpen?: string;
  setOffset: Dispatch<SetStateAction<number>>;
}

const FilterModal: FC<IProps> = ({
  handleSubmit,
  categoryId,
  cefrId,
  topicsId,
  readingLevelValue,
  lexileLevelValue,
  modalIdToOpen,
  useCategories = true,
  setOffset
}) => {
  const [t] = useTranslation();

  const {
    book: { categoryList, cefrList, topicList }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const [categories, setCategories] = useState<number[]>(categoryId || []);
  const [cefr, setCefr] = useState<number[]>(cefrId || []);
  const [topics, setTopics] = useState<number[]>(topicsId || []);
  const [readingLevel, setReadingLevel] = useState<number[]>(readingLevelValue || [0, BOOK_READING_MAX_LEVEL]);
  const [lexileLevel, setLexileLevel] = useState<number[]>(lexileLevelValue || [0, BOOK_LEXILE_MAX_LEVEL]);
  const [filterIsApplied, setFilterIsApplied] = useState<boolean>(false);

  useEffect(() => {
    if (
      categories.length > 0 ||
      cefr.length > 0 ||
      topics.length > 0 ||
      readingLevel[0] > 0 ||
      readingLevel[1] < BOOK_READING_MAX_LEVEL ||
      lexileLevel[0] > 0 ||
      lexileLevel[1] < BOOK_LEXILE_MAX_LEVEL
    ) {
      setFilterIsApplied(true);
    } else {
      setFilterIsApplied(false);
    }
  }, [categories, cefr, topics, readingLevel, lexileLevel]);

  const onChange = useCallback(
    (value: number, setFunction: Dispatch<SetStateAction<number[]>>) => {
      setFunction((prev) => {
        const newItems = [...prev];
        const itemIndex = newItems.findIndex((item) => item === value);

        if (itemIndex >= 0) {
          newItems.splice(itemIndex, 1);
        } else {
          newItems.push(value);
        }
        return newItems;
      });
    },
    [setCategories]
  );

  const handleFiltersReset = () => {
    setCategories([]);
    setLexileLevel([0, BOOK_LEXILE_MAX_LEVEL]);
    setReadingLevel([0, BOOK_READING_MAX_LEVEL]);
    setCefr([]);
    setTopics([]);
    setFilterIsApplied(false);
    setOffset(0);
  };

  const handleClose = () => {
    setCategories(categoryId || categories);
    setLexileLevel(lexileLevelValue || lexileLevel);
    setReadingLevel(readingLevelValue || readingLevel);
  };

  return (
    <div className="filter-modal-sec">
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header modal_bg">
              <h5 className="modal-title mb-0" id="exampleModalLabel">
                {t("library_filter_modal_title")}
              </h5>
              <div className="btn-group">
                {filterIsApplied && (
                  <button type="button" className="btn btn-link" onClick={handleFiltersReset}>
                    {t("library_filter_modal_button_reset")}
                  </button>
                )}
                <button
                  type="button"
                  className="btn-close custom-modal-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle={modalIdToOpen && "modal"}
                  data-bs-target={modalIdToOpen}
                  onClick={handleClose}
                >
                  <img src={crossIcon} alt="icon" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="student-edit-form m-0">
                <form>
                  {useCategories && categoryList && categoryList?.length > 0 && (
                    <div className="form-group">
                      <span className="label-text text-start">{t("library_filter_modal_label_select_category")}</span>
                      <ul className="d-flex flex-wrap gap-2">
                        {categoryList?.map((item) => {
                          const isActive = categories ? categories.indexOf(item.id) !== -1 : null;

                          return (
                            <li key={item.id}>
                              <button
                                type="button"
                                className={`btn btn-outline-primary text-capitalize ${isActive ? "active" : ""}`}
                                onClick={() => {
                                  onChange(item.id, setCategories);
                                }}
                              >
                                {t(item.name)}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  <div className="form-group">
                    <span className="label-text text-start">{t("CEFR")}</span>
                    <ul className="d-flex flex-wrap gap-2">
                      {cefrList?.map((item) => {
                        const isActive = cefr ? cefr.indexOf(item.id) !== -1 : null;

                        return (
                          <li key={item.id}>
                            <button
                              type="button"
                              className={`btn btn-outline-primary text-capitalize ${isActive ? "active" : ""}`}
                              onClick={() => {
                                onChange(item.id, setCefr);
                              }}
                            >
                              {t(item.name)}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="form-group">
                    <span className="label-text text-start">{t("Topic")}</span>
                    <ul className="d-flex flex-wrap gap-2">
                      {topicList?.map((item) => {
                        const isActive = topics ? topics.indexOf(item.id) !== -1 : null;

                        return (
                          <li key={item.id}>
                            <button
                              type="button"
                              className={`btn btn-outline-primary text-capitalize ${isActive ? "active" : ""}`}
                              onClick={() => {
                                onChange(item.id, setTopics);
                              }}
                            >
                              {t(item.name)}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="form-group">
                    <label className="label-text text-start">
                      {`${t("library_filter_modal_label_reading_level")} (${readingLevel[0]} - ${readingLevel[1]})`}
                    </label>
                    <RangeInput
                      min={0}
                      max={BOOK_READING_MAX_LEVEL}
                      step={1}
                      onChange={(values: number[]) => {
                        setReadingLevel(values);
                      }}
                      values={readingLevel}
                    />
                  </div>
                  <div className="form-group m-0">
                    <label className="label-text text-start">
                      {`${t("library_filter_modal_label_lexile_level")} (${lexileLevel[0]} - ${lexileLevel[1]})`}
                    </label>
                    <RangeInput
                      min={0}
                      max={BOOK_LEXILE_MAX_LEVEL}
                      step={1}
                      onChange={(values: number[]) => {
                        setLexileLevel(values);
                      }}
                      values={lexileLevel}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn-cancel"
                data-bs-dismiss="modal"
                onClick={handleClose}
                data-bs-toggle={modalIdToOpen && "modal"}
                data-bs-target={modalIdToOpen}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-submit"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleSubmit(categories, cefr, topics, readingLevel, lexileLevel);
                }}
                data-bs-target={modalIdToOpen}
                data-bs-toggle={modalIdToOpen && "modal"}
              >
                Submit
                <div className="button-hover">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 375 70"
                    width="375"
                    height="70"
                    preserveAspectRatio="xMidYMid meet"
                    style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}
                  >
                    <defs>
                      <clipPath id="__lottie_element_159">
                        <rect width="375" height="70" x="0" y="0"></rect>
                      </clipPath>
                    </defs>
                    <g clipPath="url(#__lottie_element_159)">
                      <g transform="matrix(1,0,0,1,362.5,54.75)" opacity="1" style={{ display: "block" }}>
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fillOpacity="0"
                            stroke="#CB1E5C"
                            strokeOpacity="1"
                            strokeWidth="4"
                            d=" M-5,-5.25 C-5,-5.25 5,5.25 5,5.25"
                          ></path>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,363,31.5)" opacity="1" style={{ display: "block" }}>
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fillOpacity="0"
                            stroke="#CB1E5C"
                            strokeOpacity="1"
                            strokeWidth="4"
                            d=" M-5.5,-0.5 C-5.5,-0.5 5.5,0.5 5.5,0.5"
                          ></path>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,363.25,9.25)" opacity="1" style={{ display: "block" }}>
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fillOpacity="0"
                            stroke="#CB1E5C"
                            strokeOpacity="1"
                            strokeWidth="4"
                            d=" M-5.25,3.25 C-5.25,3.25 5.25,-3.25 5.25,-3.25"
                          ></path>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,12.5,58.75)" opacity="1" style={{ display: "block" }}>
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fillOpacity="0"
                            stroke="#CB1E5C"
                            strokeOpacity="1"
                            strokeWidth="4"
                            d=" M5,-5.25 C5,-5.25 -5,5.25 -5,5.25"
                          ></path>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,12,35.5)" opacity="1" style={{ display: "block" }}>
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fillOpacity="0"
                            stroke="#CB1E5C"
                            strokeOpacity="1"
                            strokeWidth="4"
                            d=" M5.5,-0.5 C5.5,-0.5 -5.5,0.5 -5.5,0.5"
                          ></path>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,11.5,13.25)" opacity="1" style={{ display: "block" }}>
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fillOpacity="0"
                            stroke="#CB1E5C"
                            strokeOpacity="1"
                            strokeWidth="4"
                            d=" M5.5,3.25 C5.5,3.25 -5.5,-3.25 -5.5,-3.25"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
