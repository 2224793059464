import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment-timezone";
import { Field, Formik } from "formik";

// components
import { Layout, Loader } from "src/components";

// assets
import arrowDownIcon from "src/assets/images/down-arrow.svg";

// utils
import { getAssignmentsActions } from "src/redux/actions/assignments";
import { RootReducer } from "src/redux/reducers";

interface Assignment {
  id: number;
  startDate: Date;
  dueDate: Date;
  name: string;
  group: string;
  isPublished: boolean;
}

const columnHelper = createColumnHelper<Assignment>();

const assignmentsTypeConfig = [
  { id: "active", label: "Active", value: "active", stateId: 2 },
  { id: "pending", label: "Pending", value: "pending", stateId: 3 },
  { id: "completed", label: "Completed", value: "completed", stateId: 4 },
  { id: "draft", label: "Draft", value: "draft", stateId: 1 }
] as const;

const Assignments = () => {
  const [t] = useTranslation();
  const assignmentsRef = useRef<HTMLDivElement>(null);
  const [assignmentsType, setAssignmentsType] = useState<(typeof assignmentsTypeConfig)[number]["value"]>(
    assignmentsTypeConfig[0].value
  );
  const dispatch = useDispatch();

  const {
    assignments: { assignments, isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getAssignments = useCallback(
    () =>
      dispatch(
        getAssignmentsActions.request({
          state: assignmentsFilterRef?.current?.stateId ?? assignmentsTypeConfig[0].stateId
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    setTimeout(() => {
      getAssignments();
    }, 500);
  }, []);

  const data = useMemo(() => {
    if (assignments) {
      return assignments?.map(({ assignmentName, dueDate, startDate, assignmentId, groups, isPublished }) => {
        const filteredGroups: string[] = groups ? groups.filter((value): value is string => value !== null) : [];

        return {
          id: assignmentId,
          startDate,
          dueDate,
          name: assignmentName || "-",
          group: filteredGroups.length == 1 ? filteredGroups[0] : filteredGroups?.join(", "),
          isPublished: isPublished
        };
      });
    } else {
      return [];
    }
  }, [assignments]);

  const assignmentsFilter = useMemo(
    () => assignmentsTypeConfig.find(({ value }) => value === assignmentsType),
    [assignmentsType, assignmentsTypeConfig]
  );

  const assignmentsFilterRef = useRef(assignmentsFilter);

  useEffect(() => {
    assignmentsFilterRef.current = assignmentsFilter;
    getAssignments();
  }, [assignmentsFilter]);

  const columns = [
    columnHelper.accessor("startDate", {
      cell: (info) => moment(info.getValue()).format("YYYY-MM-DD"),
      header: () => <span>{t("assignments_modal_calendar_label_start_date")}</span>
    }),
    columnHelper.accessor("dueDate", {
      cell: (info) => moment(info.getValue()).format("YYYY-MM-DD"),
      header: () => <span>{t("assignments_modal_calendar_label_due_date")}</span>
    }),
    columnHelper.accessor("name", {
      header: () => t("Name"),
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("group", {
      header: () => t("Group"),
      cell: (info) => info.getValue()
    }),
    columnHelper.display({
      id: "actions",
      header: `${t("assignments_table_cell_actions")}`,
      cell: ({ cell }) => {
        const [t] = useTranslation();

        return (
          <div className="actionsWrapper">
            <Link to={`${cell.row.original.id}`} type="button" className="btn btn-edit">
              {t("assignments_button_view")}
            </Link>
            <Link
              to={`create/${cell.row.original.id}${
                assignmentsType === assignmentsTypeConfig[0].value ? "/quick-edit" : ""
              }`}
              type="button"
              className="btn btn-error btn-edit"
            >
              {t("assignments_button_edit")}
            </Link>
          </div>
        );
      }
    })
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return isLoading ? (
    <Loader />
  ) : (
    <Layout>
      <div className="assignments-page">
        <div className="main_breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">{t("menu_item_assignments")}</li>
            </ol>
          </nav>
        </div>
        <div
          className="tab-pane fade active show"
          id="contact3-tab-pane"
          role="tabpanel"
          aria-labelledby="contact3-tab"
        >
          <div className="ds-card" ref={assignmentsRef}>
            <div className="assignmensPageHeader d-sm-flex align-items-center  justify-content-between pb-3">
              <h6 className="pro_text">{t("assignments_title_assignments")}</h6>
              <Link to="create" className="btn custom_primary_btn">
                {t("assignment_create_btn")}
              </Link>
            </div>
            <div className="assignmentsTableWrapper ds-card sec_body">
              <div className="table-header">
                <div className="select-text mt-3 mb-0 pb-3 fw-bold">{`${assignmentsFilter?.label} assignments`}</div>
                <Formik initialValues={{ assignmentsType }} onSubmit={(e) => console.log(e)}>
                  <div className="form-group">
                    <div className="relative">
                      <Field
                        as="select"
                        name="assignmentsType"
                        className="form-control"
                        value={assignmentsType}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setAssignmentsType(e.target.value as typeof assignmentsType)
                        }
                      >
                        {assignmentsTypeConfig.map(({ id, value, label }) => {
                          return (
                            <option value={value} key={id}>
                              {label}
                            </option>
                          );
                        })}
                      </Field>
                      <span className="down-img">
                        <img src={arrowDownIcon} alt="icon" />
                      </span>
                    </div>
                  </div>
                </Formik>
              </div>
              <table className="border-separate border-spacing-0.5 pt-2.5">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className={`${row.index % 2 === 0 ? "even" : "odd"}`}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Assignments;
