import React, { useEffect, useRef, useState } from "react";
import { StyledCampus } from "./Campus.styles";
import { ReportTabs } from "../../../../../components";
import CampusAboutTab from "../CampusAboutTab/CampusAboutTab";
import CampusTeachersTab from "../CampusTeachersTab/CampusTeachersTab";
import CampusStudentsTab from "../CampusStudentsTab/CampusStudentsTab";
import { useTranslation } from "react-i18next";

interface IProps {
  campusId?: number;
  handleToggleAddUsersPopup: () => void;
  isSchool?: boolean;
}

const Campus: React.FC<IProps> = ({ handleToggleAddUsersPopup, campusId, isSchool = false }) => {
  const [t] = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [tabList, setTabList] = useState<any>([]);

  const getTabsList = () => {
    const campusTabList = [
      {
        title: `${t("school_campus_about_title")}`
      },
      {
        title: `${t("school_campus_teachers_title")}`
      },
      {
        title: `${t("school_campus_students_title")}`
      }
    ];
    if (campusTabList) {
      const result: { title: string; id: number }[] = [];

      campusTabList.map((item, index) => {
        const obj: { title: string; id: number } = {
          title: item.title,
          id: index
        };
        result.push(obj);
      });

      if (isSchool) {
        result.shift();
      }
      setTabList(result);
    }
  };

  const campusRef = useRef<HTMLDivElement>(null);

  const scrollToCampus = () => {
    const rect = campusRef.current?.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const offsetTop = rect.top + scrollTop;
    const desiredScrollTop = offsetTop - 100;

    window.scrollTo({
      top: desiredScrollTop,
      behavior: "smooth"
    });
  };

  const setTabHandler = (n: number) => {
    setActiveTabIndex(n);
    scrollToCampus();
  };

  useEffect(() => {
    getTabsList();
  }, []);

  if (!campusId) return <></>;

  return (
    <StyledCampus ref={campusRef}>
      <ReportTabs tabs={tabList} activeTabIndex={activeTabIndex} action={setTabHandler} />

      <div className="tab-content" id="myTabContent">
        {!isSchool && (
          <div className="tab-pane fade show active" id="home-tab-pane-0" role="tabpanel" aria-labelledby="home-tab">
            <CampusAboutTab campusId={campusId} />
          </div>
        )}
        <div
          className={`tab-pane fade ${isSchool && "show active"}`}
          id="home-tab-pane-1"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <CampusTeachersTab
            campusId={campusId}
            handleToggleAddUsersPopup={handleToggleAddUsersPopup}
            isSchool={isSchool}
          />
        </div>
        <div className="tab-pane fade" id="home-tab-pane-2" role="tabpanel" aria-labelledby="home-tab">
          <CampusStudentsTab
            campusId={campusId}
            handleToggleAddUsersPopup={handleToggleAddUsersPopup}
            isSchool={isSchool}
          />
        </div>
      </div>
    </StyledCampus>
  );
};

export default Campus;
