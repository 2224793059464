import { take, put } from "redux-saga/effects";
import { GET_CLASS_LIST_STATS, getClassListStatsActions } from "src/redux/actions/reports";

// services
import { getSchoolClassList } from "src/services/http/reports";

// types
import { GetClassListStatsRequestTypes, GetClassListStatsResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetClassListStatsRequest() {
  while (true) {
    const { payload }: IActionType<GetClassListStatsRequestTypes> = yield take(GET_CLASS_LIST_STATS.REQUEST);

    try {
      const result: GetClassListStatsResponseTypes = yield call(getSchoolClassList, payload);

      yield put(getClassListStatsActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getClassListStatsActions.error(e as any));
    }
  }
}

export default watchGetClassListStatsRequest;
