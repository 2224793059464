import { LOGIN, RESET_STATE } from "src/redux/actions/auth";
import { LoginResponseTypes } from "src/redux/actions/auth/types";

export const initialState: AuthState = {
  isLoading: false,
  authData: {
    userId: undefined,
    access_token: ""
  },
  error: undefined
};

export interface AuthState {
  isLoading: boolean;
  authData: {
    userId: string | undefined;
    access_token: string;
  };
  error: string | undefined;
}

const auth = (state = initialState, action: IActionType<LoginResponseTypes | string>): AuthState => {
  switch (action.type) {
    case LOGIN.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case LOGIN.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        authData: action.payload as LoginResponseTypes,
        error: undefined
      };
    }

    case LOGIN.ERROR: {
      return <AuthState>{
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default auth;
