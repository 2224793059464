import { get } from "lodash";
import { getToken, signOutUser } from "../localStorage";
import * as qs from "qs";

interface Options {
  [key: string]: any;
}

interface GlobalOptions {
  headers: {
    [key: string]: any;
  };
  mode: string;
}

/**
 * default headers
 * enabled cors
 */

export const globalOptions = (autoType = false): GlobalOptions => {
  const jsonType = "application/json";
  if (!autoType) {
    return {
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": jsonType
      },
      mode: "cors"
    };
  }
  return {
    headers: {
      Authorization: "Bearer " + getToken()
    },
    mode: "cors"
  };
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = async <T>(response: Response): Promise<T> => {
  let json;
  try {
    json = await parseJSON(response);
    switch (true) {
      case response.status >= 200 && response.status < 300:
        return json;
      case 401 === response.status:
        signOutUser();
    }
  } catch (e) {
    console.log(e); // todo create ErrorHandler
  }

  throw json;
};

//Fix for empty response from API
const parseJSON = (response: any) => {
  return response.text().then(function (text: any) {
    return text ? JSON.parse(text) : {};
  });
};

/**
 * @param url string
 * @param options object
 * @returns {Promise<T>}
 */
const http = async <T>(url: string, options: Options, autoType = false): Promise<T> => {
  const newJsonOptions = { ...globalOptions(autoType), ...options };

  const urlParams = qs.stringify(get(options, "params", {}), {
    arrayFormat: "brackets",
    skipNulls: true,
    encodeValuesOnly: true
  });
  const newUrl = `${process.env.REACT_APP_API_URL}/${url}${urlParams ? "?" : ""}${urlParams}`;

  const response = await fetch(newUrl, newJsonOptions as any /* todo */);
  return checkStatus(response);
};

export default http;
