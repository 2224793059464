import styled from "styled-components";

export const StyledAddPhotoPopup = styled.div`
  .photoContainer {
    position: relative;
    width: 100%;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .resize {
      display: flex;
      align-items: center;

      .percents {
        font-size: 19px;
        color: #165b95;
        font-weight: 600;
        margin-left: 3px;
      }
    }
  }

  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 1px solid #165b95;
    border-bottom: 1px solid #165b95;
    background: #165b95;
    width: 170px;
    margin: 0 8px;
    cursor: pointer;
  }

  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #165b95;
    background: #165b95;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #165b95;
    background: #165b95;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .reactEasyCrop_CropArea {
    display: none;
  }

  @media screen and (max-width: 900px) {
    .photoContainer {
      height: 350px;
    }

    .actionsWrapper {
      flex-direction: column;

      .resize {
        margin-bottom: 35px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .photoContainer {
      height: 200px;
    }

    .actionsWrapper {
      .resize {
        margin-bottom: 25px;
      }
    }
  }
`;
