import { take, put } from "redux-saga/effects";
import { GET_BOOK_LIST, getBookListActions } from "src/redux/actions/book";

// services
import { getBookListExtended } from "src/services/http/book";

// types
import { GetBookListRequestTypes, GetBookListResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookListRequest() {
  while (true) {
    const { payload }: IActionType<GetBookListRequestTypes> = yield take(GET_BOOK_LIST.REQUEST);

    try {
      const { resetBooks, ...restPayload } = payload;

      const result: GetBookListResponseTypes = yield call(getBookListExtended, restPayload);
      yield put(getBookListActions.success({ ...result, clear: resetBooks || !restPayload.offset }));
    } catch (e) {
      console.log(e, "error");
      yield put(getBookListActions.error(e as any));
    }
  }
}

export default watchGetBookListRequest;
