import http from "../http";

export const sso = ({ token, grant_type, deviceId }: { token: string; grant_type: string; deviceId: string }) => {
  return http("token", {
    body: `token=${encodeURIComponent(token)}&grant_type=${grant_type}`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    params: { DeviceId: deviceId },
    method: "POST"
  });
};
