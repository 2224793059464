import { take, put } from "redux-saga/effects";

// services
import { createNewGroup } from "src/services/http/group";

// types
import { CreateNewGroupRequestTypes, CreateNewGroupResponseTypes } from "src/redux/actions/group/types";

// actions
import { CREATE_NEW_GROUP, createNewGroupActions } from "src/redux/actions/group";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchCreateNewGroupRequest() {
  while (true) {
    const { payload }: IActionType<CreateNewGroupRequestTypes> = yield take(CREATE_NEW_GROUP.REQUEST);

    try {
      const response: CreateNewGroupResponseTypes = yield call(createNewGroup, payload);
      yield put(createNewGroupActions.success(response));
    } catch (e) {
      yield put(createNewGroupActions.error(e as any));
    }
  }
}

export default watchCreateNewGroupRequest;
