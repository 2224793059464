import { take, put } from "redux-saga/effects";
import { UNASSIGN_CONTENT_FROM_ASSIGNMENT, unassignContentFromAssignmentActions } from "src/redux/actions/assignments";

// services
import { unassignContentFromAssignment } from "src/services/http/assignments";

// types
import { UnassignContentFromAssignmentRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchUnassignContentFromAssignmentRequest() {
  while (true) {
    const { payload }: IActionType<UnassignContentFromAssignmentRequestTypes> = yield take(
      UNASSIGN_CONTENT_FROM_ASSIGNMENT.REQUEST
    );

    try {
      yield call(unassignContentFromAssignment, payload);

      yield put(unassignContentFromAssignmentActions.success(payload));
    } catch (e) {
      console.log(e, "error");
      yield put(unassignContentFromAssignmentActions.error(e as any));
    }
  }
}

export default watchUnassignContentFromAssignmentRequest;
