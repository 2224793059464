import React, { useState } from "react";
import { Layout } from "../../components";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import LoaderLocal from "../../components/LoaderLocal";

const TeacherResources = () => {
  const [t] = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <Layout>
      <div className="ds-card">
        <div className="d-flex align-items-center  justify-content-between pb-3">
          <div className="title mb-0">
            <h1 className="mb-0">{t("sidebar_link_teacher_resources")}</h1>
          </div>
        </div>
      </div>
      <div className="ds-card sec_body">
        <Iframe
          url="https://drive.google.com/embeddedfolderview?id=183T0R5rbLmrJMkkgadMF9EveGpL7HPYJ"
          width="100%"
          height="500px"
          display="initial"
          position="relative"
          styles={{ position: isLoaded ? "relative" : "absolute" }}
          onLoad={handleLoad}
        />
        {!isLoaded ? (
          <div className="loader-wrapper d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
            <LoaderLocal />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default TeacherResources;
