import React from "react";

import ReportTabButton from "../ReportTabButton";

// types
import { ReportTabButtonPropTypes } from "./ReportTabsTypes";

const ReportTabs = ({ action, tabs, activeTabIndex }: ReportTabButtonPropTypes) => {
  return (
    <div className="fancy_tabs">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map(({ title, id }, index) => {
          return (
            <li className="nav-item" role="presentation" key={title + id}>
              <ReportTabButton
                title={title}
                action={() => action(index)}
                isActive={activeTabIndex === index}
                id={id}
                btnClass={"nav-link"}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ReportTabs;
