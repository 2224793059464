import {
  AddAudienceToAssignmentRequestTypes,
  AddContentToAssignmentRequestTypes,
  CreateFirstSectionAssignmentRequestTypes,
  PublishAssignmentRequestTypes,
  UnassignAudienceFromAssignmentRequestTypes,
  UnassignContentFromAssignmentRequestTypes
} from "src/redux/actions/assignments/types";
import http from "../http";
import { getToken } from "../localStorage";

export const getBookGames = ({ bookId }: { bookId: number }) => {
  return http(`api/books/${bookId}/games`, {
    method: "GET"
  });
};

export const createAssignment = ({
  bookId,
  assignToUserId,
  gameId,
  challengeName,
  challengeTypeId,
  dueDate
}: {
  bookId: number;
  assignToUserId: number;
  gameId?: number;
  challengeName: string;
  challengeTypeId: number;
  dueDate: string;
}) => {
  return http("api/createChallenge", {
    method: "POST",
    body: `bookId=${bookId}&assignToUserId=${assignToUserId}&challengeName=${challengeName}&dueDate=${dueDate}&challengeTypeId=${challengeTypeId}${
      gameId !== undefined ? `&gameId=${gameId}` : ""
    }`,
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const createFirstStepAssignment = ({
  assignmentName,
  reward,
  startDate,
  dueDate,
  description,
  id
}: CreateFirstSectionAssignmentRequestTypes) => {
  return http(`api/assignment/${id || ""}`, {
    method: !id ? "POST" : "PUT",
    body: `assignmentName=${assignmentName}&reward=${reward}&startDate=${startDate.toISOString()}&dueDate=${dueDate.toISOString()}&description=${description}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const addContentToAssignment = ({ id, ...books }: AddContentToAssignmentRequestTypes) => {
  return http(`api/assignment/${id}/addContent`, {
    method: "POST",
    body: JSON.stringify({
      ...books
    }),
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};

export const unassignContentFromAssignment = ({
  id,
  challengeContentId
}: UnassignContentFromAssignmentRequestTypes) => {
  return http(`api/assignment/${id}/removeContent`, {
    method: "GET",
    params: { contentId: challengeContentId },
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};

export const publishAssignment = ({ id }: PublishAssignmentRequestTypes) => {
  return http(`api/assignment/${id}/publish`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};

export const addAudienceToAssignment = ({ assignmentId, assignees }: AddAudienceToAssignmentRequestTypes) => {
  return http(`api/assignment/${assignmentId}/assign`, {
    method: "POST",
    body: JSON.stringify({
      assignees
    }),
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};

export const unassignAudienceFromAssignment = ({ id, assignees }: UnassignAudienceFromAssignmentRequestTypes) => {
  return http(`api/assignment/${id}/unassign`, {
    method: "Post",
    body: JSON.stringify({
      assignees
    }),
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};

export const getAssignments = ({ state }: { state: number }) => {
  return http("api/Assignments", {
    method: "GET",
    params: { state: state }
  });
};

export const getAssignmentById = ({ id }: { id: number }) => {
  return http(`api/Assignment/${id}`, {
    method: "GET"
  });
};

export const getStudentAssignments = ({ userId }: { userId: number }) => {
  return http("api/Challenges", {
    method: "GET",
    params: { userId: userId, challengeTypeId: 9 }
  });
};

export const getQuizDetails = ({ quizId, userId }: { quizId: number; userId?: string }) => {
  return http(`api/Games/${quizId}`, {
    method: "GET",
    params: { userId: userId }
  });
};

export const getAssignmentDetails = ({ quizId, userId }: { quizId: number; userId?: number }) => {
  return http(`api/Challenge/${quizId}/Details`, {
    method: "GET",
    params: { userId: userId }
  });
};

export const markCompleteAssignment = ({ assignmentId, userId }: { assignmentId: number; userId: number }) => {
  return http(`api/challenge/${assignmentId}/completeChallenge`, {
    method: "POST",
    params: { userId: userId }
  });
};

export const getGetAssignmentStatus = ({ assignmentId }: { assignmentId: number }) => {
  return http(`api/assignment/${assignmentId}/status`, {
    method: "GET"
  });
};

export const getGetAssignmentUserActivity = ({ assignmentId, userId }: { assignmentId: number; userId: number }) => {
  return http(`api/assignment/${assignmentId}/studentStatus`, {
    method: "GET",
    params: { userId: userId }
  });
};
