import styled from "styled-components";

export const StyledEditUserPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .resize {
      display: flex;
      align-items: center;

      .percents {
        font-size: 19px;
        color: #165b95;
        font-weight: 600;
        margin-left: 3px;
      }
    }
  }
`;
