import * as React from "react";
import { Range } from "react-range";
import { RangePropsType } from "./RangeTypes";
import { getTrackBackground } from "react-range";

const RangeInput = ({ min = 0, max = 100, step = 1, values, onChange, ...props }: RangePropsType) => {
  return (
    <div className="range-wrapper d-flex align-items-center">
      <span className="pe-3 fw-semibold h5 m-0">0</span>
      <Range
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={(values) => onChange(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <div
              {...props}
              style={{
                ...props.style,
                height: "6px",
                width: "100%",
                background: getTrackBackground({
                  values: values,
                  colors: ["#AED5F1", "#165B95", "#AED5F1"],
                  min,
                  max
                })
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "20px",
              width: "20px",
              backgroundColor: "#165B95",
              borderRadius: "50%",
              border: "2px solid #F2F9FF",
              boxShadow: "none",
              outline: "none"
            }}
          />
        )}
        {...props}
      />
      <span className="ps-3 fw-semibold h5 m-0">{max}</span>
    </div>
  );
};

export default RangeInput;
