import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useTranslation } from "react-i18next";

// utils
import { signOutUser } from "src/services/localStorage";
import { resetStateAction } from "src/redux/actions/auth";

// types
import { LayoutPropsType } from "./LayoutTypes";

const Layout = ({ children }: LayoutPropsType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarActive, setSidebarActive] = useState<boolean>(false);
  const [t] = useTranslation();

  const sidebarToggle = () => {
    setSidebarActive(!sidebarActive);
    const body = document.querySelector("body");

    if (body) {
      sidebarActive ? body.classList.remove("sidebar-active") : body.classList.add("sidebar-active");
    }
  };

  const handleLogout = () => {
    dispatch(resetStateAction);
    signOutUser();
    navigate("/login");
  };

  return (
    <>
      <Sidebar handleLogout={handleLogout} />
      <div className="main-content" id="mainContent">
        <Header sidebarToggle={sidebarToggle} handleLogout={handleLogout} />
        {children}
        <div className="ds-footer">
          <div className="left-content">
            <p>©{t("footer_copyright")}</p>
          </div>
          <div className="right-content">
            <ul>
              <li>
                <a href="#">{t("footer_link_terms_conditions")}</a>
              </li>
              <li>
                <a href="#">{t("footer_link_privacy_policy")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
