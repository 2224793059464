import { take, put } from "redux-saga/effects";
import { GET_CHILDREN_STATS, getChildrenStatsActions } from "src/redux/actions/reports";

// services
import { getChildrenStats } from "src/services/http/reports";

// types
import { GetChildrenStatsResponseTypes } from "src/redux/actions/reports/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchChildrenStatsRequest() {
  while (true) {
    const { payload }: IActionType<{ userId: string | undefined }> = yield take(GET_CHILDREN_STATS.REQUEST);

    try {
      const result: GetChildrenStatsResponseTypes = yield call(getChildrenStats, payload);
      yield put(getChildrenStatsActions.success(result));
    } catch (e) {
      yield put(getChildrenStatsActions.error(e as any));
    }
  }
}

export default watchChildrenStatsRequest;
