import { take, put } from "redux-saga/effects";
import { SSO, ssoActions } from "src/redux/actions/auth";
import { loginActions } from "src/redux/actions/auth";
import { v4 as uuidv4 } from "uuid";

// services
import { setToken, setRefreshToken, setTokenExpDate } from "src/services/localStorage";
import { sso } from "src/services/sso";

// types
import { SSORequestTypes, LoginResponseTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchSSORequest() {
  while (true) {
    const { payload }: IActionType<SSORequestTypes> = yield take(SSO.REQUEST);

    const { onError, onSuccess, ...restPayload } = payload as SSORequestTypes;

    try {
      const result: LoginResponseTypes = yield call(sso, { ...restPayload, deviceId: uuidv4().toUpperCase() });

      setToken(result.access_token);
      setRefreshToken(result.refresh_token);
      setTokenExpDate(result[".expires"]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(loginActions.success({ access_token: result.access_token, userId: result.UserId }));
      onSuccess();
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.error || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(ssoActions.error(e.error || "Something went wrong, try again later"));
    }
  }
}

export default watchSSORequest;
