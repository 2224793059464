import { requestTypeCreator } from "src/helpers/index";
import {
  GetBookListRequestTypes,
  GetBookListResponseTypes,
  GetBookItemResponseTypes,
  GetCategoryListResponseTypes,
  GetDataLengthListResponseTypes,
  GetTagListRequestTypes,
  GetTagListResponseTypes,
  GetBookURLRequestTypes,
  GetTeacherGuidanceUrlRequestTypes
} from "./types";

export const GET_BOOK_LIST = requestTypeCreator("GET_BOOK_LIST");
export const GET_BOOK_ITEM = requestTypeCreator("GET_BOOK_ITEM");
export const GET_SIMILAR_BOOKS = requestTypeCreator("GET_SIMILAR_BOOKS");
export const GET_RELATED_BOOKS = requestTypeCreator("GET_RELATED_BOOKS");
export const GET_CATEGORY_LIST = requestTypeCreator("GET_CATEGORY_LIST");
export const GET_CEFR_LIST = requestTypeCreator("GET_CEFR_LIST");
export const GET_TOPIC_LIST = requestTypeCreator("GET_TOPIC_LIST");
export const GET_BOOK_TYPES_LIST = requestTypeCreator("GET_BOOK_TYPES_LIST");
export const GET_LENGTH_LIST = requestTypeCreator("GET_LENGTH_LIST");
export const GET_SERIES_LIST = requestTypeCreator("GET_SERIES_LIST");
export const GET_AUTHOR_LIST = requestTypeCreator("GET_AUTHOR_LIST");
export const GET_BOOK_URL = requestTypeCreator("GET_BOOK_URL");
export const GET_TEACHER_GUIDANCE_URL = requestTypeCreator("GET_TEACHER_GUIDANCE_URL");
export const RESET_BOOKS_ACTION_TYPE = "RESET_BOOKS_ACTION_TYPE";

export const getBookListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetBookListRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_LIST.REQUEST
    };
  },
  success: (payload: GetBookListResponseTypes): IActionType<GetBookListResponseTypes> => ({
    payload,
    type: GET_BOOK_LIST.SUCCESS
  })
};

export const getBookItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_ITEM.ERROR
  }),
  request: (payload: any): IActionType<GetBookItemResponseTypes> => {
    return {
      payload,
      type: GET_BOOK_ITEM.REQUEST
    };
  },
  success: (payload: GetBookItemResponseTypes | undefined): IActionType<GetBookItemResponseTypes | undefined> => ({
    payload,
    type: GET_BOOK_ITEM.SUCCESS
  })
};

export const getCategoryListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CATEGORY_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_CATEGORY_LIST.REQUEST
    };
  },
  success: (payload: GetCategoryListResponseTypes): IActionType<GetCategoryListResponseTypes> => ({
    payload,
    type: GET_CATEGORY_LIST.SUCCESS
  })
};

export const getCEFRListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CEFR_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_CEFR_LIST.REQUEST
    };
  },
  success: (payload: GetCategoryListResponseTypes): IActionType<GetCategoryListResponseTypes> => ({
    payload,
    type: GET_CEFR_LIST.SUCCESS
  })
};

export const getTopicListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_TOPIC_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_TOPIC_LIST.REQUEST
    };
  },
  success: (payload: GetCategoryListResponseTypes): IActionType<GetCategoryListResponseTypes> => ({
    payload,
    type: GET_TOPIC_LIST.SUCCESS
  })
};

export const getBookTypesListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_TYPES_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_TYPES_LIST.REQUEST
    };
  },
  success: (payload: GetCategoryListResponseTypes): IActionType<GetCategoryListResponseTypes> => ({
    payload,
    type: GET_BOOK_TYPES_LIST.SUCCESS
  })
};

export const getLengthListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_LENGTH_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_LENGTH_LIST.REQUEST
    };
  },
  success: (payload: GetDataLengthListResponseTypes): IActionType<GetDataLengthListResponseTypes> => ({
    payload,
    type: GET_LENGTH_LIST.SUCCESS
  })
};

export const getSeriesListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SERIES_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_SERIES_LIST.REQUEST
    };
  },
  success: (payload: GetTagListResponseTypes): IActionType<GetTagListResponseTypes> => ({
    payload,
    type: GET_SERIES_LIST.SUCCESS
  })
};

export const getAuthorListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_AUTHOR_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_AUTHOR_LIST.REQUEST
    };
  },
  success: (payload: GetTagListResponseTypes): IActionType<GetTagListResponseTypes> => ({
    payload,
    type: GET_AUTHOR_LIST.SUCCESS
  })
};

export const getBookURLActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_URL.ERROR
  }),
  request: (payload: any): IActionType<GetBookURLRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_URL.REQUEST
    };
  },
  success: (payload: string): IActionType<string> => ({
    payload,
    type: GET_BOOK_URL.SUCCESS
  })
};

export const getTeacherGuidanceUrlActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_TEACHER_GUIDANCE_URL.ERROR
  }),
  request: (payload: any): IActionType<GetTeacherGuidanceUrlRequestTypes> => {
    return {
      payload,
      type: GET_TEACHER_GUIDANCE_URL.REQUEST
    };
  },
  success: (payload: string): IActionType<string> => ({
    payload,
    type: GET_TEACHER_GUIDANCE_URL.SUCCESS
  })
};

export const resetBooksAction = {
  type: RESET_BOOKS_ACTION_TYPE
};
