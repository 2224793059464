import {
  GET_BOOK_LIST,
  GET_BOOK_ITEM,
  GET_AUTHOR_LIST,
  GET_CATEGORY_LIST,
  GET_LENGTH_LIST,
  GET_SERIES_LIST,
  GET_BOOK_URL,
  GET_SIMILAR_BOOKS,
  GET_RELATED_BOOKS,
  GET_TOPIC_LIST,
  GET_CEFR_LIST,
  GET_BOOK_TYPES_LIST,
  GET_TEACHER_GUIDANCE_URL,
  RESET_BOOKS_ACTION_TYPE
} from "src/redux/actions/book";
import {
  GetBookListResponseTypes,
  GetBookItemResponseTypes,
  GetCategoryListResponseTypes,
  GetDataLengthListResponseTypes,
  GetTagListResponseTypes,
  GetTagListItemTypes
} from "src/redux/actions/book/types";
import { RESET_STATE } from "../actions/auth";

export const initialState: BookState = {
  isLoadingBooksData: false,
  isLoadingBooksList: true,
  bookURL: undefined,
  bookList: {
    books: [],
    totalRows: undefined
  },
  book: undefined,
  similarBooks: {
    books: [],
    totalRows: undefined
  },
  categoryList: undefined,
  cefrList: undefined,
  topicList: undefined,
  bookTypes: undefined,
  lengthList: undefined,
  seriesList: undefined,
  authorList: undefined,
  error: undefined
};

export interface BookState {
  isLoadingBooksData: boolean;
  isLoadingBooksList: boolean;
  bookURL: string | undefined;
  bookList: GetBookListResponseTypes | undefined;
  book: GetBookItemResponseTypes | undefined;
  similarBooks: GetBookListResponseTypes | undefined;
  categoryList: GetCategoryListResponseTypes | undefined;
  cefrList: GetTagListItemTypes[] | undefined;
  bookTypes: GetTagListItemTypes[] | undefined;
  topicList: GetTagListItemTypes[] | undefined;
  lengthList: GetDataLengthListResponseTypes | undefined;
  seriesList: GetTagListResponseTypes | undefined;
  authorList: GetTagListResponseTypes | undefined;
  error: any;
}

const book = (
  state = initialState,
  action: IActionType<
    | GetBookListResponseTypes
    | GetBookItemResponseTypes
    | GetCategoryListResponseTypes
    | GetDataLengthListResponseTypes
    | GetTagListResponseTypes
    | string
  >
): BookState => {
  switch (action.type) {
    case GET_BOOK_ITEM.REQUEST:
    case GET_SIMILAR_BOOKS.REQUEST:
    case GET_RELATED_BOOKS.REQUEST:
    case GET_CATEGORY_LIST.REQUEST:
    case GET_CEFR_LIST.REQUEST:
    case GET_TOPIC_LIST.REQUEST:
    case GET_BOOK_TYPES_LIST.REQUEST:
    case GET_LENGTH_LIST.REQUEST:
    case GET_SERIES_LIST.REQUEST:
    case GET_AUTHOR_LIST.REQUEST:
    case GET_BOOK_URL.REQUEST:
    case GET_TEACHER_GUIDANCE_URL.REQUEST:
      return {
        ...state,
        isLoadingBooksData: true,
        error: undefined
      };

    case GET_BOOK_LIST.REQUEST:
      return {
        ...state,
        isLoadingBooksList: true,
        error: undefined
      };

    case GET_BOOK_LIST.SUCCESS:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      return {
        ...state,
        isLoadingBooksList: false,
        bookList: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          totalRows: action.payload?.totalRows,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          books: action.payload.clear ? action.payload.books : state.bookList?.books?.concat(action.payload.books)
        },
        error: undefined
      };

    case GET_BOOK_ITEM.SUCCESS:
      return {
        ...state,
        book: action.payload as GetBookItemResponseTypes,
        isLoadingBooksData: false,
        error: undefined
      };

    case GET_SIMILAR_BOOKS.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        similarBooks: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          totalRows: action.payload?.totalRows,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          books: action.payload.clear ? action.payload.books : state.similarBooks?.books?.concat(action.payload.books)
        },
        error: undefined
      };

    case GET_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        categoryList: action.payload as GetCategoryListResponseTypes,
        error: undefined
      };

    case GET_CEFR_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        cefrList: action.payload as GetTagListItemTypes[],
        error: undefined
      };

    case GET_BOOK_TYPES_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        bookTypes: action.payload as GetTagListItemTypes[],
        error: undefined
      };

    case GET_TOPIC_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        topicList: action.payload as GetTagListItemTypes[],
        error: undefined
      };

    case GET_LENGTH_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        lengthList: action.payload as GetDataLengthListResponseTypes,
        error: undefined
      };

    case GET_SERIES_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        seriesList: action.payload as GetTagListResponseTypes,
        error: undefined
      };

    case GET_AUTHOR_LIST.SUCCESS:
      return {
        ...state,
        isLoadingBooksData: false,
        authorList: action.payload as GetTagListResponseTypes,
        error: undefined
      };

    case GET_BOOK_URL.SUCCESS:
      return {
        ...state,
        bookURL: action.payload as string,
        isLoadingBooksData: false,
        error: undefined
      };

    case GET_TEACHER_GUIDANCE_URL.SUCCESS:
      return {
        ...state,
        book: {
          ...state.book,
          teacherGuidanceUrl: action.payload
        } as GetBookItemResponseTypes,
        isLoadingBooksData: false,
        error: undefined
      };

    case GET_BOOK_LIST.ERROR:
    case GET_BOOK_ITEM.ERROR:
    case GET_SIMILAR_BOOKS.ERROR:
    case GET_RELATED_BOOKS.ERROR:
    case GET_CATEGORY_LIST.ERROR:
    case GET_LENGTH_LIST.ERROR:
    case GET_SERIES_LIST.ERROR:
    case GET_AUTHOR_LIST.ERROR:
    case GET_BOOK_URL.ERROR:
    case GET_TEACHER_GUIDANCE_URL.ERROR:
      return {
        ...state,
        isLoadingBooksData: false,
        error: action.payload
      };

    case RESET_BOOKS_ACTION_TYPE:
    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default book;
