import { take, put } from "redux-saga/effects";
import { ADD_CONTENT_TO_ASSIGNMENT, addContentToAssignmentActions } from "src/redux/actions/assignments";

// services
import { addContentToAssignment } from "src/services/http/assignments";

// types
import { AddContentToAssignmentRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchAddContentToAssignmentRequest() {
  while (true) {
    const { payload }: IActionType<AddContentToAssignmentRequestTypes> = yield take(ADD_CONTENT_TO_ASSIGNMENT.REQUEST);

    try {
      yield call(addContentToAssignment, payload);

      yield put(addContentToAssignmentActions.success(payload));
    } catch (e) {
      console.log(e, "error");
      yield put(addContentToAssignmentActions.error(e as any));
    }
  }
}

export default watchAddContentToAssignmentRequest;
