import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { StyledAddPhotoPopup } from "./AddPhotoPopup.styles";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import getCroppedImg from "./helpers/cropImage";

import decreaseImg from "src/assets/images/decrease.svg";
import increaseImg from "src/assets/images/increase.svg";

interface IProps {
  isShow: boolean;
  handleToggle: () => void;
  imgUrl?: string;
  handleChangeFile: (file: File) => void;
}

const AddPhotoPopup: React.FC<IProps> = ({ isShow, handleToggle, imgUrl, handleChangeFile }) => {
  const [t] = useTranslation();
  const minZoom = 1;
  const maxZoom = 4;
  const zoomStep = 0.1;
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{ width: number; height: number; x: number; y: number }>({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });
  const [croppedImage, setCroppedImage] = useState<any>(null);

  if (!imgUrl) {
    return <></>;
  }

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imgUrl, croppedAreaPixels);
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (croppedImage) {
      handleChangeFile(croppedImage);
    }
  }, [croppedImage]);

  function calculatePercentage(value: number) {
    if (value <= minZoom) {
      return 0;
    }
    if (value >= maxZoom) {
      return 100;
    }
    return (((value - minZoom) / (maxZoom - minZoom)) * 100).toFixed();
  }

  const changeZoom = (action: "+" | "-") => {
    if (action === "+") {
      setZoom((prev) => (prev + zoomStep <= maxZoom ? prev + zoomStep : prev));
    }
    if (action === "-") {
      setZoom((prev) => (prev - zoomStep >= minZoom ? prev - zoomStep : prev));
    }
  };

  return (
    <Modal size="md" isShow={isShow} title={"Add photo"} handleClose={handleToggle}>
      <StyledAddPhotoPopup>
        <div className="photoContainer">
          <Cropper
            image={imgUrl}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="actionsWrapper">
          <div className="resize">
            <button
              className="zoomBtn"
              onClick={() => {
                changeZoom("-");
              }}
            >
              <img src={decreaseImg} alt="decrease" />
            </button>
            <input
              type="range"
              value={zoom}
              min={minZoom}
              max={maxZoom}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setZoom(parseFloat(e.target.value));
              }}
              className="zoom-range"
            />
            <button
              className="zoomBtn"
              onClick={() => {
                changeZoom("+");
              }}
            >
              <img src={increaseImg} alt="increase" />
            </button>
            <span className="percents">{calculatePercentage(zoom)}%</span>
          </div>
          <div className="buttons">
            <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleToggle}>
              {t("assignments_modal_button_label_cancel")}
            </button>
            <button
              type="submit"
              className="btn-submit"
              disabled={false}
              onClick={showCroppedImage}
              data-bs-dismiss="modal"
            >
              {t("schools_save_btn")}
            </button>
          </div>
        </div>
      </StyledAddPhotoPopup>
    </Modal>
  );
};

export default AddPhotoPopup;
