import { take, put } from "redux-saga/effects";
import { GET_CATEGORY_LIST, getCategoryListActions } from "src/redux/actions/book";

// services
import { getTagList } from "src/services/http/book";

// types
import { GetCategoryListResponseTypes, GetTagListRequestTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetCategoryListRequest() {
  while (true) {
    const { payload }: IActionType<GetTagListRequestTypes> = yield take(GET_CATEGORY_LIST.REQUEST);

    const { onSuccess, ...restPayload } = payload as GetTagListRequestTypes;

    try {
      const result: GetCategoryListResponseTypes = yield call(getTagList, restPayload);
      yield put(getCategoryListActions.success(result));

      if (onSuccess && result.length > 0) {
        onSuccess(result);
      }
    } catch (e) {
      console.log(e, "error");
      yield put(getCategoryListActions.error(e as any));
    }
  }
}

export default watchGetCategoryListRequest;
