import { requestTypeCreator } from "src/helpers/index";
import { GetUserInfoResponseTypes } from "./types";

export const GET_USER_INFO = requestTypeCreator("GET_USER_INFO");
export const RESET_USER_STATE = "RESET_USER_STATE";

export const getUserInfoActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_USER_INFO.ERROR
  }),
  request: (payload: { userId: string | undefined }) => ({
    payload,
    type: GET_USER_INFO.REQUEST
  }),
  success: (payload: GetUserInfoResponseTypes): IActionType<GetUserInfoResponseTypes> => ({
    payload,
    type: GET_USER_INFO.SUCCESS
  })
};
