import { GET_DASHBOARD_INFO } from "src/redux/actions/dashboard";
import { GetDashboardInfoResponseTypes } from "src/redux/actions/dashboard/types";
import { RESET_STATE } from "../actions/auth";

export const initialState: DashboardState = {
  isLoading: false,
  dashboardData: undefined,
  error: undefined
};

export interface DashboardState {
  isLoading: boolean;
  dashboardData: GetDashboardInfoResponseTypes | undefined;
  error: any;
}

const dashboard = (state = initialState, action: IActionType<GetDashboardInfoResponseTypes>): DashboardState => {
  switch (action.type) {
    case GET_DASHBOARD_INFO.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case GET_DASHBOARD_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload as GetDashboardInfoResponseTypes,
        error: undefined
      };
    }

    case GET_DASHBOARD_INFO.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default dashboard;
