import http from "../http";

export const getCountries = () => {
  return http("api/countries", {
    method: "GET"
  });
};

export const createSchool = ({
  schoolName,
  externalId,
  location,
  startDate,
  endDate,
  description,
  numberOfTeacher,
  numberOfStudent
}: {
  schoolName: string;
  externalId: number;
  location: string;
  startDate: Date;
  endDate: Date;
  description: string;
  numberOfTeacher: string;
  numberOfStudent: string;
}) => {
  if (
    schoolName &&
    externalId &&
    location &&
    startDate &&
    endDate &&
    description &&
    numberOfTeacher &&
    numberOfStudent
  ) {
    const payload = {
      name: schoolName,
      countryId: +location,
      externalId: +externalId,
      description,
      numberOfStudents: numberOfStudent,
      numberOfTeachers: numberOfTeacher,
      startDate,
      endDate
    };

    return http("api/schools", {
      method: "POST",
      body: JSON.stringify(payload)
    });
  }
};

export const uploadSchoolPhoto = (file: File | null, schoolId: any) => {
  if (file && schoolId) {
    const formData = new FormData();
    formData.append("file", file);

    return http(
      `api/schools/${schoolId}/image`,
      {
        method: "POST",
        body: formData
      },
      true
    );
  }
};

export const getSchoolsList = ({ iso }: { iso: string }) => {
  return http("api/GetSchoolsByCountry", {
    method: "GET",
    params: { iso }
  });
};

export const addUsers = (schoolId: string, payload: any) => {
  if (payload) {
    return http(`api/schools/${schoolId}/AddUsers`, {
      method: "POST",
      body: JSON.stringify(payload)
    });
  }
};

export const getSchool = ({ id }: { id: number }) => {
  return http(`api/schools/${id}/details`, {
    method: "GET"
  });
};

export const createCampus = ({
  id,
  name,
  countryId,
  externalId,
  startDate,
  endDate,
  description,
  numberOfTeachers,
  numberOfStudents
}: {
  id: number;
  name: string;
  countryId: number;
  externalId: number;
  startDate?: Date;
  endDate?: Date;
  description: string;
  numberOfTeachers: string;
  numberOfStudents: string;
}) => {
  if (name && externalId && countryId && description && numberOfTeachers && numberOfStudents) {
    const payload = {
      name: name,
      countryId: +countryId,
      externalId: +externalId,
      description,
      numberOfStudents: numberOfStudents,
      numberOfTeachers: numberOfTeachers,
      startDate,
      endDate
    };

    return http(`api/schools/${id}/campus`, {
      method: "POST",
      body: JSON.stringify(payload)
    });
  }
};

export const getCampusTeachers = async ({ campusId }: { campusId: number }) => {
  if (campusId) {
    const teachersRes = await http(`api/schools/${campusId}/teachers`, {
      method: "GET"
    });
    if (teachersRes) {
      return {
        schoolId: campusId,
        users: teachersRes
      };
    }
  }
};

export const getCampusStudents = async ({ campusId }: { campusId: number }) => {
  if (campusId) {
    const studentsRes = await http(`api/schools/${campusId}/students`, {
      method: "GET"
    });
    if (studentsRes) {
      return {
        schoolId: campusId,
        users: studentsRes
      };
    }
  }
};

export const getSchoolClasses = async ({ schoolId }: { schoolId: number }) => {
  return await http(`api/schools/${schoolId}/Classes`, {
    method: "GET"
  });
};
