import { take, put } from "redux-saga/effects";
import { GET_CLASS_ITEM_STATS, getClassItemStatsActions } from "src/redux/actions/reports";

// services
import { getClassItemStats } from "src/services/http/reports";

// types
import { GetClassItemStatsResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetClassItemStatsRequest() {
  while (true) {
    const { payload }: IActionType<GetClassItemStatsResponseTypes> = yield take(GET_CLASS_ITEM_STATS.REQUEST);

    try {
      const result: GetClassItemStatsResponseTypes = yield call(getClassItemStats, payload);

      yield put(getClassItemStatsActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getClassItemStatsActions.error(e as any));
    }
  }
}

export default watchGetClassItemStatsRequest;
