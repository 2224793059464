import React from "react";
import { useTranslation } from "react-i18next";

// assets
import editIcon from "src/assets/images/pencil.svg";

// types
import { StudentCardPropTypes } from "./StudentClassCardTypes";
import ButtonPrimary from "../../../../components/ButtonPrimary/ButtonPrimary";

const StudentClassCard = ({
  imageUrl,
  name,
  readingLevel,
  vocabulary,
  fluencyRate,
  pronunciationRate,
  id,
  onClick,
  isSelected,
  viewMode = false
}: StudentCardPropTypes) => {
  const [t] = useTranslation();

  return (
    <div
      className={`kid_card student_class_card${isSelected ? " selected" : ""}`}
      onClick={() => (onClick ? onClick(id) : {})}
    >
      <div className="kid_img">
        <div className="kid_img_inner">
          {imageUrl ? (
            <img src={imageUrl} alt="photo" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person"
              viewBox="0 0 16 16"
            >
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
            </svg>
          )}
        </div>
        {!viewMode && (
          <a className="kid_card-edit" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
            <img src={editIcon} alt="image" />
          </a>
        )}
      </div>
      <div className="kid_name">
        <h4>{name}</h4>
        <p style={{ display: "none" }}>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_143_32948)">
              <path
                d="M3.89706 2.81527V13.4751H12.6188V2.81527H3.89706ZM3.41252 1.84619H13.1033C13.2318 1.84619 13.3551 1.89724 13.4459 1.98811C13.5368 2.07898 13.5878 2.20222 13.5878 2.33073V13.9597C13.5878 14.0882 13.5368 14.2114 13.4459 14.3023C13.3551 14.3932 13.2318 14.4442 13.1033 14.4442H3.41252C3.28401 14.4442 3.16077 14.3932 3.0699 14.3023C2.97903 14.2114 2.92798 14.0882 2.92798 13.9597V2.33073C2.92798 2.20222 2.97903 2.07898 3.0699 1.98811C3.16077 1.89724 3.28401 1.84619 3.41252 1.84619Z"
                fill="#38A6DE"
              ></path>
              <path
                d="M1.47437 13.4755H15.0415V14.4445H1.47437V13.4755ZM5.35068 3.78467H7.28884V5.23829H5.35068V3.78467Z"
                fill="#38A6DE"
              ></path>
              <path
                d="M6.31966 13.4755H10.196V12.5064C10.196 11.9923 9.99178 11.4994 9.62831 11.1359C9.26483 10.7724 8.77185 10.5682 8.25782 10.5682C7.74379 10.5682 7.25081 10.7724 6.88734 11.1359C6.52386 11.4994 6.31966 11.9923 6.31966 12.5064V13.4755ZM8.25782 9.59914C9.02887 9.59914 9.76834 9.90544 10.3136 10.4507C10.8588 10.9959 11.1651 11.7353 11.1651 12.5064V14.4445H5.35059V12.5064C5.35059 11.7353 5.65688 10.9959 6.2021 10.4507C6.74731 9.90544 7.48678 9.59914 8.25782 9.59914V9.59914ZM5.35059 6.6919H7.28874V8.14552H5.35059V6.6919ZM9.2269 3.78467H11.1651V5.23829H9.2269V3.78467ZM9.2269 6.6919H11.1651V8.14552H9.2269V6.6919Z"
                fill="#38A6DE"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_143_32948">
                <rect width="15.5053" height="15.5053" fill="white" transform="translate(0.505371 0.877441)"></rect>
              </clipPath>
            </defs>
          </svg>
          Sanghvi School
        </p>
      </div>
      <hr />
      {!viewMode && (
        <>
          <div className="kid_score">
            {readingLevel && (
              <div className="kid_score_each">
                <h5>{t("progress_report_student_label_reading_level")}</h5>
                <p>{readingLevel}</p>
              </div>
            )}
            <div className="kid_score_each">
              <h5>{t("progress_report_student_label_vocabulary_result")}</h5>
              <p>{vocabulary || "-"}</p>
            </div>
            <div className="kid_score_each">
              <h5>{t("progress_report_student_label_fluency_rate")}</h5>
              <p>{fluencyRate || "-"}</p>
            </div>
            <div className="kid_score_each">
              <h5>{t("progress_report_student_label_pronunciation_rate")}</h5>
              <p>{pronunciationRate || "-"}</p>
            </div>
          </div>
          <div className="button text-center">
            <ButtonPrimary
              role="link"
              to={`/progress-report-students/${id}`}
              title={t("progress_report_student_button_label_check_details")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default StudentClassCard;
