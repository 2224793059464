import { take, put } from "redux-saga/effects";

// services
import { getCampusStudents } from "../../../services/http/schools";
import { GET_SCHOOL_STUDENTS, getSchoolStudentsActions } from "../../actions/schools";

// types
import { GetCampusUsersResponseTypes } from "../../actions/schools/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetSchoolStudentsRequest() {
  while (true) {
    const { payload }: IActionType<{ campusId: number | undefined }> = yield take(GET_SCHOOL_STUDENTS.REQUEST);

    try {
      const result: GetCampusUsersResponseTypes = yield call(getCampusStudents, payload);
      yield put(getSchoolStudentsActions.success(result));
    } catch (e) {
      yield put(getSchoolStudentsActions.error(e as any));
    }
  }
}

export default watchGetSchoolStudentsRequest;
