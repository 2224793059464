import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Field, Formik, Form, ErrorMessage } from "formik";
import ReactDatePicker from "react-datepicker";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// components
import { Loader } from "src/components";

// styles
import "react-datepicker/dist/react-datepicker.css";

// assets
import calendarIcon from "src/assets/images/calendar-icon.svg";

// utils
import {
  createFirstSectionAssignmentActions,
  updateFirstSectionAssignmentActions
} from "src/redux/actions/assignments";

// types
import { AssignmentType, CreateFirstSectionAssignmentRequestTypes } from "src/redux/actions/assignments/types";
import { EDIT_ASSIGNMENT_QUICK_MODE } from "../../../constants";

interface IProps {
  editMode?: string;
  assignment: AssignmentType | undefined;
}

export const FirstStepSection: FC<IProps> = ({ editMode, assignment }) => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();

  const navigate = useNavigate();

  const handleFirstSectionSubmit = useCallback(
    (data: CreateFirstSectionAssignmentRequestTypes) => {
      setIsLoading(false);
      setIsFetching(true);
      if (id) {
        dispatch(
          updateFirstSectionAssignmentActions.request({
            ...data,
            id: Number(id),
            onSuccess: () => {
              setIsFetching(false);
            }
          })
        );

        if (editMode === EDIT_ASSIGNMENT_QUICK_MODE) {
          navigate("/assignments");
        }
      } else {
        dispatch(
          createFirstSectionAssignmentActions.request({
            ...data,
            onSuccess: (id) => {
              setIsFetching(false);
              navigate(`${id}`);
            }
          })
        );
      }
    },
    [dispatch]
  );

  const firstSectionInitialValues = {
    assignmentName: assignment?.assignmentName || "",
    startDate: assignment?.startDate ? new Date(assignment.startDate) : new Date(),
    dueDate: assignment?.dueDate ? new Date(assignment.dueDate) : new Date(),
    description: assignment?.description || "",
    reward: assignment?.reward || undefined
  };

  const firstStepValidationSchema = Yup.object().shape({
    assignmentName: Yup.string().required(`${t("assignments_modal_select_label_assignment_name")} ${t("is_required")}`),
    startDate: Yup.date().required(`${t("assignments_modal_calendar_label_start_date")} ${t("is_required")}`),
    dueDate: Yup.date().required(`${t("assignments_modal_calendar_label_due_date")} ${t("is_required")}`),
    description: Yup.string().required(
      `${t("assignments_modal_select_label_assignment_description")} ${t("is_required")}`
    ),
    reward: Yup.string().required(`${t("assignments_modal_select_option_reward")} ${t("is_required")}`)
  });

  return (
    <div className="ds-card sec_body">
      <Formik
        validationSchema={firstStepValidationSchema}
        initialValues={firstSectionInitialValues}
        onSubmit={handleFirstSectionSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <>
              {isLoading && (
                <div className="loader-wrapper">
                  <Loader />
                </div>
              )}
              <div className="student-edit-form m-0">
                <div className="mt-5">
                  {editMode === EDIT_ASSIGNMENT_QUICK_MODE ? (
                    <h1>{t("assignment_edit_title")}</h1>
                  ) : (
                    <h1>1. {t("assignment_create_first_step_title")}</h1>
                  )}
                  <Form>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("assignments_modal_select_label_assignment_name")}</label>
                      <Field
                        type="text"
                        name="assignmentName"
                        className="form-control"
                        placeholder="Enter Assignment Name"
                      />
                      {errors.assignmentName && (
                        <ErrorMessage
                          name="assignmentName"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text">{t("start_date")}</label>
                      <div className="relative">
                        <ReactDatePicker
                          className="form-control"
                          selected={values.startDate}
                          name="startDate"
                          onChange={(date) => {
                            setFieldValue("startDate", date);
                          }}
                        />
                        <span className="down-img">
                          <img src={calendarIcon} alt="icon" />
                        </span>
                      </div>
                      {errors.startDate && (
                        <ErrorMessage
                          name="startDate"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text">{t("assignments_modal_calendar_label_due_date")}</label>
                      <div className="relative">
                        <ReactDatePicker
                          className="form-control"
                          selected={values.dueDate}
                          name="dueDate"
                          placeholderText="Select due date"
                          minDate={values.startDate}
                          onChange={(date) => {
                            setFieldValue("dueDate", date);
                          }}
                        />
                        <span className="down-img">
                          <img src={calendarIcon} alt="icon" />
                        </span>
                      </div>
                      {errors.dueDate && (
                        <ErrorMessage
                          name="dueDate"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("description")}</label>
                      <Field as="textarea" name="description" className="form-control" placeholder="Write here..." />
                      {errors.description && (
                        <ErrorMessage
                          name="description"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("reward")}</label>
                      <Field
                        type="number"
                        name="reward"
                        className="form-control"
                        placeholder="Enter number of coins for completing this assignment"
                      />
                      {errors.reward && (
                        <ErrorMessage
                          name="reward"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    {isFetching ? (
                      <div className="loaderWrapper text-center">
                        <Loader />
                      </div>
                    ) : (
                      <div className="row">
                        <button type="submit" className="btn custom_primary_btn">
                          {t("Save")}
                        </button>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
