import React from "react";

// styles
import { StyledLoader } from "./LoaderLocal.styles";

const LoaderLocal = ({
  color = "#32a3dd",
  forBtn = false,
  alignLeft = false
}: {
  color?: string;
  forBtn?: boolean;
  alignLeft?: boolean;
}) => {
  return (
    <StyledLoader color={color} className={`${forBtn && "forBtn"} ${alignLeft && "alignLeft"}`}>
      <div className="loader">
        <div className="loader__bounce first"></div>
        <div className="loader__bounce second"></div>
        <div className="loader__bounce third"></div>
      </div>
    </StyledLoader>
  );
};

export default LoaderLocal;
