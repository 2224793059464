import http from "../http";

export const login = ({ username, password }: { username: string; password: string }) => {
  return http("token", {
    body: `username=${username}&password=${password}&grant_type=password`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    method: "POST"
  });
};

export const updateRefreshToken = ({ refreshToken }: { refreshToken: string | null }) => {
  return http("token", {
    body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    method: "POST"
  });
};

export const getUserInfo = ({ userId, refresh = true }: { userId: number; refresh?: boolean }) => {
  return http(`api/users/${userId}`, {
    method: "GET",
    params: { refresh }
  });
};

export const getNonceToken = () => {
  return http("api/users/getNonce", {
    method: "GET"
  });
};
