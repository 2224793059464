import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledAddChildren } from "./AddUsersPopup.styles";
import Modal from "../../../../components/Modal/Modal";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import Papa, { ParseResult } from "papaparse";
import { addUsers } from "../../../../services/http/schools";
import LoaderLocal from "../../../../components/LoaderLocal";
import { IMPORT_STUDENTS_CSV_EXAMPLE_LINK, IMPORT_TEACHERS_CSV_EXAMPLE_LINK } from "../../../../constants";

interface IParsedCsvItem {
  className: string;
  firstName: string;
  gradeLevel: string;
  lastName: string;
  password: string;
  readingLevel: string;
  username: string;

  [key: string]: string;
}

interface IProps {
  isShow: boolean;
  handleToggle: () => void;
  handleUsersAdded: () => void;
  isTeachers?: boolean;
  campusId: number;
}

const AddUsersPopup: React.FC<IProps> = ({ isShow, handleToggle, handleUsersAdded, isTeachers = false, campusId }) => {
  const [t] = useTranslation();
  const fileTypes = ["CSV"];
  const [file, setFile] = useState<File | null>(null);
  const [parsedData, setParsedData] = useState<IParsedCsvItem[]>([]);
  const [errorFile, setErrorFile] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  function validateCsvData(dataArray: IParsedCsvItem[]) {
    const validTeachersKeys = ["classes", "firstName", "lastName", "password", "username"];

    const validStudentsKeys = [
      "className",
      "firstName",
      "gradeLevel",
      "lastName",
      "password",
      "readingLevel",
      "username"
    ];

    for (const obj of dataArray) {
      if (typeof obj !== "object") {
        return false;
      }

      for (const key of isTeachers ? validTeachersKeys : validStudentsKeys) {
        if (!Object.prototype.hasOwnProperty.call(obj, key) || typeof obj[key] !== "string" || obj[key].trim() === "") {
          return false;
        }
      }
    }

    return true;
  }

  const handleChangeFile = (file: File | null) => {
    setFile(file);
  };

  const handleSuccessSubmit = () => {
    handleUsersAdded();
    handleToggle();
  };

  useEffect(() => {
    !isShow && setFile(null);
  }, [isShow]);

  useEffect(() => {
    if (file) {
      setErrorFile("");
      Papa.parse<IParsedCsvItem[]>(file, {
        complete: (result: ParseResult<IParsedCsvItem> | any) => {
          if (result.data) {
            if (validateCsvData(result.data)) {
              setParsedData(result.data);
            } else {
              handleDropzoneError(`${t("children_add_modal_invalid_file_data")}`);
            }
          }
        },
        header: true,
        skipEmptyLines: true
      });
    }
  }, [file]);

  const handleDropzoneError = (errorMessage: string) => {
    setErrorFile(errorMessage);
    setFile(null);

    setTimeout(() => {
      setErrorFile("");
    }, 3000);
  };

  const onSubmitForm = async () => {
    if (campusId && parsedData) {
      setIsFetching(true);
      setErrorSubmit("");

      const payload = {
        users: parsedData.map((user) => {
          const userObj: any = {
            ...user,
            role: isTeachers ? "teacher" : "student",
            channel: "LMS"
          };

          if (isTeachers) {
            userObj.classes = user.classes.split(",");
          }

          return userObj;
        })
      };

      try {
        //request
        const submitResponse = (await addUsers(`${campusId}`, payload)) as { usersCreated: number; error: number };

        if (submitResponse?.usersCreated) {
          // Success
          handleSuccessSubmit();
        }
      } catch (e: any) {
        setErrorSubmit(`${e.message || t("children_add_modal_submit_error")}`);
      } finally {
        setIsFetching(false);
      }
    }
  };
  return (
    <StyledAddChildren>
      <Modal size="md" isShow={isShow} title={t("children_add_modal_title")} handleClose={handleToggle}>
        <div className="fileWrapper">
          <p className="fiter_text">{t("children_add_modal_dropzone_title")}</p>
          <FileUploader
            classes="dropzone"
            label=""
            hoverTitle=" "
            handleChange={handleChangeFile}
            onTypeError={() => handleDropzoneError(`${t("children_add_modal_file_type_error")}`)}
            name="file"
            types={fileTypes}
            dropMessageStyle={{
              backgroundColor: "#AED5F1",
              border: "none",
              borderRadius: "16px"
            }}
          >
            {!errorFile && file && <p className="fileName">{file.name}</p>}

            {}

            {!errorFile && !file && (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.8711 1.69141V11.6914M11.8711 21.6914V11.6914M11.8711 11.6914H21.8711M11.8711 11.6914H1.87109"
                  stroke="#01324B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}

            {errorFile && <p className="error">{errorFile}</p>}
          </FileUploader>

          <p className="downloadDescription">
            {`${t("children_add_modal_download_description")} `}
            <Link to={isTeachers ? IMPORT_TEACHERS_CSV_EXAMPLE_LINK : IMPORT_STUDENTS_CSV_EXAMPLE_LINK}>
              {t("click_here")}
            </Link>
          </p>

          {errorSubmit && <div className="formSubmitError">{errorSubmit}</div>}
        </div>

        <div className="actions">
          <div className="buttons">
            <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleToggle}>
              {t("assignments_modal_button_label_cancel")}
            </button>
            <button
              type="submit"
              className="btn-submit"
              disabled={isFetching || !!errorFile || !parsedData?.length}
              onClick={onSubmitForm}
              data-bs-dismiss="modal"
            >
              {isFetching ? <LoaderLocal forBtn={true} /> : <>{t("assignments_modal_button_label_submit")}</>}
            </button>
          </div>
        </div>
      </Modal>
    </StyledAddChildren>
  );
};

export default AddUsersPopup;
