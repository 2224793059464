import { useCallback, useEffect, useState } from "react";
import { getCountries } from "../services/http/schools";
import { GetCountriesResponseTypes } from "../redux/actions/schools/types";
import { RootReducer } from "../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolsListActions } from "../redux/actions/schools";
import useUserType from "./useUserType";
import { getGroupsActions } from "../redux/actions/group";

const useSchools = () => {
  const dispatch = useDispatch();

  const {
    user: { userData },
    schools: { activeSchool, schoolsList }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const { isAdmin } = useUserType();

  const [countryIso, setCountryIso] = useState("");

  const [activeSchoolId, setActiveSchoolId] = useState<number>();

  const getCountryIso = async (countryId: number) => {
    try {
      const countries = (await getCountries()) as GetCountriesResponseTypes;
      if (countries) {
        const currentCountry = countries.find((country) => country.countryId === countryId);
        if (currentCountry?.countryIsoCode) {
          setCountryIso(currentCountry?.countryIsoCode);
        }
      }
    } catch (e: any) {
      console.log("Get country iso error", e);
    }
  };

  const getSchoolsList = useCallback(
    (payload: { iso: string }) => dispatch(getSchoolsListActions.request(payload)),
    [dispatch]
  );

  const getGroups = useCallback(() => {
    let payload;

    if (isAdmin) {
      payload = { schoolId: activeSchoolId };
    } else {
      payload = {
        ownerUserId: userData?.id
      };
    }

    if (payload) {
      return dispatch(getGroupsActions.request(payload));
    }
  }, [dispatch, activeSchoolId]);

  //get country iso code
  useEffect(() => {
    if (isAdmin && userData?.countryId && !countryIso?.length) {
      getCountryIso(userData?.countryId);
    }
  }, [userData]);

  //get schools list
  useEffect(() => {
    countryIso && !schoolsList?.length && getSchoolsList({ iso: countryIso });
  }, [countryIso]);

  //update active school id
  useEffect(() => {
    if (activeSchool?.id) {
      setActiveSchoolId(activeSchool?.id);
    } else {
      setActiveSchoolId(userData?.schoolId);
    }
  }, [activeSchool, userData, isAdmin]);

  return {
    activeSchoolId,
    countryIso,
    getCountryIso,
    getSchoolsList,
    getGroups
  };
};

export default useSchools;
