import { MouseEvent, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ActiveElement
} from "chart.js";

// assets
import classIcon from "src/assets/images/class-icon.svg";

// types
import { ChartPropTypes } from "./ChartTypes";
import LoaderLocal from "../LoaderLocal";
import { RootReducer } from "../../redux/reducers";
import { useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const colors = ["#afd4f4", "#0e2d42", "#165a97", "#2b9dd7", "#afd8f6"];
const selectedColor = "#CB1E5C";

const ProgressChart = ({
  readingLevelsDataSet,
  numberOfStudentDataSet,
  handleBarClick,
  classTitle
}: ChartPropTypes) => {
  const [t] = useTranslation();

  const yTitle = t("progress_report_chart_title_number_of_students");
  const xTitle = t("progress_report_chart_title_reading_level");

  const ref = useRef<ChartJS<"bar", number[], string>>();

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      if (ref.current) {
        const elem = getElementAtEvent(ref.current, event);
        if (elem[0]) {
          const { index } = elem[0];
          setSelectedItems((prev) => {
            const newItems = [...prev];
            const itemIndex = newItems.findIndex((item) => item === index);
            if (itemIndex >= 0) {
              newItems.splice(itemIndex, 1);
            } else {
              newItems.push(index);
            }
            return newItems;
          });
          handleBarClick(readingLevelsDataSet?.[Number(index)]);
        }
      }
    },
    [setSelectedItems, handleBarClick, readingLevelsDataSet, getElementAtEvent]
  );

  const {
    reports: { isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const data = useMemo(
    () => ({
      labels: readingLevelsDataSet,
      datasets: [
        {
          label: "Number of students",
          data: numberOfStudentDataSet,
          backgroundColor: numberOfStudentDataSet?.map((_, index) =>
            selectedItems.includes(index) ? selectedColor : colors[index]
          ),
          borderColor: numberOfStudentDataSet?.map((_, index) => colors[index]),
          borderWidth: 1,
          borderRadius: 40
        }
      ]
    }),
    [readingLevelsDataSet, numberOfStudentDataSet, selectedItems]
  );

  const options = useMemo(
    () => ({
      onHover: (event: any, chartElement: ActiveElement[]) => {
        event.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          padding: 10,
          titleFont: {
            size: 14
          },
          bodyFont: {
            size: 14
          },
          titleColor: "#165B95",
          bodyColor: "#165B95",
          yAlign: "bottom" as const,
          displayColors: false,
          backgroundColor: "#F2F9FF",
          borderColor: "#165B95",
          borderWidth: 1,
          cornerRadius: 14
        },
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: xTitle,
            color: "#165a97",
            font: {
              size: 25
            }
          }
        },
        y: {
          beginAtZero: true,
          grace: "5%",
          title: {
            display: true,
            text: yTitle,
            color: "#165a97",
            font: {
              size: 25
            }
          }
        }
      },
      maxBarThickness: 60
    }),
    [yTitle, xTitle, readingLevelsDataSet]
  );

  return (
    <div className="ds-card chart-card">
      <div className="chart-thumb">
        <div className="chartBox" style={{ width: "100%" }}>
          <div className="grph-top">
            <p className="chart-title">
              <b>{t("reports_chart_title_class_reading_level_distribution")}</b>
            </p>
            <div className="grph-btn">
              {/*TODO: Add editing*/}
              {/*<button type="button" className="btn btn-labeled btn-default btn-edit">*/}
              {/*  {t("progress_report_chart_button_label_edit")}*/}
              {/*  <span className="btn-label">*/}
              {/*    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
              {/*      <path*/}
              {/*        d="M15.6423 2.01592L15.6423 2.01591C15.9033 1.75978 16.2571 1.61615 16.6256 1.61615C16.9942 1.61615 17.3479 1.75978 17.609 2.01591L17.609 2.01592L20.234 4.59195L20.1786 4.64845L20.234 4.59196C20.4951 4.84822 20.642 5.19606 20.642 5.55908C20.642 5.92209 20.4951 6.26993 20.234 6.5262L20.1786 6.4697L20.234 6.52621L8.42161 18.1182C8.26918 18.2683 8.08338 18.3814 7.87897 18.4487L7.87882 18.4488L3.94141 19.7367L15.6423 2.01592ZM15.6423 2.01592L3.8298 13.608L15.6423 2.01592ZM3.49262 14.1427L3.49263 14.1427C3.56122 13.941 3.67669 13.758 3.82975 13.608L3.49262 14.1427ZM3.49262 14.1427L2.18013 18.0067C2.09804 18.2479 2.08608 18.5069 2.14562 18.7545C2.20515 19.0021 2.33378 19.2283 2.51686 19.4078C2.69993 19.5873 2.9302 19.7131 3.18175 19.7711C3.43326 19.8291 3.69627 19.8173 3.94131 19.7368L3.49262 14.1427ZM4.88204 14.6185L16.6256 3.09397L19.1376 5.55908L7.39591 17.0817L3.62653 18.3147L4.88204 14.6185Z"*/}
              {/*        stroke="#165B95"*/}
              {/*        strokeWidth="1.158333"*/}
              {/*      />*/}
              {/*      <path*/}
              {/*        d="M16.7612 8.64743L16.7843 8.6701H16.8166H16.8179H16.8503L16.8734 8.6474L17.8014 7.73548L17.8589 7.67898L17.8013 7.62251L14.5201 4.40248L14.4646 4.34807L14.4092 4.40248L13.4799 5.31439L13.4223 5.3709L13.4799 5.4274L16.7612 8.64743ZM4.8125 13.2079H4.73333V13.287V13.931C4.73333 14.1233 4.81118 14.3074 4.94926 14.4429C5.08729 14.5784 5.27418 14.6542 5.46875 14.6542H6.04583V15.219C6.04583 15.4113 6.12368 15.5954 6.26176 15.7309C6.39979 15.8664 6.58668 15.9422 6.78125 15.9422H7.35833V16.5071C7.35833 16.6993 7.43618 16.8834 7.57426 17.0189C7.71229 17.1544 7.89918 17.2302 8.09375 17.2302H8.75H8.82917V17.1511V15.219C8.82917 15.0268 8.75132 14.8427 8.61324 14.7072C8.47521 14.5717 8.28832 14.4959 8.09375 14.4959H7.51667V13.931C7.51667 13.7387 7.43882 13.5546 7.30074 13.4191C7.16271 13.2837 6.97582 13.2079 6.78125 13.2079H4.8125Z"*/}
              {/*        stroke="#165B95"*/}
              {/*        strokeWidth="1.158333"*/}
              {/*      />*/}
              {/*      <rect x="1.75" y="21.0156" width="19.25" height="1.71735" rx="0.791667" fill="#165B95" />*/}
              {/*    </svg>*/}
              {/*  </span>*/}
              {/*</button>*/}
              {!isLoading ? (
                <>
                  {classTitle && (
                    <button type="button" className="btn-class">
                      {classTitle}
                      <span className="btn-label">
                        <img src={classIcon} alt="icon" />
                      </span>
                    </button>
                  )}
                </>
              ) : (
                <LoaderLocal />
              )}
            </div>
          </div>
          <Bar options={options} data={data} ref={ref} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
