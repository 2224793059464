const CheckboxErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        stroke="url(#paint0_linear_613_71909)"
        strokeWidth="2"
        d="M4.04 5.02c1.622-1.975 14.295-1.138 16.188 0 1.894 1.137 1.786 14.613-.513 15.84-2.299 1.228-11.97 3.121-14.933 1.05C1.453 19.582 2.416 6.994 4.04 5.02z"
      ></path>
      <path
        fill="#CB1E5C"
        d="M16.902 15.322c-.045.133-.174.338-.386.616-.212.255-.43.495-.652.717-.457.457-.873.731-1.25.822-.386.08-.821-.12-1.303-.602l-1.807-1.807-2.522 2.522a2.498 2.498 0 01-.677-.276 3.783 3.783 0 01-.659-.525c-.394-.394-.59-.768-.587-1.122-.008-.364.138-.697.439-.997l1.804-1.804-2.76-2.76a5.58 5.58 0 01.402-.6c.19-.278.396-.528.619-.75.445-.446.862-.709 1.249-.79.398-.091.844.11 1.337.603l1.725 1.725 2.472-2.472c.187.076.407.184.66.326.253.12.483.283.692.491.383.384.579.757.587 1.122-.003.353-.15.675-.439.964l-1.77 1.77 2.826 2.827z"
      ></path>
      <path
        fill="url(#paint1_linear_613_71909)"
        fillOpacity="0.3"
        d="M16.902 15.322c-.045.133-.174.338-.386.616-.212.255-.43.495-.652.717-.457.457-.873.731-1.25.822-.386.08-.821-.12-1.303-.602l-1.807-1.807-2.522 2.522a2.498 2.498 0 01-.677-.276 3.783 3.783 0 01-.659-.525c-.394-.394-.59-.768-.587-1.122-.008-.364.138-.697.439-.997l1.804-1.804-2.76-2.76a5.58 5.58 0 01.402-.6c.19-.278.396-.528.619-.75.445-.446.862-.709 1.249-.79.398-.091.844.11 1.337.603l1.725 1.725 2.472-2.472c.187.076.407.184.66.326.253.12.483.283.692.491.383.384.579.757.587 1.122-.003.353-.15.675-.439.964l-1.77 1.77 2.826 2.827z"
        style={{ mixBlendMode: "soft-light" }}
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_613_71909"
          x1="19.623"
          x2="0.633"
          y1="7.098"
          y2="11.681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7256D"></stop>
          <stop offset="1" stopColor="#C40E4A"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_613_71909"
          x1="5.566"
          x2="1.566"
          y1="3.544"
          y2="17.312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckboxErrorIcon;
