import styled from "styled-components";

export const StyledSchoolInfo = styled.div`
  .schoolHead {
    padding: 0;

    &--main {
      display: grid;
      grid-template-columns: 440px 1fr;
      grid-gap: 34px;
      padding: 14px 16px;

      .photoWrapper {
        width: 100%;
        height: 400px;
        border-radius: 8px;
        overflow: hidden;

        &.noPhoto {
          border: 1px solid rgb(56, 166, 222);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            object-fit: none;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .descriptionWrapper {
        padding-top: 20px;

        .sectionHeader {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }

        .location {
          display: flex;
          align-items: center;
          color: #3f3f46;
          font-size: 14px;

          svg {
            margin-right: 10px;
          }
        }

        .schoolDescription {
          margin-top: 22px;
          color: #52525b;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    &--footer {
      width: 100%;
      border-top: 1px solid #aed5f1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;

      .campusActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
      }
    }
  }

  .bullets {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-top: 25px;

    .item {
      display: flex;
      align-items: center;
      gap: 18px;

      img {
      }

      .itemWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--number {
          font-size: 32px;
          line-height: 25px;
          font-weight: 700;
          color: #01324b;
          font-family: "Baloo 2", cursive !important;
        }

        &--description {
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          color: #01324b;
        }
      }
    }
  }

  .nav-tabs {
    border: none;
  }
`;
