import React, { useCallback, useEffect } from "react";
import NoResultsSection from "../../../partials/NoResultsSection/NoResultsSection";
import { useTranslation } from "react-i18next";
import { Search, UserCard } from "../../../../../components";
import { SytledCampusTeachers } from "./CampusTeachersTab.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../../../../redux/reducers";
import { getCampusTeachersActions, getSchoolTeachersActions } from "../../../../../redux/actions/schools";
import LoaderLocal from "../../../../../components/LoaderLocal";

//img
import TeachersNoResults from "src/assets/images/campusNoResults/teachers.png";

interface IProps {
  campusId: number;
  handleToggleAddUsersPopup: (isTeacher: boolean) => void;
  isSchool?: boolean;
}

const CampusTeachersTab: React.FC<IProps> = ({ campusId, handleToggleAddUsersPopup, isSchool = false }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    schools: { school }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const campus = school?.campuses?.find((campus) => campus.id === campusId);
  const teachers = campus?.teachers ?? school?.teachers;

  const getSchoolTeachers = useCallback(
    (payload: { campusId: number }) => dispatch(getSchoolTeachersActions.request(payload)),
    [dispatch]
  );

  const getCampusTeachers = useCallback(
    (payload: { campusId: number }) => dispatch(getCampusTeachersActions.request(payload)),
    [dispatch]
  );

  const loadUsers = () => {
    isSchool ? getSchoolTeachers({ campusId }) : getCampusTeachers({ campusId });
  };

  useEffect(() => {
    campusId && loadUsers();
  }, [campusId]);

  const handleAddTeachers = () => {
    handleToggleAddUsersPopup(true);
  };
  return (
    <SytledCampusTeachers>
      <Search
        search={""}
        handleSearch={() => {
          return;
        }}
        resetSearch={() => {
          return;
        }}
        showFilterButton={false}
      />
      <div className="grph-top">
        <p className="chart-title">
          <b>{t("school_campus_teachers_title")}</b>
        </p>
        <div className="grph-btn">
          <button type="button" className="btn btn-labeled btn-default btn-edit" onClick={handleAddTeachers}>
            {t("school_campus_teachers_add_btn")}
            <span className="btn-label">
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.2427 6.58987C12.2427 6.29771 12.1244 6.01752 11.9139 5.81093C11.7034 5.60434 11.4178 5.48828 11.1201 5.48828C10.8224 5.48828 10.5369 5.60434 10.3264 5.81093C10.1159 6.01752 9.99758 6.29771 9.99758 6.58987V11.3634H5.13328C4.83556 11.3634 4.55004 11.4795 4.33952 11.6861C4.12901 11.8927 4.01074 12.1729 4.01074 12.465C4.01074 12.7572 4.12901 13.0374 4.33952 13.244C4.55004 13.4505 4.83556 13.5666 5.13328 13.5666H9.99758V18.3402C9.99758 18.6323 10.1159 18.9125 10.3264 19.1191C10.5369 19.3257 10.8224 19.4418 11.1201 19.4418C11.4178 19.4418 11.7034 19.3257 11.9139 19.1191C12.1244 18.9125 12.2427 18.6323 12.2427 18.3402V13.5666H17.107C17.4047 13.5666 17.6902 13.4505 17.9007 13.244C18.1112 13.0374 18.2295 12.7572 18.2295 12.465C18.2295 12.1729 18.1112 11.8927 17.9007 11.6861C17.6902 11.4795 17.4047 11.3634 17.107 11.3634H12.2427V6.58987Z"
                  fill="#165B95"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>

      {school?.isFetchingUsers ? (
        <>
          <div className="d-flex justify-content-center mt-5">
            <LoaderLocal />
          </div>
        </>
      ) : (
        <>
          {teachers?.length ? (
            <div className="row">
              {teachers.map((teacher, i) => (
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                <div key={i + teacher.userId + teacher.name} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <UserCard
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    imageUrl={teacher.profileImageUrl || ""}
                    schoolId={campusId}
                    name={teacher.name}
                    id={teacher.userId}
                    progress={0}
                    showProgress={false}
                    hideDetailsButton={true}
                    role="teacher"
                    userLink="user"
                    loadUsers={loadUsers}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NoResultsSection title={t("school_campus_no_teachers_title")} image={TeachersNoResults} largeDesc={true}>
              {/* TODO: Display import teachers btn */}
              {/*<a className="btn custom_primary_btn" onClick={handleAddTeachers}>*/}
              {/*  {t("school_campus_import_teachers_btn")}*/}
              {/*</a>*/}
            </NoResultsSection>
          )}
        </>
      )}
    </SytledCampusTeachers>
  );
};

export default CampusTeachersTab;
