import { persistor } from "../../redux/store";

const { localStorage } = window;

export const setToken = (token: string): void => localStorage.setItem("accessToken", token);
export const getToken = (): string | null => localStorage.getItem("accessToken");

export const setRefreshToken = (token: string): void => localStorage.setItem("refreshToken", token);
export const getRefreshToken = (): string | null => localStorage.getItem("refreshToken");

export const setTokenExpDate = (date: number): void => localStorage.setItem("token_exp_date", String(date));
export const getTokenExpDate = (): string | null => localStorage.getItem("tokenExpDate");

export const getLocalStorageItem = <T>(name: string): T | null => {
  const item = localStorage.getItem(name);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

export const setLocalStorageItem = <T>(name: string, data: T): void => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const signOutUser = (): void => {
  localStorage.clear();

  setTimeout(() => {
    persistor.purge();
  }, 500);
};
