import { take, put } from "redux-saga/effects";

// services
import { assignStudentsToGroups } from "src/services/http/group";

// types
import { AssignStudentToGroupRequestTypes } from "src/redux/actions/group/types";

// actions
import { ASSIGN_STUDENTS_TO_GROUP, assignStudentsToGroupActions } from "src/redux/actions/group";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchAssignStudentToGroupRequest() {
  while (true) {
    const { payload }: IActionType<AssignStudentToGroupRequestTypes> = yield take(ASSIGN_STUDENTS_TO_GROUP.REQUEST);

    try {
      yield call(assignStudentsToGroups, payload);
      yield put(assignStudentsToGroupActions.success(payload));
    } catch (e) {
      yield put(assignStudentsToGroupActions.error(e as any));
    }
  }
}

export default watchAssignStudentToGroupRequest;
