//Checking a string for long words or shortening them
const getTruncatedWords = (text: string, characterLimit = 100, checkMode = false) => {
  const words = text.split(" ");

  if (checkMode) {
    let isLongWordExist = false;
    words.forEach(function (word) {
      if (word.length > characterLimit) {
        isLongWordExist = true;
      }
    });
    return isLongWordExist;
  } else {
    const shortenedWords = words.map(function (word) {
      if (word.length > characterLimit) {
        return word.substring(0, characterLimit) + "...";
      } else {
        return word;
      }
    });

    const newText = shortenedWords.join(" ");
    return newText;
  }
};

export default getTruncatedWords;
