import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRefreshToken, getToken, getTokenExpDate, setRefreshToken, signOutUser } from "src/services/localStorage";
import { updateRefreshToken } from "src/services/http/user";

// types
import { PrivateRouteProps } from "./PrivateRouteTypes";
import { RootReducer } from "src/redux/reducers";

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, acceptedUserTypes = [] }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const minute = 60000;

    const refreshUserToken = () => {
      if (getToken() && getRefreshToken()) {
        const expDate = getTokenExpDate();
        if (expDate) {
          const tokenDate = new Date(expDate);
          const currentDate = new Date();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dateDifferenceInMinutes = Math.round((tokenDate - currentDate) / 60000);

          if (dateDifferenceInMinutes < 60) {
            updateRefreshToken({ refreshToken: getRefreshToken() })
              .then((response: any) => {
                if (response.refresh_token) {
                  setRefreshToken(response.refresh_token);
                } else {
                  signOutUser();
                  navigate("/login");
                }
              })
              .catch(() => {
                signOutUser();
                navigate("/login");
              });
          }
        }
      }
    };

    setTimeout(() => {
      refreshUserToken();
    }, 1000);

    setInterval(refreshUserToken, minute * 5);
  }, []);

  const {
    auth: { authData },
    user: { userData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  if (!authData?.userId || !getToken()) {
    return <Navigate to="/login" />;
  }

  if (userData && acceptedUserTypes?.length && !acceptedUserTypes.includes(userData.userTypeId)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
