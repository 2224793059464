import {
  CREATE_ASSIGNMENT,
  GET_BOOK_GAMES,
  GET_STUDENT_ASSIGNMENTS,
  GET_QUIZ_DETAILS,
  GET_ASSIGNMENT_DETAILS,
  MARK_COMPLETE_ASSIGNMENT,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
  ADD_CONTENT_TO_ASSIGNMENT,
  UNASSIGN_CONTENT_FROM_ASSIGNMENT,
  UNASSIGN_AUDIENCE_FROM_ASSIGNMENT,
  ADD_AUDIENCE_TO_ASSIGNMENT,
  UPDATE_FIRST_STEP_ASSIGNMENT,
  RESET_STUDENT_ASSIGNMENTS_IS_LOADING,
  GET_ASSIGNMENT_STATUS,
  GET_ASSIGNMENT_USER_ACTIVITY,
  RESET_STUDENT_ASSIGNMENTS
} from "src/redux/actions/assignments";
import {
  GetAssignmentStatusResponseTypes,
  GetBookGamesResponseTypes,
  GetStudentAssignmentResponseTypes,
  GetQuizDetailsResponseTypes,
  GetAssignmentDetailsResponseTypes,
  AssignmentType,
  GetAssignmentResponseTypes,
  GetAssignmentUserActivityResponseTypes
} from "src/redux/actions/assignments/types";
import { RESET_STATE } from "../actions/auth";

export const initialState: AssignmentsState = {
  isLoading: false,
  isLoadingChanges: false,
  bookGames: undefined,
  studentAssignments: undefined,
  assignments: undefined,
  assignmentStatus: undefined,
  assignmentUserActivity: undefined,
  quizDetails: undefined,
  assignmentDetails: undefined,
  error: undefined
};

export interface AssignmentsState {
  isLoading: boolean;
  isLoadingChanges: boolean;
  bookGames: GetBookGamesResponseTypes | undefined;
  assignments: AssignmentType[] | undefined;
  studentAssignments: GetStudentAssignmentResponseTypes | undefined;
  assignmentStatus: GetAssignmentStatusResponseTypes | undefined;
  assignmentUserActivity: GetAssignmentUserActivityResponseTypes | undefined;
  quizDetails: GetQuizDetailsResponseTypes | undefined;
  assignmentDetails: GetAssignmentDetailsResponseTypes | undefined;
  error: any;
}

const assignments = (
  state = initialState,
  action: IActionType<
    | GetBookGamesResponseTypes
    | GetStudentAssignmentResponseTypes
    | GetQuizDetailsResponseTypes
    | GetAssignmentStatusResponseTypes
    | GetAssignmentUserActivityResponseTypes
    | GetAssignmentDetailsResponseTypes
    | GetAssignmentResponseTypes
    | AssignmentType
  >
): AssignmentsState => {
  switch (action.type) {
    case GET_BOOK_GAMES.REQUEST:
    case GET_ASSIGNMENTS.REQUEST:
    case GET_ASSIGNMENT_STATUS.REQUEST:
    case GET_ASSIGNMENT_USER_ACTIVITY.REQUEST:
    case GET_QUIZ_DETAILS.REQUEST:
    case GET_ASSIGNMENT_DETAILS.REQUEST:
    case ADD_CONTENT_TO_ASSIGNMENT.REQUEST:
    case UNASSIGN_CONTENT_FROM_ASSIGNMENT.REQUEST:
    case UNASSIGN_AUDIENCE_FROM_ASSIGNMENT.REQUEST:
    case ADD_AUDIENCE_TO_ASSIGNMENT.REQUEST:
    case UPDATE_FIRST_STEP_ASSIGNMENT.REQUEST:
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case MARK_COMPLETE_ASSIGNMENT.REQUEST:
    case CREATE_ASSIGNMENT.REQUEST:
      return {
        ...state,
        isLoadingChanges: true,
        error: undefined
      };

    case CREATE_ASSIGNMENT.SUCCESS:
    case MARK_COMPLETE_ASSIGNMENT.SUCCESS:
    case CREATE_ASSIGNMENT.ERROR:
    case MARK_COMPLETE_ASSIGNMENT.ERROR:
      return {
        ...state,
        isLoadingChanges: false,
        error: action.payload || undefined
      };

    case GET_BOOK_GAMES.SUCCESS:
      return {
        ...state,
        bookGames: action.payload as GetBookGamesResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_STUDENT_ASSIGNMENTS.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RESET_STUDENT_ASSIGNMENTS_IS_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case GET_STUDENT_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        studentAssignments: action.payload as GetStudentAssignmentResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        assignments: action.payload as GetAssignmentResponseTypes,
        isLoading: false,
        error: undefined
      };

    case UPDATE_FIRST_STEP_ASSIGNMENT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case GET_ASSIGNMENT_BY_ID.REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case GET_ASSIGNMENT_BY_ID.SUCCESS: {
      const assignmentIndex = state.assignments
        ? /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          state.assignments.findIndex(({ assignmentId }) => assignmentId === action.payload.assignmentId)
        : -1;

      const assignments = state.assignments ? [...state.assignments] : [];

      if (assignmentIndex === -1) {
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        assignments.push(action.payload);
      } else {
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        assignments.splice(assignmentIndex, 1, action.payload);
      }

      return {
        ...state,
        assignments: assignments as GetAssignmentResponseTypes,
        isLoading: false,
        error: undefined
      };
    }
    case UNASSIGN_AUDIENCE_FROM_ASSIGNMENT.SUCCESS: {
      const assignmentIndex = state.assignments
        ? /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          state.assignments.findIndex(({ assignmentId }) => assignmentId === action.payload.id)
        : -1;

      if (state.assignments) {
        const assignments = [...state.assignments];

        assignments[assignmentIndex].assignees = assignments[assignmentIndex].assignees.filter(
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          ({ assigneeId }) => assigneeId !== action.payload.assignees[0].assigneeId
        );
        return {
          ...state,
          assignments: assignments as GetAssignmentResponseTypes,
          isLoading: false,
          error: undefined
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: undefined
        };
      }
    }

    case UNASSIGN_CONTENT_FROM_ASSIGNMENT.SUCCESS: {
      const assignmentIndex = state.assignments
        ? /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          state.assignments.findIndex(({ assignmentId }) => assignmentId === action.payload.id)
        : -1;

      if (state.assignments) {
        const assignments = [...state.assignments];

        assignments[assignmentIndex].content.content = assignments[assignmentIndex].content.content.filter(
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          ({ bookId }) => bookId !== action.payload.challengeContentId
        );

        return {
          ...state,
          assignments: assignments as GetAssignmentResponseTypes,
          isLoading: false,
          error: undefined
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: undefined
        };
      }
    }

    case ADD_CONTENT_TO_ASSIGNMENT.SUCCESS: {
      const assignmentIndex = state.assignments
        ? /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          state.assignments.findIndex(({ assignmentId }) => assignmentId === action.payload.id)
        : -1;

      if (state.assignments) {
        const assignments = [...state.assignments];

        const content = [...assignments[assignmentIndex].content.content];

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        action.payload.contentTypes.forEach((contentType) =>
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          action.payload.content.forEach(({ bookId, thumbnailUrl }) =>
            content.push({ bookId, thumbnailUrl, contentType: contentType })
          )
        );

        assignments[assignmentIndex].content = {
          content,
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          contentTypes: action.payload.contentTypes.map(Number)
        };
        return {
          ...state,
          assignments: assignments as GetAssignmentResponseTypes,
          isLoading: false,
          error: undefined
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: undefined
        };
      }
    }

    case ADD_AUDIENCE_TO_ASSIGNMENT.SUCCESS: {
      const assignmentIndex = state.assignments
        ? /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          state.assignments.findIndex(({ assignmentId }) => assignmentId === action.payload.assignmentId)
        : -1;

      if (state.assignments) {
        const assignments = [...state.assignments];
        //  eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //  @ts-ignore
        assignments[assignmentIndex].assignees.push(...action.payload.assignees);

        return {
          ...state,
          assignments: assignments as GetAssignmentResponseTypes,
          isLoading: false,
          error: undefined
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: undefined
        };
      }
    }

    case GET_QUIZ_DETAILS.SUCCESS:
      return {
        ...state,
        quizDetails: action.payload as GetQuizDetailsResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_ASSIGNMENT_DETAILS.SUCCESS:
      return {
        ...state,
        assignmentDetails: action.payload as GetAssignmentDetailsResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_ASSIGNMENT_STATUS.SUCCESS:
      return {
        ...state,
        assignmentStatus: action.payload as GetAssignmentStatusResponseTypes,
        assignmentUserActivity: undefined,
        isLoading: false,
        error: undefined
      };

    case GET_ASSIGNMENT_USER_ACTIVITY.SUCCESS:
      return {
        ...state,
        assignmentUserActivity: action.payload as GetAssignmentUserActivityResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_BOOK_GAMES.ERROR:
    case GET_STUDENT_ASSIGNMENTS.ERROR:
    case GET_QUIZ_DETAILS.ERROR:
    case GET_ASSIGNMENT_DETAILS.ERROR:
    case GET_ASSIGNMENT_USER_ACTIVITY.ERROR:
    case GET_ASSIGNMENTS.ERROR:
    case GET_ASSIGNMENT_STATUS.ERROR:
    case UPDATE_FIRST_STEP_ASSIGNMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case RESET_STUDENT_ASSIGNMENTS:
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default assignments;
