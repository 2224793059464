import React from "react";
import { useTranslation } from "react-i18next";

// assets
import searchIcon from "src/assets/fonts/5.svg";
import crossIcon from "src/assets/images/crose-icon.svg";

const Search = ({
  search,
  handleSearch,
  resetSearch,
  showFilterButton = true
}: {
  search: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetSearch: () => void;
  showFilterButton: boolean;
}) => {
  const [t] = useTranslation();

  const inputPlaceholder = t("library_input_search_label");

  return (
    <div className="library-media align-items-center">
      <div className="search-form onpage">
        <div className="form position-relative">
          <input type="text" placeholder={inputPlaceholder} onChange={handleSearch} value={search} />
          <span className="button">
            <img src={searchIcon} alt="Icon" className="img-fluid" />
          </span>
          {search.length > 2 && (
            <button type="button" className="btn-clear-search btn position-absolute p-0" onClick={resetSearch}>
              <img src={crossIcon} alt="icon" />
            </button>
          )}
        </div>
      </div>
      {showFilterButton && (
        <div className="title_libary title_libary-btn text-end">
          <div className="button m-0">
            <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.55469 16.041C6.55469 15.4887 7.0024 15.041 7.55469 15.041L17.5547 15.041C18.107 15.041 18.5547 15.4887 18.5547 16.041C18.5547 16.5933 18.107 17.041 17.5547 17.041L7.55469 17.041C7.0024 17.041 6.55469 16.5933 6.55469 16.041Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.27734 8.02051C2.27734 7.46822 2.72506 7.02051 3.27734 7.02051H21.2773C21.8296 7.02051 22.2773 7.46822 22.2773 8.02051C22.2773 8.57279 21.8296 9.02051 21.2773 9.02051H3.27734C2.72506 9.02051 2.27734 8.57279 2.27734 8.02051Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.55469 12.041C4.55469 11.4887 5.0024 11.041 5.55469 11.041L19.5547 11.041C20.107 11.041 20.5547 11.4887 20.5547 12.041C20.5547 12.5933 20.107 13.041 19.5547 13.041L5.55469 13.041C5.0024 13.041 4.55469 12.5933 4.55469 12.041Z"
                  fill="white"
                />
              </svg>
              <span>{t("library_filter_button_label")}</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
