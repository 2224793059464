import { useDispatch, useSelector } from "react-redux";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// components
import { SelectAudienceModal } from "./SelectAudienceModal/SelectAudienceModal";

// utils
import { RootReducer } from "src/redux/reducers";
import { getChildrenStatsActions } from "src/redux/actions/reports";

// types
import { AssignmentType } from "src/redux/actions/assignments/types";
import { GetGroupsResponseTypes } from "src/redux/actions/group/types";
import StudentClassCard from "src/modules/progressReportClass/partials/StudentClassCard";
import { ChildStatsTypes } from "src/redux/actions/reports/types";
import { unassignAudienceFromAssignmentActions } from "src/redux/actions/assignments";
import CrossIcon from "src/assets/images/crose-icon.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  assignment: AssignmentType | undefined;
  groups: GetGroupsResponseTypes["rows"] | undefined;
  handlePublish: () => void;
}

export const ThirdStepSection: FC<IProps> = ({ assignment, groups, handlePublish }) => {
  const dispatch = useDispatch();
  const [isShowAddGroupPopup, setIsShowAddGroupPopup] = useState(false);
  const [t] = useTranslation();
  const { id } = useParams();

  const {
    auth: {
      authData: { userId }
    },
    reports: { childrenStats }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getChildrenStats = useCallback(
    () => dispatch(getChildrenStatsActions.request({ userId: userId || "" })),
    [dispatch]
  );

  useEffect(() => {
    getChildrenStats();
  }, []);

  const handleUnassign = useCallback(
    (assigneeId: ChildStatsTypes["childId"]) => {
      if (id && assignment) {
        const assignee = assignment?.assignees.find((item) => item.assigneeId === assigneeId);
        if (assignee?.role) {
          dispatch(
            unassignAudienceFromAssignmentActions.request({
              id: Number(id),
              assignees: [{ assigneeId, role: assignee.role }]
            })
          );
        }
      }
    },
    [id, dispatch, assignment]
  );

  const handleToggleAddGroup = () => {
    setIsShowAddGroupPopup((prev) => !prev);
  };

  return (
    <div className="ds-card sec_body third-section">
      <div className="student-edit-form m-0">
        <div className="mt-5">
          <h1>3. {t("assignment_create_third_step_title")}</h1>
          <div className="form-group">
            <label className="label-text pt-2">{t("assignment_create_assignees_label")}</label>
            <div className="row">
              {assignment?.assignees?.map(({ role, assigneeId }) => {
                if (role == 2 && groups) {
                  const group = groups.find(({ id }) => id === assigneeId);
                  return (
                    group && (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 assignee-card-wrapper" key={group.id}>
                        <StudentClassCard
                          id={group.id}
                          name={`${group.name} (${group.usersCount} students)`}
                          viewMode
                        />
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Delete book"
                          onClick={() => handleUnassign(group.id)}
                        >
                          <img src={CrossIcon} alt="" />
                        </button>
                      </div>
                    )
                  );
                } else if (role == 1 && childrenStats) {
                  const student = childrenStats.flat().find(({ childId }) => childId === assigneeId);
                  return (
                    student && (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 assignee-card-wrapper" key={student.childId}>
                        <StudentClassCard
                          id={student.childId}
                          imageUrl={student.childProfileImageUrl}
                          name={student.childName}
                          viewMode
                        />
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Delete book"
                          onClick={() => handleUnassign(student.childId)}
                        >
                          <img src={CrossIcon} alt="" />
                        </button>
                      </div>
                    )
                  );
                }
              })}
            </div>
            <button
              className="btn btn-edit"
              data-bs-toggle="modal"
              data-bs-target="#selectAudienceModal"
              onClick={handleToggleAddGroup}
            >
              {t("assignment_create_add_audience")}
            </button>
          </div>
          <div className="row">
            <button
              className="btn custom_primary_btn"
              disabled={!assignment?.assignees?.length || !assignment?.content?.content.length}
              onClick={handlePublish}
            >
              {t("Publish")}
            </button>
          </div>
        </div>
      </div>
      {assignment && (
        <SelectAudienceModal
          childrens={childrenStats}
          groups={groups}
          assignment={assignment}
          isShow={isShowAddGroupPopup}
        />
      )}
    </div>
  );
};
