import { put } from "redux-saga/effects";
import { GET_ASSIGNMENTS, getAssignmentsActions } from "src/redux/actions/assignments";

// services
import { getAssignments } from "src/services/http/assignments";

// types
import { GetAssignmentResponseTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchAssignmentsRequest() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  //   const sortByPublishDateDescending = (data: any) => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     return data.sort((a, b) => {
  //       const dateA = new Date(a.publishDate);
  //       const dateB = new Date(b.publishDate);

  //       if (dateA > dateB) return -1;
  //       if (dateA < dateB) return 1;
  //       return 0;
  //     });
  //   };

  while (true) {
    const { payload } = yield Effects.take(GET_ASSIGNMENTS.REQUEST);
    try {
      //   const assignmentsList: any = [];
      const result: GetAssignmentResponseTypes = yield call(getAssignments, { state: payload.state });

      //   sortByPublishDateDescending(result);

      yield put(getAssignmentsActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getAssignmentsActions.error(e as any));
    }
  }
}

export default watchAssignmentsRequest;
