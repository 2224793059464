import styled from "styled-components";

export const StyledCampusAbout = styled.div`
  .campusPhoto {
    width: 100%;
    margin-top: 25px;

    &.noPhoto {
      border: 1px solid rgb(56, 166, 222);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      img {
        object-fit: none;
      }
    }

    img {
      width: 100%;
      max-height: 580px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .campusDescription {
    margin-top: 30px;

    p {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .campusContacts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    gap: 62px;
    margin-bottom: 20px;

    .item {
      display: flex;
      align-items: center;

      &Icon {
        margin-right: 12px;
      }

      &Wrapper {
        display: flex;
        flex-direction: column;

        .label {
          color: #18181b;
          font-size: 16px;
          font-weight: 500;
        }

        a {
          font-family: "Baloo 2", cursive;
          font-size: 25px;
          font-weight: 700;
          color: #000000;
          transition: color 0.3s;

          &:hover {
            color: #165b95;
          }
        }
      }
    }
  }
`;
