import {
  AssignStudentToGroupRequestTypes,
  CreateNewGroupRequestTypes,
  GetGroupsRequestTypes
} from "src/redux/actions/group/types";
import http from "../http";
import { getToken } from "../localStorage";

export const getGroups = (params: GetGroupsRequestTypes) => {
  return http("api/groups", {
    method: "GET",
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const createNewGroup = (payload: CreateNewGroupRequestTypes) => {
  return http("api/groups", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};

export const assignStudentsToGroups = ({ userIds, groupId }: AssignStudentToGroupRequestTypes) => {
  const userRoles = userIds.map((userId: number) => ({ userId: userId, roleId: 99 }));
  return http(`api/groups/${groupId}/AssignUser`, {
    method: "POST",
    body: JSON.stringify({ userRoles: userRoles }),
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
};
