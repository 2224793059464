import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { uploadSchoolPhoto } from "../../../../services/http/schools";
import { FileUploader } from "react-drag-drop-files";
import crossIcon from "../../../../assets/images/crose-icon.svg";
import AddPhotoPopup from "../AddPhotoPopup/AddPhotoPopup";
import LoaderLocal from "../../../../components/LoaderLocal";
import { createCampus } from "../../../../services/http/schools";

interface IProps {
  isShow: boolean;
  handleToggle: () => void;
  handleCampusCreated: () => void;
  id: number;
  countryId: number;
}

const AddCampusPopup: React.FC<IProps> = ({ isShow, handleToggle, handleCampusCreated, id, countryId }) => {
  const [t] = useTranslation();

  const [isResetForm, setIsResetForm] = useState(false);
  const [isShowAddPhotoPopup, setIsShowAddPhotoPopup] = useState(false);
  const fileTypes = ["JPG", "JPEG", "PNG"];
  const [campusPhotoFile, setCampusPhotoFile] = useState<File | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");

  const handleToggleAddPhotoPopup = () => {
    setIsShowAddPhotoPopup((prev) => !prev);
  };

  const handleChangeFile = (file: File | null) => {
    setCampusPhotoFile(file);
    handleToggleAddPhotoPopup();
  };

  const handleResetPhoto = () => {
    setCampusPhotoFile(null);
  };

  const handleFileTypeError = () => {
    setError(`${t("modal_file_type_error")}`);

    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const initialValues = {
    name: "",
    description: "",
    numberOfTeachers: "",
    numberOfStudents: "",
    externalId: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, `${t("campus_create_form_name_label")} ${t("input_must_contain_only_letters")}`)
      .required(`${t("campus_create_form_name_label")} ${t("is_required")}`),
    description: Yup.string().required(`${t("campus_create_form_description_label")} ${t("is_required")}`),
    numberOfTeachers: Yup.number()
      .typeError(`${t("campus_create_form_teacher_num_label")} ${t("input_must_be_a_number")}`)
      .required(`${t("campus_create_form_teacher_num_label")} ${t("is_required")}`),
    numberOfStudents: Yup.number()
      .typeError(`${t("campus_create_form_student_num_label")} ${t("input_must_be_a_number")}`)
      .required(`${t("campus_create_form_student_num_label")} ${t("is_required")}`),
    externalId: Yup.number()
      .typeError(`${t("campus_create_form_external_id_label")} ${t("input_must_be_a_number")}`)
      .required(`${t("campus_create_form_external_id_label")} ${t("is_required")}`)
  });

  const handleFormSuccess = (resetForm: () => void) => {
    resetForm();
    handleResetPhoto();
    handleToggle();
    handleCampusCreated();
    setIsResetForm(true);
  };

  const handleSubmitForm = async (fields: any, { resetForm }: any) => {
    setErrorSubmit("");
    setIsFetching(true);
    try {
      const campusId = await createCampus({ id, countryId, ...fields });

      if (campusId) {
        if (campusPhotoFile) {
          const uploadPhotoStatus = await uploadSchoolPhoto(campusPhotoFile, campusId);
          if (uploadPhotoStatus) {
            handleFormSuccess(resetForm);
          }
        } else {
          handleFormSuccess(resetForm);
        }
      }
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    isResetForm && setIsResetForm(false);
  }, [isResetForm]);

  return (
    <>
      <Modal isShow={isShow} title={"Campus"} handleClose={handleToggle}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
          enableReinitialize={isResetForm}
        >
          {({ errors }) => {
            return (
              <>
                <div className="schoolAddForm student-edit-form m-0">
                  <div className="addImgWrapper">
                    <FileUploader
                      classes={`addImageBtn ${campusPhotoFile && "notEmpty"}`}
                      label=""
                      hoverTitle=" "
                      handleChange={handleChangeFile}
                      onTypeError={handleFileTypeError}
                      name="file"
                      types={fileTypes}
                      dropMessageStyle={{
                        backgroundColor: "#AED5F1",
                        border: "none",
                        borderRadius: "16px"
                      }}
                    >
                      {!error && campusPhotoFile && (
                        <>
                          <img src={URL.createObjectURL(campusPhotoFile)} alt="Campus photo" />
                        </>
                      )}

                      {!error && !campusPhotoFile && (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 2V12M12 22V12M12 12H22M12 12H2"
                            stroke="#01324B"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}

                      {error && <p className="error m-0">{error}</p>}
                    </FileUploader>

                    {campusPhotoFile && (
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleResetPhoto}
                      >
                        <p>Remove photo</p>
                        <img src={crossIcon} alt="" />
                      </button>
                    )}

                    {!campusPhotoFile && <span className="addImageLabel">{t("campus_create_form_add_img_label")}</span>}
                  </div>
                  <Form>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("campus_create_form_name_label")}</label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder={t("campus_create_form_name_placeholder")}
                      />
                      {errors.name && (
                        <ErrorMessage
                          name="name"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("campus_create_form_external_id_label")}</label>
                      <Field
                        type="text"
                        name="externalId"
                        className="form-control"
                        placeholder={t("campus_create_form_external_id_placeholder")}
                      />
                      {errors.externalId && (
                        <ErrorMessage
                          name="externalId"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("campus_create_form_teacher_num_label")}</label>
                      <Field
                        type="text"
                        name="numberOfTeachers"
                        className="form-control"
                        placeholder={t("campus_create_form_teacher_num_placeholder")}
                      />
                      {errors.numberOfTeachers && (
                        <ErrorMessage
                          name="numberOfTeachers"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("campus_create_form_student_num_label")}</label>
                      <Field
                        type="text"
                        name="numberOfStudents"
                        className="form-control"
                        placeholder={t("campus_create_form_student_num_placeholder")}
                      />
                      {errors.numberOfStudents && (
                        <ErrorMessage
                          name="numberOfStudents"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("campus_create_form_description_label")}</label>
                      <Field
                        as="textarea"
                        type="text"
                        name="description"
                        className="form-control"
                        placeholder={t("campus_create_form_description_placeholder")}
                      />
                      {errors.description && (
                        <ErrorMessage
                          name="description"
                          render={(children) => (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                              {children}
                            </div>
                          )}
                        />
                      )}
                    </div>
                    {errorSubmit && <div className="formSubmitError">{errorSubmit}</div>}
                    <div className="submitRow">
                      <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleToggle}>
                        {t("assignments_modal_button_label_cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn-submit"
                        disabled={isFetching}
                        // onClick={handleSubmitForm}
                        data-bs-dismiss="modal"
                      >
                        {isFetching ? <LoaderLocal forBtn={true} /> : <>{t("assignments_modal_button_label_submit")}</>}
                      </button>
                    </div>
                  </Form>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal>

      {campusPhotoFile && (
        <AddPhotoPopup
          isShow={isShowAddPhotoPopup}
          handleToggle={handleToggleAddPhotoPopup}
          imgUrl={`${URL.createObjectURL(campusPhotoFile)}`}
          handleChangeFile={handleChangeFile}
        />
      )}
    </>
  );
};

export default AddCampusPopup;
