import { ASSIGN_STUDENTS_TO_GROUP, CREATE_NEW_GROUP, GET_GROUPS } from "../actions/group";
import {
  AssignStudentToGroupRequestTypes,
  CreateNewGroupResponseTypes,
  GetGroupsResponseTypes
} from "../actions/group/types";
import { RESET_STATE } from "../actions/auth";

export const initialState: GroupState = {
  isLoading: false,
  groups: undefined,
  createdGroupIds: [],
  error: undefined
};

export interface GroupState {
  isLoading: boolean;
  groups: GetGroupsResponseTypes | undefined;
  createdGroupIds: number[];
  error: any;
}

const group = (
  state = initialState,
  action: IActionType<CreateNewGroupResponseTypes | GetGroupsResponseTypes | AssignStudentToGroupRequestTypes>
): GroupState => {
  switch (action.type) {
    case CREATE_NEW_GROUP.REQUEST:
    case ASSIGN_STUDENTS_TO_GROUP.REQUEST:
    case GET_GROUPS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case CREATE_NEW_GROUP.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        createdGroupIds: [...state.createdGroupIds, action.payload],
        error: undefined
      };
    }

    case ASSIGN_STUDENTS_TO_GROUP.SUCCESS: {
      if (state?.groups?.rows) {
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        const groupIndex = state?.groups?.rows.findIndex(({ id }) => id === action.payload.groupId);
        const groups = { ...state?.groups };

        groups.rows[groupIndex].usersCount = String(Number(groups.rows[groupIndex].usersCount) + 1);
        return {
          ...state,
          isLoading: false,
          groups,
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          createdGroupIds: [...state.createdGroupIds, 1],
          error: undefined
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: undefined
        };
      }
    }
    case GET_GROUPS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        groups: action.payload as GetGroupsResponseTypes,
        error: undefined
      };
    }
    case CREATE_NEW_GROUP.ERROR:
    case ASSIGN_STUDENTS_TO_GROUP.ERROR:
    case GET_GROUPS.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default group;
