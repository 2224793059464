import React, { useState } from "react";
import UserPhoto from "../../UserPhoto/UserPhoto";
import { useTranslation } from "react-i18next";
import LoaderLocal from "../../../../LoaderLocal";
import { deleteUserAccount } from "../../../../../services/http/users";
import { getTruncatedWords } from "../../../../../helpers";

interface Props {
  userId: number;
  imageUrl?: string;
  name: string;
  handleToggle: () => void;
  handleToggleModal: () => void;
  loadUsers: () => void;
}

const DeleteUserForm: React.FC<Props> = ({ userId, imageUrl, name, handleToggle, handleToggleModal, loadUsers }) => {
  const [t] = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");

  const handleDeleteUser = async () => {
    setIsFetching(true);
    setErrorSubmit("");

    try {
      const deleteRes = await deleteUserAccount({ userId });

      if (deleteRes) {
        loadUsers();
        handleToggleModal();
      } else {
        setErrorSubmit(`${t("edit_user_delete_error", { name })}`);
      }
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetching(false);
    }
  };
  return (
    <>
      <UserPhoto imageUrl={imageUrl} />
      <h3 className="mt-3 color_red_1">{`${t("edit_user_delete_title", { name: getTruncatedWords(name, 16) })}`}</h3>
      {errorSubmit && <div className="formSubmitError">{errorSubmit}</div>}
      <div className="submitRow mt-36">
        <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleToggle}>
          {t("Cancel")}
        </button>
        <button type="submit" className="btn-submit" disabled={isFetching} onClick={handleDeleteUser}>
          {isFetching ? <LoaderLocal forBtn={true} /> : <>{t("confirm_delete_button")}</>}
        </button>
      </div>
    </>
  );
};

export default DeleteUserForm;
