import {
  GetSchoolsListResponseTypes,
  GetSchoolsItemResponseTypes,
  GetCampusUsersResponseTypes,
  CampusUserType,
  SchoolSearchOptionTypes
} from "src/redux/actions/schools/types";
import {
  GET_SCHOOLS_LIST,
  GET_SCHOOLS_ITEM,
  GET_CAMPUS_ITEM,
  GET_CAMPUS_STUDENTS,
  GET_CAMPUS_TEACHERS,
  GET_SCHOOL_STUDENTS,
  GET_SCHOOL_TEACHERS,
  SET_ACTIVE_SCHOOL
} from "../actions/schools";
import { RESET_STATE } from "../actions/auth";

export const initialState: SchoolsState = {
  isLoading: true,
  activeSchool: undefined,
  schoolsList: undefined,
  school: undefined,
  campus: undefined,
  error: undefined
};

export interface SchoolsState {
  isLoading: boolean;
  activeSchool?: SchoolSearchOptionTypes;
  schoolsList: GetSchoolsListResponseTypes | undefined;
  school: GetSchoolsItemResponseTypes | undefined;
  campus: GetSchoolsItemResponseTypes | undefined;
  error: any;
}

const schools = (
  state = initialState,
  action: IActionType<
    GetSchoolsListResponseTypes | GetSchoolsItemResponseTypes | GetCampusUsersResponseTypes | SchoolSearchOptionTypes
  >
): SchoolsState => {
  switch (action.type) {
    case GET_SCHOOLS_LIST.REQUEST:
    case GET_SCHOOLS_ITEM.REQUEST:
    case GET_CAMPUS_ITEM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case GET_SCHOOLS_LIST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        schoolsList: action.payload as GetSchoolsListResponseTypes,
        error: undefined
      };
    }

    case GET_SCHOOLS_ITEM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        school: {
          ...action.payload,
          campuses:
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            action.payload?.campuses &&
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            action.payload?.campuses.map((campus: any) => ({
              ...campus,
              teachers: [],
              students: []
            }))
        } as GetSchoolsItemResponseTypes,
        error: undefined
      };
    }

    case GET_CAMPUS_ITEM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        campus: action.payload as GetSchoolsItemResponseTypes,
        error: undefined
      };
    }

    case GET_SCHOOLS_LIST.ERROR:
    case GET_SCHOOLS_ITEM.ERROR:
    case GET_CAMPUS_ITEM.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case GET_SCHOOL_STUDENTS.REQUEST:
    case GET_SCHOOL_TEACHERS.REQUEST:
    case GET_CAMPUS_STUDENTS.REQUEST:
    case GET_CAMPUS_TEACHERS.REQUEST: {
      return {
        ...state,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        school: {
          ...state.school,
          isFetchingUsers: true
        }
      };
    }

    case GET_SCHOOL_TEACHERS.SUCCESS: {
      return {
        ...state,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        school: {
          ...state.school,
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          teachers: action.payload?.users,
          isFetchingUsers: false
        }
      };
    }

    case GET_SCHOOL_STUDENTS.SUCCESS: {
      return {
        ...state,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        school: {
          ...state.school,

          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          students: action.payload?.users,
          isFetchingUsers: false
        }
      };
    }

    case GET_CAMPUS_TEACHERS.SUCCESS: {
      return {
        ...state,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        school: {
          ...state.school,
          isFetchingUsers: false,
          campuses: state.school?.campuses?.map((campus) => {
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            if (campus.id === action.payload.schoolId) {
              return {
                ...campus,
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                teachers: action.payload.users as CampusUserType[],
                error: undefined
              };
            }
            return {
              ...campus
            };
          })
        }
      };
    }

    case GET_CAMPUS_STUDENTS.SUCCESS: {
      return {
        ...state,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        school: {
          ...state.school,
          isFetchingUsers: false,
          campuses: state.school?.campuses?.map((campus) => {
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            if (campus.id === action.payload.schoolId) {
              return {
                ...campus,
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                students: action.payload.users as CampusUserType[],
                error: undefined
              };
            }
            return {
              ...campus
            };
          })
        }
      };
    }

    case SET_ACTIVE_SCHOOL: {
      return {
        ...state,
        activeSchool: action.payload as SchoolSearchOptionTypes
      };
    }

    case GET_SCHOOL_TEACHERS.ERROR:
    case GET_SCHOOL_STUDENTS.ERROR:
    case GET_CAMPUS_TEACHERS.ERROR:
    case GET_CAMPUS_STUDENTS.ERROR: {
      return {
        ...state,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        school: {
          ...state.school,
          isFetchingUsers: false
        },
        error: action.payload
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default schools;
