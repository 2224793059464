import { take, put } from "redux-saga/effects";

// services
import { getGroups } from "src/services/http/group";

// actions
import { GetGroupsRequestTypes, GetGroupsResponseTypes } from "src/redux/actions/group/types";

import * as Effects from "redux-saga/effects";
import { GET_CLASS_LIST_ADMIN_STATS, getClassListAdminActions } from "../../actions/reports";

const call: any = Effects.call;

function* watchGetClassListAdminRequest() {
  while (true) {
    const { payload }: IActionType<GetGroupsRequestTypes> = yield take(GET_CLASS_LIST_ADMIN_STATS.REQUEST);

    try {
      const result: GetGroupsResponseTypes = yield call(getGroups, payload);

      if (result?.rows?.length) {
        const adminClassList = result?.rows.map((group) => ({
          groupId: group.id,
          groupName: group.name
        }));

        yield put(getClassListAdminActions.success(adminClassList));
      } else {
        yield put(getClassListAdminActions.success([]));
      }
    } catch (e) {
      yield put(getClassListAdminActions.error(e as any));
    }
  }
}

export default watchGetClassListAdminRequest;
