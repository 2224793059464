import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useAppFlags } from "../../../helpers/useAppFlags";

// assets
import crossIcon from "src/assets/images/crose-icon.svg";

// components
import { BookList, Search } from "src/components";
import { Tabs } from "src/components/Tabs/Tabs";

//utils
import { addContentToAssignmentActions } from "src/redux/actions/assignments";
import { BookTypes, GetTagListItemTypes } from "src/redux/actions/book/types";

interface IProps {
  offset: number;
  bookList: any;
  isLoading: any;
  loadBookList: any;
  resetSearch?: any;
  handleSearch?: any;
  searchPhrase?: string;
  selectedIds: { bookId: number; thumbnailUrl?: string }[] | undefined;
  bookTypes: GetTagListItemTypes[];
}

export const SelectContentModal: FC<IProps> = ({
  offset,
  bookList,
  isLoading,
  loadBookList,
  resetSearch,
  handleSearch,
  searchPhrase,
  selectedIds,
  bookTypes
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [currentBooks, setCurrentBooks] = useState<{ bookId: number; thumbnailUrl?: string }[]>([]);
  const [contentTypes, setContentTypes] = useState<GetTagListItemTypes["value"][]>([]);
  const { quizLmsEnabled } = useAppFlags();

  const tabsData = useMemo(() => {
    if (bookTypes) {
      if (quizLmsEnabled) {
        return bookTypes.map(({ value, name }) => ({ value, title: t(name) }));
      } else {
        return bookTypes.filter(({ value }) => value === "1").map(({ value, name }) => ({ value, title: t(name) }));
      }
    }
  }, [bookTypes]);

  const handleTabClick = useCallback(
    (id: string) => {
      if (contentTypes) {
        setContentTypes((prev) => {
          const typeIndex = prev.findIndex((typeId) => typeId === id);

          if (typeIndex === -1) {
            return [...prev, id];
          } else {
            const types = [...prev];
            types.splice(typeIndex, 1);
            return types;
          }
        });
      }
    },
    [contentTypes]
  );

  const handleBookClick = useCallback(
    (bookId: number, thumbnailUrl?: string) => {
      setCurrentBooks((prev) => {
        const newItems = [...prev];
        const itemIndex = newItems.findIndex((item) => item.bookId === bookId);
        if (itemIndex >= 0) {
          newItems.splice(itemIndex, 1);
        } else {
          newItems.push({ bookId, thumbnailUrl });
        }
        return newItems;
      });
    },
    [setCurrentBooks]
  );

  const handleSubmit = useCallback(() => {
    if (id && !!currentBooks.length) {
      dispatch(
        addContentToAssignmentActions.request({
          content: currentBooks.map((book) => ({ bookId: book.bookId, thumbnailUrl: book.thumbnailUrl })),
          contentTypes,
          id: Number(id)
        })
      );
      if (selectedIds?.length) {
        setCurrentBooks(selectedIds);
      }
      setContentTypes([]);
    }
  }, [dispatch, id, currentBooks, contentTypes]);

  const booksToShow = useMemo(
    () => bookList?.books.filter((book: BookTypes) => !selectedIds?.find((selected) => selected.bookId === book.id)),
    [bookList?.books, selectedIds]
  );

  return (
    <>
      <div className="student-edit-modal assign-book-modal add-assignment-modal">
        <div className="modal fade" id="selectContentModal" aria-labelledby="selectContentModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header modal_bg">
                <h5 className="modal-title" id="exampleModalLabel">
                  Assign Book
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  // onClick={() => setCurrentIds(booksIds)}
                >
                  <img src={crossIcon} alt="" />
                </button>
              </div>
              <div className="modal-body">
                <div className="assign-book-inner">
                  <div>
                    <h1 className="tech_title">Search Book</h1>
                    <Search
                      search={searchPhrase || ""}
                      handleSearch={handleSearch}
                      resetSearch={resetSearch}
                      showFilterButton
                    />
                  </div>
                  <p className="select-text mt-3 mb-0 pb-3 fw-bold">{t("assignments_modal_label_select_book")}</p>
                  <div id="listWrapper" className="book-list-wrapper">
                    <BookList
                      setSelectedBookId={setCurrentBooks}
                      selectedBookId={currentBooks.map((book) => book.bookId)}
                      onBookClick={(id: number, thumbnailUrl?: string) => {
                        handleBookClick(id, thumbnailUrl);
                      }}
                      scrollableTarget="listWrapper"
                      bookList={booksToShow}
                      offset={offset}
                      scrollableList
                      isLoading={isLoading}
                      loadBooks={loadBookList}
                      totalRows={bookList?.totalRows}
                      hideEditButton
                      bookButtonTitle="Check details"
                    />
                  </div>

                  <div className="modal-footer">
                    <div className="assignmentTypes">
                      <h1 className="tech_title">Assignment Type</h1>
                      {tabsData && (
                        <Tabs data={tabsData} activeTabs={contentTypes.map(String)} handleTabClick={handleTabClick} />
                      )}
                    </div>
                    <div className="actions">
                      <button
                        type="button"
                        className="btn-cancel"
                        data-bs-dismiss="modal"
                        // onClick={() => setCurrentIds(booksIds)}
                      >
                        {t("assignments_modal_button_label_cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn-submit"
                        disabled={!currentBooks.length || !contentTypes.length}
                        onClick={handleSubmit}
                        data-bs-dismiss="modal"
                      >
                        {t("assignments_modal_button_label_submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
