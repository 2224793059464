import { put } from "redux-saga/effects";
import { GET_ASSIGNMENT_BY_ID, getAssignmentsByIdActions } from "src/redux/actions/assignments";

// services
import { getAssignmentById } from "src/services/http/assignments";
import { getBookListExtended } from "../../../services/http/book";

// types
import { AssignmentType, GetAssignmentByIdRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";
import { BookTypes, GetBookListResponseTypes } from "../../actions/book/types";

const call: any = Effects.call;

function* fetchBooksWithThumbnail(bookIds: number[], subscriptionId?: number): Generator<any, BookTypes[], any> {
  try {
    const bookData: GetBookListResponseTypes = yield call(getBookListExtended, {
      ids: bookIds,
      limit: bookIds.length,
      subscriptionIds: subscriptionId ? [subscriptionId] : undefined
    });

    return bookData?.books || [];
  } catch (error) {
    console.error("Error fetching books data:", error);
    return [];
  }
}

function* watchAssignmentByIdRequest() {
  while (true) {
    const { payload }: IActionType<GetAssignmentByIdRequestTypes> = yield Effects.take(GET_ASSIGNMENT_BY_ID.REQUEST);

    try {
      const result: AssignmentType = yield call(getAssignmentById, { id: payload.id });

      if (result?.content?.content?.length) {
        const bookIds = result?.content?.content.map((book) => Math.abs(book.bookId));

        const booksData: BookTypes[] = yield call(fetchBooksWithThumbnail, bookIds, payload.subscriptionId);

        const contentWithThumbs = result?.content?.content.map((book) => {
          const thumbnailUrl = booksData.find((item: BookTypes) => book.bookId === item.id)?.thumbnailSmallUrl;
          return {
            ...book,
            thumbnailUrl
          };
        });

        const resultWithThumbs = {
          ...result,
          content: {
            ...result.content,
            content: contentWithThumbs
          }
        };

        yield put(getAssignmentsByIdActions.success(resultWithThumbs));
      } else {
        yield put(getAssignmentsByIdActions.success(result));
      }
    } catch (e) {
      console.log(e, "error");
      yield put(getAssignmentsByIdActions.error(e as any));
    }
  }
}

export default watchAssignmentByIdRequest;
