import { take, put } from "redux-saga/effects";
import { ADD_AUDIENCE_TO_ASSIGNMENT, addAudienceToAssignmentActions } from "src/redux/actions/assignments";

// services
import { addAudienceToAssignment } from "src/services/http/assignments";

// types
import { AddAudienceToAssignmentRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchAddAudienceToAssignmentRequest() {
  while (true) {
    const { payload }: IActionType<AddAudienceToAssignmentRequestTypes> = yield take(
      ADD_AUDIENCE_TO_ASSIGNMENT.REQUEST
    );

    try {
      yield call(addAudienceToAssignment, payload);

      yield put(addAudienceToAssignmentActions.success(payload));
    } catch (e) {
      console.log(e, "error");
      yield put(addAudienceToAssignmentActions.error(e as any));
    }
  }
}

export default watchAddAudienceToAssignmentRequest;
