import React from "react";

// assets
// import CheckboxIcon from "./icons/CheckboxIcon";
import CheckboxCorrectIcon from "./icons/CheckboxCorrentIcon";
import CheckboxErrorIcon from "./icons/CheckboxErrorIcon";

// types
import { QuizQuestionPropsType } from "./BookQuizQuestionTypes";

const BookQuizQuestion = ({ questionText, wrongAnswers, correctAnswer }: QuizQuestionPropsType) => {
  return (
    <div className="card border-0 assign_card_box">
      <div className="card-body">
        <div className="assign_content">
          <h3>{questionText}</h3>
          {wrongAnswers.map((item) => {
            if (item) {
              return (
                <p key={item}>
                  <span className="pe-2">
                    <CheckboxErrorIcon />
                  </span>
                  {item}
                </p>
              );
            }
          })}

          <p>
            <span className="pe-2">
              <CheckboxCorrectIcon />
            </span>
            {correctAnswer}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookQuizQuestion;
