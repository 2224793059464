const CheckboxIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        stroke="#165B95"
        strokeWidth="2"
        d="M3.04 3.02c1.622-1.975 14.295-1.138 16.188 0 1.894 1.137 1.786 14.613-.513 15.84-2.299 1.228-11.97 3.121-14.933 1.05C.453 17.582 1.416 4.994 3.04 3.02z"
      ></path>
    </svg>
  );
};

export default CheckboxIcon;
