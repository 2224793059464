import React, { useCallback, useEffect } from "react";
import NoResultsSection from "../../../partials/NoResultsSection/NoResultsSection";
import { useTranslation } from "react-i18next";
import { Search, UserCard } from "../../../../../components";
import { SytledCampusStudents } from "./CampusStudentsTab.styles";
import { RootReducer } from "../../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getCampusStudentsActions, getSchoolStudentsActions } from "../../../../../redux/actions/schools";
import LoaderLocal from "../../../../../components/LoaderLocal";

//img
import StudentsNoResults from "src/assets/images/campusNoResults/students.png";

interface IProps {
  campusId: number;
  handleToggleAddUsersPopup: (isTeacher: boolean) => void;
  isSchool?: boolean;
}

const CampusStudentsTab: React.FC<IProps> = ({ campusId, handleToggleAddUsersPopup, isSchool }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    schools: { school }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const campus = school?.campuses?.find((campus) => campus.id === campusId);
  const students = campus?.students ?? school?.students;

  const getSchoolStudents = useCallback(
    (payload: { campusId: number }) => dispatch(getSchoolStudentsActions.request(payload)),
    [dispatch]
  );

  const getCampusStudents = useCallback(
    (payload: { campusId: number }) => dispatch(getCampusStudentsActions.request(payload)),
    [dispatch]
  );

  const loadUsers = () => {
    isSchool ? getSchoolStudents({ campusId }) : getCampusStudents({ campusId });
  };

  useEffect(() => {
    campusId && loadUsers();
  }, [campusId]);

  const handleAddStudents = () => {
    handleToggleAddUsersPopup(false);
  };
  return (
    <SytledCampusStudents>
      <Search
        search={""}
        handleSearch={() => {
          return;
        }}
        resetSearch={() => {
          return;
        }}
        showFilterButton={false}
      />
      <div className="grph-top">
        <p className="chart-title">
          <b>{t("school_campus_students_title")}</b>
        </p>
        <div className="grph-btn">
          {/* TODO: Display import students btn */}
          {/*<button type="button" className="right_btn md btn-primary-dark btn wt_icon" onClick={handleImportStudent}>*/}
          {/*  {t("school_campus_students_import_btn")}*/}
          {/*  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
          {/*    <path*/}
          {/*      fillRule="evenodd"*/}
          {/*      clipRule="evenodd"*/}
          {/*      d="M6.84555 2.53702C6.86155 2.66502 6.88555 2.77702 6.91755 2.87302L10.402 4.62086C7.27326 4.15236 2.85834 3.62662 1.59784 4.16102C0.190208 4.75778 0.702027 7.66093 1.70938 7.66102C2.65334 7.6611 7.09185 7.46595 10.2903 7.31946L8.59755 8.08102C8.05355 8.35302 7.63755 8.64902 7.34955 8.96902C7.07755 9.27302 6.94155 9.70502 6.94155 10.265C6.94155 10.681 7.07755 11.073 7.34955 11.441C7.63755 11.793 8.17355 12.073 8.95755 12.281L15.5575 8.44102C15.9255 8.23302 16.2055 7.94502 16.3975 7.57702C16.5895 7.20902 16.6855 6.68902 16.6855 6.01702C16.6855 5.12102 16.4775 4.40102 16.0615 3.85702L10.9015 0.881016C10.4215 0.609016 10.0135 0.401016 9.67755 0.257016C9.35755 0.113016 9.02955 0.0410156 8.69355 0.0410156C8.34155 0.0410156 8.04555 0.105016 7.80555 0.233015C7.58155 0.345016 7.39755 0.497015 7.25355 0.689015C7.10955 0.881015 7.00555 1.10502 6.94155 1.36102C6.87755 1.61702 6.84555 1.88902 6.84555 2.17702V2.53702Z"*/}
          {/*    />*/}
          {/*  </svg>*/}
          {/*</button>*/}
          <button type="button" className="right_btn md btn-primary-color btn wt_icon" onClick={handleAddStudents}>
            {t("school_campus_students_add_btn")}
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.2417 6.58987C12.2417 6.29771 12.1234 6.01752 11.9129 5.81093C11.7024 5.60434 11.4169 5.48828 11.1191 5.48828C10.8214 5.48828 10.5359 5.60434 10.3254 5.81093C10.1149 6.01752 9.99661 6.29771 9.99661 6.58987V11.3634H5.1323C4.83458 11.3634 4.54906 11.4795 4.33855 11.6861C4.12803 11.8927 4.00977 12.1729 4.00977 12.465C4.00977 12.7572 4.12803 13.0374 4.33855 13.244C4.54906 13.4505 4.83458 13.5666 5.1323 13.5666H9.99661V18.3402C9.99661 18.6323 10.1149 18.9125 10.3254 19.1191C10.5359 19.3257 10.8214 19.4418 11.1191 19.4418C11.4169 19.4418 11.7024 19.3257 11.9129 19.1191C12.1234 18.9125 12.2417 18.6323 12.2417 18.3402V13.5666H17.106C17.4037 13.5666 17.6892 13.4505 17.8997 13.244C18.1102 13.0374 18.2285 12.7572 18.2285 12.465C18.2285 12.1729 18.1102 11.8927 17.8997 11.6861C17.6892 11.4795 17.4037 11.3634 17.106 11.3634H12.2417V6.58987Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>

      {school?.isFetchingUsers ? (
        <>
          <div className="d-flex justify-content-center mt-5">
            <LoaderLocal />
          </div>
        </>
      ) : (
        <>
          {students?.length ? (
            <div className="row">
              {students.map((student, i) => (
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                <div key={student.userId + i + student.name} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <UserCard
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    imageUrl={student.profileImageUrl || ""}
                    schoolId={campusId}
                    name={student.name}
                    id={student.userId}
                    progress={0}
                    showProgress={false}
                    hideDetailsButton={true}
                    role="student"
                    userLink="user"
                    loadUsers={loadUsers}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NoResultsSection title={t("school_campus_no_students_title")} image={StudentsNoResults} largeDesc={true} />
          )}
        </>
      )}
    </SytledCampusStudents>
  );
};

export default CampusStudentsTab;
