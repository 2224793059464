import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  BOOK_ITEMS_LIMIT,
  BOOK_LEXILE_MAX_LEVEL,
  BOOK_READING_MAX_LEVEL,
  TAG_TYPE_BOOK_TYPES,
  TAG_TYPE_CATEGORY,
  TAG_TYPE_CEFR,
  TAG_TYPE_TOPIC
} from "src/constants";
import {
  getBookListActions,
  getBookTypesListActions,
  getCEFRListActions,
  getCategoryListActions,
  getTopicListActions,
  resetBooksAction
} from "src/redux/actions/book";
import {
  GetBookListRequestTypes,
  GetBookListResponseTypes,
  GetTagListRequestTypes
} from "src/redux/actions/book/types";
import { RootReducer } from "src/redux/reducers";
import { getBookListExtended } from "src/services/http/book";

export const useFilterBooks = () => {
  const dispatch = useDispatch();
  const { catId } = useParams();

  const {
    book: { isLoadingBooksData, isLoadingBooksList, bookList, categoryList },
    user: { userData, isLoading: isUserLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  // states
  const [currentCategoryName, setCurrentCategoryName] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [booksInCategory, setBooksInCategory] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [cefrId, setCefrId] = useState<number[]>([]);
  const [topicsId, setTopicsId] = useState<number[]>([]);
  const [readingLevelValue, setReadingLevelValue] = useState<number[]>([0, BOOK_READING_MAX_LEVEL]);
  const [lexileLevelValue, setLexileLevelValue] = useState<number[]>([0, BOOK_LEXILE_MAX_LEVEL]);
  const [filterIsActive, setIsFilterActive] = useState<boolean>(false);
  const [resetBooks, setResetBooks] = useState<boolean>(false);
  const [subscriptionIds, setSubscriptionIds] = useState<number[]>([]);

  const getBooks = useCallback(
    (payload: GetBookListRequestTypes) => dispatch(getBookListActions.request(payload)),
    [dispatch]
  );

  useEffect(() => {
    userData?.subscriptionId && setSubscriptionIds([userData?.subscriptionId]);
  }, [userData]);

  // helper funcs
  const loadBookList = () => {
    if (subscriptionIds?.length) {
      getBooks({
        resetBooks,
        limit: BOOK_ITEMS_LIMIT,
        subscriptionIds,
        offset,
        categoryIds: catId ? [+catId] : categoryIds,
        cefr: cefrId,
        pyp: topicsId,
        searchPhrase: searchPhrase || "",
        readingLevelFrom:
          readingLevelValue[0] !== 0 || readingLevelValue[1] !== BOOK_READING_MAX_LEVEL
            ? readingLevelValue[0]
            : undefined,
        readingLevelTo:
          readingLevelValue[0] !== 0 || readingLevelValue[1] !== BOOK_READING_MAX_LEVEL
            ? readingLevelValue[1]
            : undefined,
        lexileFrom:
          lexileLevelValue[0] !== 0 || lexileLevelValue[1] !== BOOK_LEXILE_MAX_LEVEL ? lexileLevelValue[0] : undefined,
        lexileTo:
          lexileLevelValue[0] !== 0 || lexileLevelValue[1] !== BOOK_LEXILE_MAX_LEVEL ? lexileLevelValue[1] : undefined
      });
      setOffset(offset + BOOK_ITEMS_LIMIT);
      setResetBooks(false);
    }
  };

  const chunkArray = useMemo(() => {
    let currentIndex = 0;

    return (arr: any[], chunkSize: number, isReset = false) => {
      if (isReset) {
        currentIndex = 0;
      }
      if (currentIndex < arr.length) {
        const chunk = arr.slice(currentIndex, currentIndex + chunkSize);
        currentIndex += chunkSize;
        return [...chunk];
      } else {
        return [];
      }
    };
  }, []);

  const getBooksSortedByCategories = async (isReset = false) => {
    const result: React.SetStateAction<any[] | null> = [];
    let isResetChunk = isReset;

    if (!catId && categoryList?.length && subscriptionIds?.length) {
      setLoading(true);
      let chunkedCategories: any[] = [];
      while (result.length < 2) {
        chunkedCategories = chunkArray(categoryList, 2, isResetChunk);
        isResetChunk = false;
        if (chunkedCategories.length === 0) {
          break;
        }
        await Promise.all(
          chunkedCategories.map(async (item) => {
            const data = (await getBookListExtended({
              limit: 3,
              subscriptionIds,
              offset: 0,
              categoryIds: [item.id]
            })) as GetBookListResponseTypes;

            if (data?.books?.length) {
              const element = { ...data, categoryName: item.name, categoryId: item.id };
              result.push(element);
            }
          })
        );
      }

      setBooksInCategory(isReset ? [...result] : (prevState) => [...prevState, ...result]);
    }
    setLoading(false);
  };

  const getCategoryList = useCallback(
    (payload: GetTagListRequestTypes) => dispatch(getCategoryListActions.request(payload)),
    [dispatch]
  );

  const getCEFRList = useCallback(
    (payload: GetTagListRequestTypes) => dispatch(getCEFRListActions.request(payload)),
    [dispatch]
  );

  const getBookTypesList = useCallback(
    (payload: GetTagListRequestTypes) => dispatch(getBookTypesListActions.request(payload)),
    [dispatch]
  );

  const getTopicList = useCallback(
    (payload: GetTagListRequestTypes) => dispatch(getTopicListActions.request(payload)),
    [dispatch]
  );

  const resetSearch = () => {
    setOffset(0);
    setSearchPhrase("");
    setResetBooks(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchPhrase(value);
    if (value.length === 0 || value.length > 2) {
      setOffset(0);
    }
  };

  const handleSubmit = (
    categories: typeof categoryIds,
    cefr: typeof cefrId,
    topics: typeof topicsId,
    readingValue: typeof readingLevelValue,
    lexileValue: typeof lexileLevelValue
  ) => {
    setCefrId(cefr);
    setTopicsId(topics);
    setReadingLevelValue(readingValue);
    setLexileLevelValue(lexileValue);
    setIsFilterActive(true);
    setResetBooks(true);
    setOffset(0);
    if (categories) {
      setCategoryIds(categories);
    }
  };

  const getCurrentCategory = () => {
    let result = "";

    if (categoryList && catId) {
      categoryList.find((item) => {
        if (item.id === +catId) {
          result = item.name;
        }
      });
    }

    return result;
  };

  // useEffects
  useEffect(() => {
    if (subscriptionIds?.length) {
      getCategoryList({
        id: TAG_TYPE_CATEGORY,
        languageId: 2
      });
      getCEFRList({ id: TAG_TYPE_CEFR, languageId: 2 });
      getTopicList({ id: TAG_TYPE_TOPIC, languageId: 2 });
      getBookTypesList({ id: TAG_TYPE_BOOK_TYPES, languageId: 2 });
    }
  }, [subscriptionIds]);

  useEffect(() => {
    catId && setCurrentCategoryName(getCurrentCategory());
  }, [categoryList]);

  useEffect(() => {
    setLoading(isLoadingBooksData);
  }, [isLoadingBooksData]);

  useEffect(() => {
    if (searchPhrase.length === 0 || (searchPhrase.length > 2 && subscriptionIds?.length)) {
      loadBookList();
    }
  }, [searchPhrase]);

  useEffect(() => {
    subscriptionIds?.length && loadBookList();
  }, [subscriptionIds, categoryIds, cefrId, topicsId, readingLevelValue, lexileLevelValue]);

  useEffect(() => {
    return () => {
      dispatch(resetBooksAction);
    };
  }, []);

  return {
    bookList,
    booksInCategory,
    offset,
    loading,
    searchPhrase,
    categoryIds,
    subscriptionIds,
    cefrId,
    topicsId,
    readingLevelValue,
    lexileLevelValue,
    filterIsActive,
    categoryList,
    getBooksSortedByCategories,
    resetSearch,
    handleSearch,
    handleSubmit,
    loadBookList,
    isLoadingBooksData,
    isLoadingBooksList,
    isUserLoading,
    currentCategoryName,
    setOffset,
    setBooksInCategory
  };
};
