import { take, put } from "redux-saga/effects";
import { GET_USER_INFO, getUserInfoActions } from "src/redux/actions/user";
import * as Sentry from "@sentry/react";

// services
import { getUserInfo } from "src/services/http/user";

// types
import { GetUserInfoResponseTypes } from "src/redux/actions/user/types";

import * as Effects from "redux-saga/effects";
import flagsmith from "flagsmith";
const call: any = Effects.call;

function* watchGetUserInfoRequest() {
  while (true) {
    const { payload }: IActionType<{ userId: string | undefined }> = yield take(GET_USER_INFO.REQUEST);

    try {
      const result: GetUserInfoResponseTypes = yield call(getUserInfo, payload);
      yield put(getUserInfoActions.success(result));

      if (result?.id) {
        Sentry.setUser({ id: result.id });
        flagsmith.identify(result.id.toString(), { schoolId: result.schoolId ?? null });
      } else {
        Sentry.setUser(null);
      }
    } catch (e) {
      yield put(getUserInfoActions.error(e as any));
    }
  }
}

export default watchGetUserInfoRequest;
