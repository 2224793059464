import { requestTypeCreator } from "src/helpers/index";
import { GetDashboardInfoResponseTypes } from "./types";

export const GET_DASHBOARD_INFO = requestTypeCreator("GET_DASHBOARD_INFO");

export const getDashboardInfoActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_DASHBOARD_INFO.ERROR
  }),
  request: (payload: { schoolId: number | undefined }) => ({
    payload,
    type: GET_DASHBOARD_INFO.REQUEST
  }),
  success: (payload: GetDashboardInfoResponseTypes): IActionType<GetDashboardInfoResponseTypes> => ({
    payload,
    type: GET_DASHBOARD_INFO.SUCCESS
  })
};
