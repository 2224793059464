import styled from "styled-components";

export const StyledActiveSchool = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  * {
    flex-shrink: 0;
  }

  h5 {
    margin: 0;
  }

  .editSchoolName {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: inherit;
      opacity: 0.8;
    }

    svg {
      color: #38a6de;
      transform: scale(0.7) translateY(-10px);
    }
  }

  .search-school-form {
    width: 100%;

    form {
      position: relative;
      width: 100%;

      input {
        border: 2px solid #e3e2e2;
        border-radius: 30px;
        height: 40px;
        width: 250px;
        max-width: 100%;
        padding: 6px 20px;
        padding-left: 35px;
      }

      .searchIcon {
        position: absolute;
        top: 11px;
        left: 10px;
        background-color: transparent;
        border: 0;
      }

      .closeIcon {
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
  }
`;
