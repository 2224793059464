import { requestTypeCreator } from "src/helpers/index";
import {
  AssignStudentToGroupRequestTypes,
  CreateNewGroupRequestTypes,
  CreateNewGroupResponseTypes,
  GetGroupsRequestTypes,
  GetGroupsResponseTypes
} from "./types";

export const CREATE_NEW_GROUP = requestTypeCreator("CREATE_NEW_GROUP");
export const GET_GROUPS = requestTypeCreator("GET_GROUPS");
export const ASSIGN_STUDENTS_TO_GROUP = requestTypeCreator("ASSIGN_STUDENTS_TO_GROUP");

export const createNewGroupActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: CREATE_NEW_GROUP.ERROR
  }),
  request: (payload: CreateNewGroupRequestTypes) => ({
    payload,
    type: CREATE_NEW_GROUP.REQUEST
  }),
  success: (payload: any): IActionType<CreateNewGroupResponseTypes> => ({
    payload,
    type: CREATE_NEW_GROUP.SUCCESS
  })
};

export const getGroupsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_GROUPS.ERROR
  }),
  request: (payload: GetGroupsRequestTypes) => ({
    payload,
    type: GET_GROUPS.REQUEST
  }),
  success: (payload: GetGroupsResponseTypes): IActionType<GetGroupsResponseTypes> => ({
    payload,
    type: GET_GROUPS.SUCCESS
  })
};

export const assignStudentsToGroupActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: ASSIGN_STUDENTS_TO_GROUP.ERROR
  }),
  request: (payload: AssignStudentToGroupRequestTypes) => ({
    payload,
    type: ASSIGN_STUDENTS_TO_GROUP.REQUEST
  }),
  success: (payload: AssignStudentToGroupRequestTypes): IActionType<AssignStudentToGroupRequestTypes> => ({
    payload,
    type: ASSIGN_STUDENTS_TO_GROUP.SUCCESS
  })
};
