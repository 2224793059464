import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoaderLocal from "../../../../LoaderLocal";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { assignClassesToUser, getAssignedUserClasses, setUserReadingLevel } from "../../../../../services/http/users";
import { getUserInfo } from "../../../../../services/http/user";
import { GetUserInfoResponseTypes } from "../../../../../redux/actions/user/types";
import { ClassItemStatsTypes } from "../../../../../redux/actions/reports/types";
import { getSchoolClasses } from "../../../../../services/http/schools";

interface Props {
  isShow: boolean;
  userId: number;
  schoolId: number;
  isNameEdit: boolean;
  handleToggle: () => void;
  handleSuccess: () => void;
}

const StudentEditForm: React.FC<Props> = ({ isShow, userId, schoolId, isNameEdit, handleToggle, handleSuccess }) => {
  const [t] = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingInitData, setIsFetchingInitData] = useState(false);
  const [error, setError] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");

  const [schoolClasses, setSchoolClasses] = useState<ClassItemStatsTypes[]>([]);
  const [initUserClassId, setInitUserClassId] = useState(0);
  const [initReadingLevel, setInitReadingLevel] = useState(0);

  const getInitialData = async () => {
    setIsFetchingInitData(true);
    try {
      const assignedClasses = (await getAssignedUserClasses({ schoolId, userId })) as ClassItemStatsTypes[];
      const schoolClassesList = (await getSchoolClasses({ schoolId })) as ClassItemStatsTypes[];
      const { calculatedReadingLevel } = (await getUserInfo({ userId })) as GetUserInfoResponseTypes;

      assignedClasses?.length && setInitUserClassId(assignedClasses[0]?.groupId);
      schoolClassesList?.length && setSchoolClasses(schoolClassesList);
      calculatedReadingLevel && setInitReadingLevel(calculatedReadingLevel);
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetchingInitData(false);
    }
  };

  const studentInitialValues = {
    studentClass: +initUserClassId || 0,
    readingLevel: initReadingLevel
  };

  const studentValidationSchema = Yup.object().shape({
    studentClass: Yup.number()
      .required(`${t("edit_user_class")} ${t("is_required")}`)
      .test("is-non-zero", `${t("edit_user_class")} ${t("is_required")}`, (value) => value !== 0),
    readingLevel: Yup.number().required(`${t("edit_user_readingLvl")} ${t("is_required")}`)
  });

  const handleSubmitStudentForm = async ({
    studentClass,
    readingLevel
  }: {
    studentClass: number;
    readingLevel: number;
  }) => {
    setErrorSubmit("");
    setIsFetching(true);

    const assignClassPayload = {
      userId,
      memberships: schoolClasses.map((schoolClass) => ({
        userId,
        role: "student",
        groupId: schoolClass.groupId,
        isMember: studentClass === schoolClass.groupId
      }))
    };

    try {
      const assignClassRes = await assignClassesToUser(assignClassPayload);
      const setReadingLvlRes = await setUserReadingLevel({ userId, readingLevel });

      if (assignClassRes && setReadingLvlRes) {
        handleSuccess();
      } else {
        setErrorSubmit(`${t("Error")}`);
      }
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsResetForm(true);
      setError(error);
    };
  }, []);

  useEffect(() => {
    isShow && getInitialData();
  }, [isShow]);

  if (isFetchingInitData) {
    return <LoaderLocal />;
  }

  return (
    <Formik
      validationSchema={studentValidationSchema}
      initialValues={studentInitialValues}
      onSubmit={handleSubmitStudentForm}
      enableReinitialize={isResetForm}
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <div className="form-group">
            <label className="label-text pt-2">{t("edit_user_select_class")}</label>
            <FormControl fullWidth={true}>
              <Select
                labelId="student-class"
                id="student-class"
                name="studentClass"
                value={values.studentClass}
                onChange={(e) => setFieldValue("studentClass", e.target.value as number)}
              >
                {schoolClasses.map((schoolClass) => (
                  <MenuItem key={schoolClass.groupId + schoolClass.groupName} value={+schoolClass.groupId}>
                    {schoolClass.groupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {errors.studentClass && (
              <ErrorMessage
                name="studentClass"
                render={(children) => (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {children}
                  </div>
                )}
              />
            )}
          </div>
          <div className="form-group">
            <label className="label-text pt-2">{t("edit_user_readingLvl")}</label>
            <Field type="text" name="readingLevel" autoComplete="off" className="form-control" />
            {errors.readingLevel && (
              <ErrorMessage
                name="readingLevel"
                render={(children) => (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {children}
                  </div>
                )}
              />
            )}
          </div>
          {errorSubmit && <div className="formSubmitError">{errorSubmit}</div>}
          {!isNameEdit ? (
            <div className="submitRow mt-36">
              <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleToggle}>
                {t("Cancel")}
              </button>
              <button type="submit" className="btn-submit" disabled={isFetching} data-bs-dismiss="modal">
                {isFetching ? <LoaderLocal forBtn={true} /> : <>{t("Submit")}</>}
              </button>
            </div>
          ) : (
            <></>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default StudentEditForm;
