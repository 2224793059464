import { take, put } from "redux-saga/effects";
import { GET_DASHBOARD_INFO, getDashboardInfoActions } from "src/redux/actions/dashboard";

// services
import { getDashboardInfo } from "src/services/http/dashboard";

// types
import { GetDashboardInfoResponseTypes } from "src/redux/actions/dashboard/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetDashboardInfoRequest() {
  while (true) {
    const { payload }: IActionType<{ schoolId: string | undefined }> = yield take(GET_DASHBOARD_INFO.REQUEST);

    try {
      const result: GetDashboardInfoResponseTypes = yield call(getDashboardInfo, payload);
      yield put(getDashboardInfoActions.success(result));
    } catch (e) {
      yield put(getDashboardInfoActions.error(e as any));
    }
  }
}

export default watchGetDashboardInfoRequest;
