import { take, put } from "redux-saga/effects";
import { LOGIN, loginActions } from "src/redux/actions/auth";

// services
import { setToken } from "src/services/localStorage";
import { login } from "src/services/http/user";

// types
import { LoginRequestTypes, LoginResponseTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchLoginRequest() {
  while (true) {
    const { payload }: IActionType<LoginRequestTypes> = yield take(LOGIN.REQUEST);

    const { onError, onSuccess, ...restPayload } = payload as LoginRequestTypes;

    try {
      const result: LoginResponseTypes = yield call(login, restPayload);

      setToken(result.access_token);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(loginActions.success({ access_token: result.access_token, userId: result.UserId }));
      onSuccess();
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.error || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(loginActions.error(e.error || "Something went wrong, try again later"));
    }
  }
}

export default watchLoginRequest;
