import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import LoaderLocal from "../../../../LoaderLocal";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { updateUserPassword } from "src/services/http/users";

interface Props {
  userId: number;
  handleClose: () => void;
  handleSuccess: () => void;
}

const PasswordUpdateForm: React.FC<Props> = ({ userId, handleClose, handleSuccess }) => {
  const [t] = useTranslation();

  const [isResetForm, setIsResetForm] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(`${t("edit_user_new_password_title")} ${t("is_required")}`)
      .min(3, `${t("edit_user_new_password_length_warning")}`)
  });

  const handleSubmit = async ({ password }: { password: string }) => {
    setErrorSubmit("");
    setIsFetching(true);

    try {
      await updateUserPassword({ password, userId });
      handleSuccess();
    } catch (e: any) {
      setErrorSubmit(e.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsResetForm(true);
      setIsFetching(false);
      setError(error);
      setErrorSubmit("");
    };
  }, []);

  return (
    <Formik
      validationSchema={passwordValidationSchema}
      initialValues={{ password: "" }}
      onSubmit={handleSubmit}
      enableReinitialize={isResetForm}
    >
      {({ errors }) => (
        <Form>
          <div className="form-group">
            <label className="label-text pt-2">{t("edit_user_new_password_title")}</label>
            <Field type="password" name="password" autoComplete="off" className="form-control" />
            {errors.password && (
              <ErrorMessage
                name="password"
                render={(children) => (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {children}
                  </div>
                )}
              />
            )}
          </div>
          {errorSubmit && <div className="formSubmitError">{errorSubmit}</div>}
          <div className="submitRow mt-36">
            <button type="button" className="btn-cancel" data-bs-dismiss="modal" onClick={handleClose}>
              {t("Cancel")}
            </button>
            <button type="submit" className="btn-submit" disabled={isFetching} data-bs-dismiss="modal">
              {isFetching ? <LoaderLocal forBtn={true} /> : <>{t("Submit")}</>}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordUpdateForm;
