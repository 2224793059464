import { FC, useCallback, useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// styles
import "react-datepicker/dist/react-datepicker.css";

// types
import { AssignmentType } from "src/redux/actions/assignments/types";

// components
import { SelectContentModal } from "./SelectContentModal";
import FilterModal from "src/modules/library/partials/filterModal";

// hooks
import { useFilterBooks } from "src/hooks/useFilterBooks";

// utils
import { RootReducer } from "src/redux/reducers";
import { unassignContentFromAssignmentActions } from "src/redux/actions/assignments";

// assets
import DefaultBook from "src/assets/images/default-book.png";
import CrossIcon from "src/assets/images/crose-icon.svg";
import { groupBy } from "lodash";

interface IProps {
  assignment: AssignmentType | undefined;
}

export const SecondStepSection: FC<IProps> = ({ assignment }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [t] = useTranslation();

  const {
    book: { bookList, bookTypes, categoryList, cefrList, topicList }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const {
    offset,
    searchPhrase,
    categoryIds,
    readingLevelValue,
    lexileLevelValue,
    resetSearch,
    handleSearch,
    handleSubmit: handleSubmitFilter,
    loadBookList,
    isLoadingBooksList,
    setOffset
  } = useFilterBooks();

  const handleUnassignContent = useCallback((challengeContentId: number) => {
    dispatch(
      unassignContentFromAssignmentActions.request({
        id: Number(id),
        challengeContentId
      })
    );
  }, []);

  const groupedContentData = useMemo(() => {
    const groupedData = groupBy(assignment?.content?.content, "bookId");

    return Object.keys(groupedData).map((id) => ({
      bookId: id,
      contentType: groupedData[id].map((book) => book.contentType),
      thumbnailUrl: groupedData[id][0].thumbnailUrl
    }));
  }, [assignment?.content?.content]);

  useEffect(() => {
    setOffset(0);
    const isLoadBooks =
      !bookList?.books?.length && bookTypes?.length && categoryList?.length && cefrList?.length && topicList?.length;

    if (isLoadBooks) {
      loadBookList();
    }
  }, [bookTypes, categoryList, cefrList, topicList]);

  return (
    <div className="ds-card sec_body">
      <Formik initialValues={{}} onSubmit={(e) => console.log(e)}>
        {() => {
          return (
            <>
              <div className="student-edit-form m-0 second-step">
                <div className="mt-5">
                  <h1>2. {t("assignment_create_second_step_title")}</h1>
                  <Form>
                    <div className="form-group">
                      <label className="label-text pt-2">{t("book_type_ebook")}</label>
                      <div className="row my-2">
                        {groupedContentData?.map(({ bookId, thumbnailUrl, contentType }) => {
                          const contentTypes = bookTypes?.filter(({ value }) =>
                            contentType.find((type) => Number(type) == Number(value))
                          );
                          return (
                            <div className="col-md-3 col-sm-6 image-wrapper" key={bookId}>
                              <div className="thumb">
                                <img src={thumbnailUrl || DefaultBook} alt="Img" className="img-fluid" />
                              </div>
                              <div className="types-wrapper">
                                {contentTypes?.map(({ value, name }) => (
                                  <div key={value} className="type-label">
                                    {t(name)}
                                  </div>
                                ))}
                              </div>
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Delete book"
                                onClick={() => handleUnassignContent(Number(bookId))}
                              >
                                <img src={CrossIcon} alt="" />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <button className="btn btn-edit" data-bs-toggle="modal" data-bs-target="#selectContentModal">
                        {t("school_campus_books_add_btn")}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
      {bookTypes && (
        <SelectContentModal
          offset={offset}
          bookList={bookList}
          isLoading={isLoadingBooksList}
          loadBookList={loadBookList}
          resetSearch={resetSearch}
          handleSearch={handleSearch}
          searchPhrase={searchPhrase}
          selectedIds={assignment?.content?.content.map(({ bookId, thumbnailUrl }) => ({ bookId, thumbnailUrl }))}
          bookTypes={bookTypes}
        />
      )}
      <FilterModal
        categoryId={categoryIds}
        readingLevelValue={readingLevelValue}
        lexileLevelValue={lexileLevelValue}
        modalIdToOpen="#selectContentModal"
        handleSubmit={handleSubmitFilter}
        setOffset={setOffset}
      />
    </div>
  );
};
