import { take, put } from "redux-saga/effects";
import { GET_CAMPUS_ITEM, getCampusItemActions } from "src/redux/actions/schools";

// services
import { getSchool } from "../../../services/http/schools";

// types
import { GetSchoolsItemResponseTypes } from "src/redux/actions/schools/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetCampusItemRequest() {
  while (true) {
    const { payload }: IActionType<{ iso: string | undefined }> = yield take(GET_CAMPUS_ITEM.REQUEST);

    try {
      const result: GetSchoolsItemResponseTypes = yield call(getSchool, payload);
      yield put(getCampusItemActions.success(result));
    } catch (e) {
      yield put(getCampusItemActions.error(e as any));
    }
  }
}

export default watchGetCampusItemRequest;
