import { put, takeLatest } from "redux-saga/effects";
import { GET_STUDENT_ASSIGNMENTS, getStudentAssignmentsActions } from "src/redux/actions/assignments";

// services
import { getStudentAssignments } from "src/services/http/assignments";
import { getBookListExtended } from "src/services/http/book";

// types
import {
  GetStudentAssignmentRequestTypes,
  GetStudentAssignmentResponseTypes
} from "src/redux/actions/assignments/types";
import { GetBookListResponseTypes } from "src/redux/actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

const sortByPublishDateDescending = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  data.sort((a, b) => {
    const dateA = new Date(a.publishDate);
    const dateB = new Date(b.publishDate);

    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  });
};

function getBookIds(challenges: any) {
  if (challenges?.length) {
    const challengeBookIds = challenges.map((challenge: any) => {
      if (challenge.bookId) {
        return challenge.bookId;
      }
    });
    return challengeBookIds.filter((id: number) => !!id);
  }
}

function* processItems(challenges: any[], assignmentsList: any[], subscriptionId?: number) {
  if (challenges.length === 0 && assignmentsList.length === 0) return;
  const challengeBookIds: number[] = yield getBookIds(challenges);
  const bookData: GetBookListResponseTypes = yield getBookListExtended({
    ids: challengeBookIds,
    limit: challengeBookIds.length,
    subscriptionIds: subscriptionId ? [subscriptionId] : undefined
  });
  for (const challenge of challenges) {
    const book = bookData.books.find((item) => item.id === challenge.bookId);
    assignmentsList.push({ ...challenge, bookData: book });
  }
}

//saga
function* watchStudentAssignmentsRequest() {
  yield takeLatest(GET_STUDENT_ASSIGNMENTS.REQUEST, handleStudentAssignmentsRequest);
}

//handler
function* handleStudentAssignmentsRequest(action: IActionType<GetStudentAssignmentRequestTypes>) {
  try {
    const assignmentsList: any = [];
    const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments, { userId: action.payload.id });

    sortByPublishDateDescending(result);

    yield processItems(result, assignmentsList, action.payload.subscriptionId);

    yield put(getStudentAssignmentsActions.success(assignmentsList));
  } catch (e) {
    console.log(e, "error");
    yield put(getStudentAssignmentsActions.error(e as any));
  }
}

export default watchStudentAssignmentsRequest;
