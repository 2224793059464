import styled from "styled-components";

export const StyledViewAssignment = styled.div`
  .studentsTableWrapper {
    margin-top: 25px;
  }

  .sec_body {
    padding-top: 0;
    overflow-x: auto;
  }

  table {
    thead {
      th {
        color: #01324b;
        text-transform: capitalize;
      }
    }

    tbody {
      tr {
        text-align: center;

        &.even {
          background-color: #e2f2ff;
        }

        &:hover {
          background-color: #32a2dc;
          color: #fff;
        }

        td {
          color: #01324b;
        }
      }
    }

    thead th,
    tbody tr td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;

        a,
        button {
          margin-left: auto;
        }
      }
    }
  }

  .studentsTableWrapper {
    padding-top: 0;
    height: 428px;
    overflow: auto;

    table {
      thead {
        position: sticky;
        top: 0;
        background-color: #fff;

        &:after {
          display: none;
        }
      }
    }
  }
`;
