import { take, put } from "redux-saga/effects";
import { GET_ASSIGNMENT_USER_ACTIVITY, getAssignmentUserActivityActions } from "src/redux/actions/assignments";

// services
import { getGetAssignmentUserActivity } from "src/services/http/assignments";

// types
import { GetAssignmentUserActivityResponseTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchAssignmentUserActivityRequest() {
  while (true) {
    const { payload } = yield take(GET_ASSIGNMENT_USER_ACTIVITY.REQUEST);

    try {
      const result: GetAssignmentUserActivityResponseTypes = yield call(getGetAssignmentUserActivity, {
        assignmentId: payload.id,
        userId: payload.userId
      });
      yield put(getAssignmentUserActivityActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getAssignmentUserActivityActions.error(e as any));
    }
  }
}

export default watchAssignmentUserActivityRequest;
