import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif"
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "14px",
          height: "48px",
          boxSizing: "border-box",

          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#38a6de"
            }
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#38a6de",
            borderWidth: "2px"
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#38a6de"
          },

          ".MuiSelect-icon": {
            width: "24px",
            height: "24px",
            color: "#0e2d54"
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 11300
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#e2f2ff"
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "#165b95",
            color: "white"
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "14px",
          boxSizing: "border-box"
        }
      }
    }
  }
});
