import { USER_TYPES } from "../constants";
import { RootReducer } from "../redux/reducers";
import { useSelector } from "react-redux";

const useUserType = () => {
  const {
    user: { userData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const isAdmin = userData && [USER_TYPES.SCHOOL_ADMIN, USER_TYPES.DISTRIBUTOR_ADMIN].includes(userData?.userTypeId);

  return { isAdmin };
};

export default useUserType;
