import React from "react";
import arrowIcon from "src/assets/fonts/10.svg";
import { Link } from "react-router-dom";

const ButtonPrimaryInner = () => (
  <>
    <span>
      <img src={arrowIcon} alt="Img" className="img-fluid" />
    </span>
    <div className="button-hover">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 375 70"
        width="375"
        height="70"
        preserveAspectRatio="xMidYMid meet"
        style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}
      >
        <defs>
          <clipPath id="__lottie_element_159">
            <rect width="375" height="70" x="0" y="0"></rect>
          </clipPath>
        </defs>
        <g clipPath="url(#__lottie_element_159)">
          <g transform="matrix(1,0,0,1,362.5,54.75)" opacity="1" style={{ display: "block" }}>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="#CB1E5C"
                strokeOpacity="1"
                strokeWidth="4"
                d=" M-5,-5.25 C-5,-5.25 5,5.25 5,5.25"
              ></path>
            </g>
          </g>
          <g transform="matrix(1,0,0,1,363,31.5)" opacity="1" style={{ display: "block" }}>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="#CB1E5C"
                strokeOpacity="1"
                strokeWidth="4"
                d=" M-5.5,-0.5 C-5.5,-0.5 5.5,0.5 5.5,0.5"
              ></path>
            </g>
          </g>
          <g transform="matrix(1,0,0,1,363.25,9.25)" opacity="1" style={{ display: "block" }}>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="#CB1E5C"
                strokeOpacity="1"
                strokeWidth="4"
                d=" M-5.25,3.25 C-5.25,3.25 5.25,-3.25 5.25,-3.25"
              ></path>
            </g>
          </g>
          <g transform="matrix(1,0,0,1,12.5,58.75)" opacity="1" style={{ display: "block" }}>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="#CB1E5C"
                strokeOpacity="1"
                strokeWidth="4"
                d=" M5,-5.25 C5,-5.25 -5,5.25 -5,5.25"
              ></path>
            </g>
          </g>
          <g transform="matrix(1,0,0,1,12,35.5)" opacity="1" style={{ display: "block" }}>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="#CB1E5C"
                strokeOpacity="1"
                strokeWidth="4"
                d=" M5.5,-0.5 C5.5,-0.5 -5.5,0.5 -5.5,0.5"
              ></path>
            </g>
          </g>
          <g transform="matrix(1,0,0,1,11.5,13.25)" opacity="1" style={{ display: "block" }}>
            <g opacity="1" transform="matrix(1,0,0,1,0,0)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="#CB1E5C"
                strokeOpacity="1"
                strokeWidth="4"
                d=" M5.5,3.25 C5.5,3.25 -5.5,-3.25 -5.5,-3.25"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </>
);

interface Props {
  role?: string;
  to?: string;
  title: string;
  handleClick?: () => void;
  target?: string;
  disabled?: boolean;
}

const ButtonPrimary: React.FC<Props> = ({ role = "button", to, title, handleClick, target, disabled = false }) => {
  if (role === "link") {
    return (
      <Link to={`${to}`} className={`btn btn-primary ${disabled ? "disabled" : ""}`} target={target}>
        {title}
        <ButtonPrimaryInner />
      </Link>
    );
  } else {
    return (
      <button className="btn btn-primary" disabled={disabled} onClick={handleClick}>
        {title}
        <ButtonPrimaryInner />
      </button>
    );
  }
};

export default ButtonPrimary;
