import { take, put } from "redux-saga/effects";
import { GET_BOOK_GAMES, getBookGamesActions } from "src/redux/actions/assignments";

// services
import { getBookGames } from "src/services/http/assignments";

// types
import { GetBookGamesRequestTypes, GetBookGamesResponseTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookGamesRequest() {
  const filterByBookGameType = (arr: GetBookGamesResponseTypes) => {
    return arr.filter((obj) => obj.bookGameType === 2 || obj.bookGameType === 3);
  };

  while (true) {
    const { payload }: IActionType<GetBookGamesRequestTypes> = yield take(GET_BOOK_GAMES.REQUEST);

    try {
      const result: GetBookGamesResponseTypes = yield call(getBookGames, { bookId: payload.id });

      const filteredResult = filterByBookGameType(result);

      if (payload.onSuccess) {
        payload.onSuccess(filteredResult);
      }
      yield put(getBookGamesActions.success(filteredResult));
    } catch (e) {
      console.log(e, "error");
      yield put(getBookGamesActions.error(e as any));
    }
  }
}

export default watchGetBookGamesRequest;
