import { take, put } from "redux-saga/effects";
import { PUBLISH_ASSIGNMENT, publishAssignmentActions } from "src/redux/actions/assignments";

// services
import { publishAssignment } from "src/services/http/assignments";

// types
import { PublishAssignmentRequestTypes } from "src/redux/actions/assignments/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchPublishAssignmentRequest() {
  while (true) {
    const { payload }: IActionType<PublishAssignmentRequestTypes> = yield take(PUBLISH_ASSIGNMENT.REQUEST);

    try {
      yield call(publishAssignment, payload);

      yield put(publishAssignmentActions.success(payload));
    } catch (e) {
      console.log(e, "error");
      yield put(publishAssignmentActions.error(e as any));
    }
  }
}

export default watchPublishAssignmentRequest;
