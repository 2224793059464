import { take, put } from "redux-saga/effects";
import { GET_STUDENT_STATS, getStudentStatsActions } from "src/redux/actions/reports";

// services
import { getStudentStats } from "src/services/http/reports";

// types
import { GetStudentStatsRequestTypes, GetStudentStatsResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetStudentStatsRequest() {
  while (true) {
    const { payload }: IActionType<GetStudentStatsRequestTypes> = yield take(GET_STUDENT_STATS.REQUEST);

    try {
      const result: GetStudentStatsResponseTypes = yield call(getStudentStats, payload);
      yield put(getStudentStatsActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getStudentStatsActions.error(e as any));
    }
  }
}

export default watchGetStudentStatsRequest;
