import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { StyledEditUserPopup } from "./EditUserPopup.styles";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../../ButtonPrimary/ButtonPrimary";
import PasswordUpdateForm from "./partials/PasswordUpdateForm";
import StudentEditForm from "./partials/StudentEditForm";
import TeacherEditForm from "./partials/TeacherEditForm";
import { IconButton } from "@mui/material";
import pencil from "../../../../assets/images/pencil.svg";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UserPhoto from "../UserPhoto/UserPhoto";
import DeleteUserForm from "./partials/DeleteUserForm";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { updateUserName } from "../../../../services/http/users";
import { getTruncatedWords } from "src/helpers/index";

interface IProps {
  schoolId: number;
  userId: number;
  name: string;
  imageUrl?: string;
  role?: string;
  isShow: boolean;
  handleToggle: () => void;
  loadUsers: () => void;
}

const EditUserPopup: React.FC<IProps> = ({
  schoolId,
  userId,
  name,
  imageUrl,
  role,
  isShow,
  handleToggle,
  loadUsers
}) => {
  const [t] = useTranslation();
  const [modalTitle, setModalTitle] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [isPasswordFormShow, setIsPasswordFormShow] = useState(false);
  const [isDeleteFormShow, setIsDeleteFormShow] = useState(false);

  const [isNameFetching, setIsNameFetching] = useState(false);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [nameInputVal, setNameInputVal] = useState(name);
  const [updatedName, setUpdatedName] = useState(name);

  const handleTogglePasswordForm = () => {
    setIsPasswordFormShow((prev) => !prev);
    if (isPasswordFormShow) {
      setModalTitle("");
    } else {
      setModalTitle(`${t("edit_user_update_password")}`);
    }
  };

  const handleToggleDeleteForm = () => {
    setIsDeleteFormShow((prev) => !prev);
    if (isDeleteFormShow) {
      setModalTitle("");
    } else {
      setModalTitle(`${t("edit_user_delete_account")}`);
    }
  };

  const handleToggleEditModal = () => {
    handleToggle();
    if (isShow) {
      setModalTitle("");
      setSuccessSubmit(false);
      setIsPasswordFormShow(false);

      if (name !== updatedName) {
        loadUsers();
      }
    }
  };

  const handleClosePasswordForm = () => {
    setIsPasswordFormShow(false);
    setModalTitle("");
  };

  const handleSuccess = () => {
    setSuccessSubmit(true);
  };

  const handleToggleNameEdit = () => {
    setIsNameEdit((prev) => !prev);
  };

  const handleSubmitNewName = async () => {
    if (nameInputVal) {
      setIsNameFetching(true);

      const splittedName = nameInputVal.split(/\s+(.+)/);

      const newNamePayload = {
        userId,
        firstName: splittedName[0],
        lastName: splittedName[1]
      };

      try {
        const updateNameRes = await updateUserName(newNamePayload);

        if (updateNameRes) {
          setUpdatedName(nameInputVal);
        }
      } finally {
        setIsNameFetching(false);
        setIsNameEdit(false);
      }
    }
  };

  const handleInputNewName = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (!getTruncatedWords(newName, 16, true)) {
      setNameInputVal(newName);
    }
  };

  useEffect(() => {
    isNameEdit && setNameInputVal(updatedName);
  }, [isNameEdit]);

  return (
    <Modal
      size="md"
      isShow={isShow}
      title={modalTitle || ` ${role === "teacher" ? t("edit_teacher_user") : t("edit_student_user")}`}
      handleClose={handleToggleEditModal}
    >
      <StyledEditUserPopup>
        {successSubmit ? (
          <>
            <div className="kid_img">
              <svg
                className="successIcon"
                width="202"
                height="200"
                viewBox="0 0 202 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="100.951" cy="100" r="99" fill="#27AE60" stroke="white" strokeWidth="2" />
                <path
                  d="M93.3279 128.33L93.338 128.321L93.3474 128.311L136.354 82.7525C136.86 82.2888 137.263 81.7359 137.54 81.1244C137.818 80.5083 137.963 79.8459 137.963 79.176C137.963 78.5061 137.818 77.8438 137.54 77.2277C137.261 76.6124 136.855 76.0565 136.345 75.591C135.841 75.1201 135.242 74.7478 134.585 74.4941C133.926 74.2402 133.221 74.1098 132.509 74.1098C131.797 74.1098 131.092 74.2402 130.434 74.4941C129.775 74.7481 129.176 75.121 128.672 75.5925L128.662 75.6019L128.652 75.612L89.471 117.646L71.9436 101.461C71.9434 101.461 71.9431 101.46 71.9428 101.46C71.4384 100.989 70.8395 100.616 70.1816 100.363C69.5233 100.109 68.8179 99.9782 68.1062 99.9782C67.3944 99.9782 66.689 100.109 66.0307 100.363C65.3732 100.616 64.7746 100.989 64.2704 101.459C63.7606 101.925 63.3539 102.481 63.0755 103.096C62.7968 103.712 62.6527 104.375 62.6527 105.044C62.6527 105.714 62.7968 106.377 63.0755 106.993C63.3541 107.609 63.7612 108.165 64.2715 108.631C64.2718 108.631 64.272 108.631 64.2722 108.631L85.6056 128.33C87.7446 130.306 91.1888 130.306 93.3279 128.33Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.8"
                />
              </svg>
            </div>
            <div className="kid_name">
              <h4>{t("edit_user_updated_success")}</h4>
            </div>
            <div className="mt-4">
              <button
                type="button"
                className="btn-cancel m-auto"
                data-bs-dismiss="modal"
                onClick={handleToggleEditModal}
              >
                {t("Close")}
              </button>
            </div>
          </>
        ) : isDeleteFormShow ? (
          <DeleteUserForm
            userId={userId}
            imageUrl={imageUrl}
            name={updatedName}
            handleToggle={handleToggleDeleteForm}
            handleToggleModal={handleToggleEditModal}
            loadUsers={loadUsers}
          />
        ) : isPasswordFormShow ? (
          <PasswordUpdateForm userId={userId} handleClose={handleClosePasswordForm} handleSuccess={handleSuccess} />
        ) : (
          <>
            <UserPhoto imageUrl={imageUrl} />

            {isNameEdit ? (
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                  maxWidth: "100%",
                  height: "48px",
                  border: "2px solid #38a6de",
                  boxShadow: "none"
                }}
              >
                <IconButton onClick={handleToggleNameEdit}>
                  <CloseIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  value={nameInputVal}
                  placeholder="Enter username"
                  onChange={handleInputNewName}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {isNameFetching ? (
                  <IconButton style={{ color: "#0d2d54", opacity: 0.5 }} className="spinned" disabled={true}>
                    <RefreshOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton style={{ backgroundColor: "#27ad60", color: "#fff" }} onClick={handleSubmitNewName}>
                    <CheckIcon />
                  </IconButton>
                )}
              </Paper>
            ) : (
              <div className="kid_name d-flex align-items-center">
                <h4 className="m-0">{`${getTruncatedWords(updatedName, 16)}`}</h4>
                <IconButton aria-label="delete" style={{ marginLeft: "5px" }} onClick={handleToggleNameEdit}>
                  <img src={pencil} alt="pencil" style={{ width: "24px" }} />
                </IconButton>
                <IconButton onClick={handleToggleDeleteForm}>
                  <DeleteOutlineOutlinedIcon className="color_red_1" />
                </IconButton>
              </div>
            )}

            <ButtonPrimary
              title={`${t("edit_user_update_password")}`}
              disabled={isNameEdit}
              handleClick={handleTogglePasswordForm}
            />

            {role === "teacher" && (
              <TeacherEditForm
                isShow={isShow}
                schoolId={schoolId}
                userId={userId}
                isNameEdit={isNameEdit}
                handleToggle={handleToggleEditModal}
                handleSuccess={handleSuccess}
              />
            )}

            {role === "student" && (
              <StudentEditForm
                isShow={isShow}
                schoolId={schoolId}
                userId={userId}
                isNameEdit={isNameEdit}
                handleToggle={handleToggleEditModal}
                handleSuccess={handleSuccess}
              />
            )}
          </>
        )}
      </StyledEditUserPopup>
    </Modal>
  );
};

export default EditUserPopup;
