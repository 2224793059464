import React from "react";

// assets
import CheckboxIcon from "./icons/CheckboxIcon";
import CheckboxCorrectIcon from "./icons/CheckboxCorrentIcon";

// types
import { QuizQuestionPropsType } from "./QuizQuestionTypes";

const QuizQuestion = ({ questionText, answers }: QuizQuestionPropsType) => {
  return (
    <div className="card border-0 assign_card_box">
      <div className="card-body">
        <div className="assign_content">
          <h3>{questionText}</h3>
          {answers.map((item) => {
            if (item.answerText) {
              return (
                <p
                  key={item.answerText}
                  className={`answer-item ${
                    item.correctAnswer ? "success-bg" : item.userAnswer && !item.correctAnswer ? "error-bg" : ""
                  }`}
                >
                  <span className="pe-2">{item.userAnswer ? <CheckboxCorrectIcon /> : <CheckboxIcon />}</span>
                  {item.answerText}
                </p>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default QuizQuestion;
