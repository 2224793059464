import { FC } from "react";

export type TabDataType = { title: string; value: string }[];
interface IProps {
  data: TabDataType;
  activeTabs: string[];
  handleTabClick?: (value: string) => void;
}

export const Tabs: FC<IProps> = ({ data, activeTabs, handleTabClick }: any) => {
  return (
    <div className="tabs-wrapper">
      {data.map(({ title, value }: any) => {
        return (
          <div
            className={`tab${activeTabs.includes(value) ? " selected" : ""}`}
            key={value}
            onClick={() => handleTabClick(value)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};
