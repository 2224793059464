import styled from "styled-components";

export const StyledLoader = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  z-index: 9999;

  &.forBtn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .loader {
    width: 100%;
    text-align: center;
    display: block;
    z-index: 99999;
  }

  &.alignLeft {
    .loader {
      text-align: left;
      margin-top: 15px;
    }
  }

  .loader__bounce {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 2px;
    background: ${({ color }) => color};
    border-radius: 30px;
    animation: loader-bounce 1.4s infinite ease-out both;
  }

  .loader__bounce.first {
    animation-delay: -0.32s;
  }

  .loader__bounce.second {
    animation-delay: -0.16s;
  }

  @keyframes loader-bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;
