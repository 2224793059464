import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// assets
import crossIcon from "src/assets/images/crose-icon.svg";
import searchIcon from "src/assets/fonts/5.svg";

// utils
import { GetChildrenStatsResponseTypes } from "src/redux/actions/reports/types";
import { GetGroupsResponseTypes, GroupRowType } from "src/redux/actions/group/types";

// components
import StudentClassCard from "src/modules/progressReportClass/partials/StudentClassCard";
import { useDispatch } from "react-redux";
import { addAudienceToAssignmentActions } from "src/redux/actions/assignments";
import { useParams } from "react-router-dom";
import { AssignmentType } from "src/redux/actions/assignments/types";

interface IProps {
  childrens?: GetChildrenStatsResponseTypes;
  groups: GetGroupsResponseTypes["rows"] | undefined;
  assignment: AssignmentType;
  isShow: boolean;
}

export const audienceTypesConfig = [
  { value: "groups", title: "Groups" },
  { value: "students", title: "Students" }
];

export const SelectAudienceModal: FC<IProps> = ({ childrens, groups, assignment, isShow }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [audienceType, setAudienceType] = useState<(typeof audienceTypesConfig)[number]>(audienceTypesConfig[0]);
  const [audienceIds, setAudienceIds] = useState<{ assigneeId: number; role: number }[]>();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    assignment && setAudienceIds(assignment.assignees?.map((item) => ({ ...item })));
  }, [isShow]);

  const handleClick = useCallback(
    (id: number) => {
      setAudienceIds((prev) => {
        const newItems = prev ? [...prev] : [];
        const itemIndex = newItems.findIndex((item) => item.assigneeId === id);
        if (itemIndex >= 0) {
          newItems.splice(itemIndex, 1);
        } else {
          newItems.push({ assigneeId: id, role: Number(audienceType.value === "groups" ? 2 : 1) });
        }
        return newItems;
      });
    },
    [setAudienceIds, audienceType]
  );

  const handleSubmit = useCallback(() => {
    if (id && audienceIds) {
      dispatch(
        addAudienceToAssignmentActions.request({
          assignmentId: Number(id),
          assignees: audienceIds
        })
      );
    }
    setAudienceIds(undefined);
  }, [dispatch, id, audienceIds]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const searchResults: GroupRowType[] | GetChildrenStatsResponseTypes | undefined = useMemo(() => {
    if (audienceType.value === "groups" && groups) {
      return groups.filter(({ name }) => name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
    } else if (audienceType.value === "students" && childrens) {
      return childrens.filter(
        (item) =>
          item[0].childName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) &&
          !assignment?.assignees?.find(({ assigneeId }) => assigneeId === item[0].childId)
      );
    }
  }, [audienceType, groups, childrens, searchValue]);

  return (
    <>
      <div className="student-edit-modal assign-book-modal add-assignment-modal">
        <div className="modal fade" id="selectAudienceModal" aria-labelledby="selectAudienceModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header modal_bg">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select audience
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <img src={crossIcon} alt="" />
                </button>
              </div>
              <div className="modal-body overflow-auto">
                <div className="row">
                  <div className="search-wrapper">
                    <div className="search-form onpage">
                      <div className="form position-relative">
                        <input type="text" value={searchValue} onChange={handleChange} placeholder="Search here..." />
                        <div className="dropdown-wrapper">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {audienceType.title}
                          </button>
                          <ul className="dropdown-menu">
                            {audienceTypesConfig.map((type) => (
                              <li key={type.value} onClick={() => setAudienceType(type)}>
                                <div className="dropdown-item">{type.title}</div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <span className="button">
                          <img src={searchIcon} alt="Icon" className="img-fluid" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row usersList">
                  {audienceType.value === "students" &&
                    searchResults &&
                    searchResults.map((item: any) => (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={item[0].childId}>
                        <StudentClassCard
                          imageUrl={item[0].childProfileImageUrl}
                          id={item[0].childId}
                          name={item[0].childName}
                          onClick={(id) => handleClick(id)}
                          isSelected={!!audienceIds?.find(({ assigneeId }) => assigneeId == item[0].childId)}
                          viewMode
                        />
                      </div>
                    ))}
                  {audienceType.value === "groups" &&
                    searchResults &&
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    searchResults?.map(({ id, name, usersCount }) => (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={id}>
                        <StudentClassCard
                          id={id}
                          name={`Group: ${name} (${usersCount} students)`}
                          onClick={(id) => handleClick(id)}
                          isSelected={!!audienceIds?.find(({ assigneeId }) => assigneeId == id)}
                          viewMode
                        />
                      </div>
                    ))}
                </div>
              </div>

              <div className="modal-footer justify-content-end flex-row">
                <button type="button" className="btn-cancel" data-bs-dismiss="modal">
                  {t("assignments_modal_button_label_cancel")}
                </button>
                <button
                  type="submit"
                  className="btn-submit"
                  disabled={!audienceIds}
                  onClick={handleSubmit}
                  data-bs-dismiss="modal"
                >
                  {t("assignments_modal_button_label_submit")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
