import { take, put } from "redux-saga/effects";
import { GET_POWER_BI_CONFIG, getPowerBiConfigActions } from "src/redux/actions/reports";

// services
import { getPowerBiConfig } from "src/services/http/powerBi";

// types
import { GetPowerBiConfigResponseTypes } from "../../actions/reports/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetPowerBiConfigRequest() {
  while (true) {
    yield take(GET_POWER_BI_CONFIG.REQUEST);
    try {
      const result: GetPowerBiConfigResponseTypes = yield call(getPowerBiConfig);
      yield put(getPowerBiConfigActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getPowerBiConfigActions.error(e as any));
    }
  }
}

export default watchGetPowerBiConfigRequest;
