import React from "react";
import { useTranslation } from "react-i18next";

// assets
import editIcon from "src/assets/images/9.svg";
import locationIcon from "src/assets/images/7.svg";
import usersGroupIcon from "src/assets/images/8.svg";

// types
import { BookCardPropsType } from "./BookCardTypes";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

const BookCard = ({
  imgUrl,
  title,
  author,
  series,
  description,
  id,
  readingLevel,
  hideEditButton = true,
  blankDetailsButton = false,
  buttonTitle,
  onBookClick,
  selectedBookId,
  setSelectedBookId,
  isSelected
}: BookCardPropsType) => {
  const [t] = useTranslation();

  const handleClick = () => {
    if (onBookClick) {
      onBookClick(id, imgUrl);
    } else if (setSelectedBookId) {
      setSelectedBookId(id);
    }
  };

  return (
    <div
      className={`course ${isSelected || selectedBookId === id ? "active" : ""}`}
      onClick={handleClick}
      style={{ cursor: `${onBookClick ? "pointer" : "default"}` }}
    >
      <div className="course-top">
        <div className="thumb">
          <img src={imgUrl} alt="Img" className="img-fluid" />
        </div>
        <div className="text">
          {!hideEditButton && (
            <div className="text-end">
              <a href="#" className="icon">
                <img src={editIcon} alt="Img" className="img-fluid" />
              </a>
            </div>
          )}
          <h2>
            <a href="#">{title}</a>
          </h2>
          <ul>
            {series && series.length > 0 && (
              <li>
                <>
                  <span>
                    <img src={locationIcon} alt="Img" className="img-fluid" />
                  </span>
                  <span>
                    {series.map((item, index) => {
                      return (
                        <span key={item.tagId}>
                          {item.tagName}
                          {series && series.length > 1 && index + 1 !== author.length ? "," : ""}
                        </span>
                      );
                    })}
                    {t("library_book_card_series_label")}
                  </span>
                </>
              </li>
            )}
            {author && author.length > 0 && (
              <li>
                <>
                  <span>
                    <img src={usersGroupIcon} alt="Img" className="img-fluid" />
                  </span>
                  {author.map((item, index) => {
                    return (
                      <span key={item.tagId}>
                        {item.tagName}
                        {author.length > 1 && index + 1 !== author.length ? "," : ""}
                      </span>
                    );
                  })}
                </>
              </li>
            )}
            {readingLevel && (
              <li>
                <span className="fw-bold">{readingLevel}</span>
                <span>{t("library_book_card_label_reading_level")}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {description && <p>{description}</p>}
      <div className="button">
        <ButtonPrimary
          role="link"
          to={`/book/${id}`}
          title={buttonTitle ? buttonTitle : t("library_book_card_button_select_book_label")}
          target={blankDetailsButton ? "_blank" : undefined}
        />
      </div>
    </div>
  );
};

export default BookCard;
