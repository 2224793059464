import React, { ReactNode, useEffect, useState, MouseEvent } from "react";
import crossIcon from "../../assets/images/crose-icon.svg";
import { StyledModal } from "./Modal.styles";

interface IProps {
  size?: "md" | "lg";
  isShow: boolean;
  title: string;
  handleClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<IProps> = ({ size = "lg", isShow, title, handleClose, children }) => {
  const [isFade, setIsFade] = useState(isShow);
  const [isVisible, setIsVisible] = useState(isShow);

  useEffect(() => {
    if (isShow) {
      setIsVisible(isShow);
      setTimeout(() => {
        setIsFade(isShow);
      }, 100);
    } else {
      setIsFade(isShow);
      setTimeout(() => {
        setIsVisible(isShow);
      }, 100);
    }
  }, [isShow]);

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.classList.contains("modal")) {
      handleClose();
    }
  };

  return (
    <StyledModal className={`${isVisible ? "show" : ""}`}>
      <div
        onClick={handleOverlayClick}
        className={`modal ${isFade ? "fade" : ""} ${isVisible ? "show" : ""}`}
        aria-labelledby={`${title}Modal`}
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered ${size}`}>
          <div className="modal-content">
            <div className="modal-header modal_bg">
              <h5 className="modal-title" id="headerModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <img src={crossIcon} alt="" />
              </button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};

export default Modal;
