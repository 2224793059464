import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// assets
import toggleIcon from "src/assets/fonts/toggler.svg";
import notificationIcon from "src/assets/fonts/6.svg";
import languageIcon from "src/assets/fonts/p1.svg";
import bookIcon from "src/assets/fonts/b1.svg";
import userImage from "src/assets/images/user1.png";
import searchIcon from "src/assets/fonts/5.svg";
import flagSmithIdentification from "src/helpers/flagsmithIdentification";

// utils
import { RootReducer } from "src/redux/reducers";
import { getUserInfoActions } from "src/redux/actions/user";

import ActiveSchoolSelect from "../ActiveSchoolSelect/ActiveSchoolSelect";
import useUserType from "../../hooks/useUserType";

const Header = ({ sidebarToggle, handleLogout }: { sidebarToggle: () => void; handleLogout: () => void }) => {
  const dispatch = useDispatch();

  const {
    auth: {
      authData: { userId }
    },
    user: { userData, lastUpdated }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getUserInfo = useCallback(() => dispatch(getUserInfoActions.request({ userId: userId || "" })), [dispatch]);

  const { isAdmin } = useUserType();
  const flagSmithHelper = flagSmithIdentification();

  const isDataStale = () => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    return !lastUpdated || Date.now() - lastUpdated > oneDay;
  };

  useEffect(() => {
    if (!userData || isDataStale()) {
      getUserInfo();
    } else {
      flagSmithHelper.identify(userData.id, userData.schoolId);
    }
  }, []);

  return (
    <header className="header">
      <div className="header-cpntent">
        <div className="left-content">
          <div className="toggler" onClick={sidebarToggle}>
            <img src={toggleIcon} alt="Img" className="img-fluid" />
          </div>

          {isAdmin ? (
            <ActiveSchoolSelect />
          ) : (
            <div className="search-form">
              <form action="#">
                <input type="text" placeholder="Search here...." />
                <button type="submit">
                  <img src={searchIcon} alt="Icon" className="img-fluid" />
                </button>
              </form>
            </div>
          )}
        </div>

        <div className="user-option">
          <ul>
            <li>
              <div className="languages">
                <div className="dropdown">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={languageIcon} alt="Icon" className="img-fluid" />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        India
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Bengali
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Germany
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Arabic
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="notification">
                <div></div>
                <div className="dropdown">
                  <button
                    className="dropdown-toggle icon"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {" "}
                    <span>
                      <img src={notificationIcon} alt="Icon" className="img-fluid" />
                    </span>
                  </button>
                  <div className="dropdown-menu">
                    <div className="nt-top">
                      <h1>Notifications</h1>
                      <a href="#">Clear all</a>
                    </div>
                    <ul>
                      <li>
                        <a className="dropdown-item" href="#">
                          <span>
                            <img src={bookIcon} alt="Icon" className="img-fluid" />
                          </span>{" "}
                          sit amet, consectetur adipiscing elit.{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <span>
                            <img src={bookIcon} alt="Icon" className="img-fluid" />
                          </span>{" "}
                          sit amet, consectetur adipiscing elit.{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <span>
                            <img src={bookIcon} alt="Icon" className="img-fluid" />
                          </span>{" "}
                          sit amet, consectetur adipiscing elit.{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <span>
                            <img src={bookIcon} alt="Icon" className="img-fluid" />
                          </span>{" "}
                          sit amet, consectetur adipiscing elit.{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <span>
                            <img src={bookIcon} alt="Icon" className="img-fluid" />
                          </span>{" "}
                          sit amet, consectetur adipiscing elit.{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="user-dropdown">
                <div className="dropdown">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={userData?.profileImageUrl || userImage} alt="Icon" className="img-fluid" />
                    {userData?.name && (
                      <span className="text">
                        <strong>{userData.name}</strong> Super admin
                      </span>
                    )}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="./Teacher’s_Profile.html">
                        My account
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="./forgot.html">
                        Reset password
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={handleLogout}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
