import React from "react";

// types
import { ReportTabButtonPropTypes } from "./ReportTabButtonTypes";

const ReportTabButton = ({ title, isActive, action, id, btnClass }: ReportTabButtonPropTypes) => {
  return (
    <button
      onClick={() => {
        action(id);
      }}
      className={`${btnClass} ${isActive ? "active" : ""}`}
      id="home-tab"
      data-bs-toggle="tab"
      data-bs-target={`#home-tab-pane-${id}`}
      type="button"
      role="tab"
      aria-controls={`home-tab-pane-${id}`}
      aria-selected="true"
    >
      {title}
    </button>
  );
};

export default ReportTabButton;
