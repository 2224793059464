import { useCallback, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components";

import { RootReducer } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getPowerBiConfigActions } from "src/redux/actions/reports";
import { Loader } from "src/components";

const PowerBIReport = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    reports: { powerBiConfig }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getPowerBiConfig = useCallback(() => dispatch(getPowerBiConfigActions.request()), [dispatch]);
  useEffect(() => {
    getPowerBiConfig();
  }, []);

  const embedConfig = {
    type: "report", // or 'dashboard'
    tokenType: models.TokenType.Embed,
    accessToken: powerBiConfig?.token,
    embedUrl:
      "https://app.powerbi.com/reportEmbed?reportId=7e6aae95-2397-4758-8369-76e7e88ec28b&ctid=f2dad8cf-2321-4e2a-af29-d9c58fa61d20",
    id: "7e6aae95-2397-4758-8369-76e7e88ec28b", // Report ID
    settings: {
      filterPaneEnabled: true,
      navContentPaneEnabled: true
    }
  };

  return powerBiConfig == undefined ? (
    <Loader />
  ) : (
    <Layout>
      <div className="ds-card">
        <div className="d-flex align-items-center justify-content-between pb-3">
          <div className="title mb-0">
            <h1 className="mb-0">{t("reports_page_title")}</h1>
          </div>
        </div>
      </div>
      <div className="ds-card sec_body">
        <PowerBIEmbed
          embedConfig={embedConfig}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                }
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                }
              ],
              [
                "error",
                function (event) {
                  console.log("Error during load or render", event?.detail);
                }
              ]
            ])
          }
          cssClassName={"powerbi-container"}
        />
      </div>
    </Layout>
  );
};

export default PowerBIReport;
